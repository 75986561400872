import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil, getObjectFromValue } from '@/shared/utils';
import { ACCESS_LEVEL } from '@/utils/utils';
import _ from 'lodash';

export const getCountTotalOfUser = async (myProject, keyword) => {
	try {
		const {
			data: { GetCountTotalOfUser }
		} = await apollo.query({
			query: gql`
				query ($MyProject: Boolean!, $KeyWord: String) {
					GetCountTotalOfUser(MyProject: $MyProject, KeyWord: $KeyWord)
				}
			`,
			variables: {
				MyProject: myProject,
				KeyWord: keyword
			},
			fetchPolicy: 'no-cache'
		});

		return GetCountTotalOfUser;
	} catch (e) {
		throw new Error('Error request');
	}
};
export const getUsers = async (
	{ myProject, limit, offset, keyword },
	projectId = 0,
	departmentId = -1,
	functionId = 0,
	skip = 0,
	sortField = '',
	sortOrder = '',
	forTimeSheet = null
) => {
	try {
		let variables = {
			myProject,
			limit: limit !== undefined ? limit : null,
			offset: offset !== undefined ? offset : null,
			keyword: keyword !== undefined ? keyword : null
		};

		if (+projectId > 0) {
			variables = {
				...variables,
				projectId: +projectId
			};
		}

		if (!_.isNil(departmentId) && departmentId >= -1) {
			variables = {
				...variables,
				departmentId
			};
		}

		if (+functionId > 0) {
			variables = {
				...variables,
				functionId: +functionId
			};
		}

		if (+skip > 0) {
			variables = {
				...variables,
				skip: +skip
			};
		}

		if (!_.isNil(limit) && +limit > 0 && !_.isNil(offset) && +offset > 0) {
			variables = {
				...variables,
				limit: limit,
				Offset: offset
			};
		}

		if (!_.isNil(sortField) && _.isString(sortField) && sortField !== '') {
			variables = {
				...variables,
				sortField
			};
		}

		if (!_.isNil(sortOrder) && _.isString(sortOrder) && sortOrder !== '') {
			variables = {
				...variables,
				sortOrder
			};
		}

		if (!_.isNil(forTimeSheet) && _.isBoolean(forTimeSheet)) {
			variables = {
				...variables,
				ForTimeSheet: forTimeSheet
			};
		}

		if (!_.isNil(keyword) && keyword !== '') {
			variables = {
				...variables,
				KeyWord: keyword
			};
		}

		const {
			data: { Users }
		} = await apollo.query({
			query: gql`
				query (
					$myProject: Boolean
					$projectId: ID
					$departmentId: ID
					$functionId: ID
					$limit: Int
					$skip: Int
					$sortField: String
					$sortOrder: String
					$ForTimeSheet: Boolean
					$Offset: Int
					$KeyWord: String
				) {
					Users(
						myProject: $myProject
						projectID: $projectId
						Department: $departmentId
						Function: $functionId
						limit: $limit
						skip: $skip
						sortField: $sortField
						sortOrder: $sortOrder
						ForTimeSheet: $ForTimeSheet
						Offset: $Offset
						KeyWord: $KeyWord
					) {
						id
						name
						firstName
						fullName
						picture
						phone
						email
						phone
						isProd
						isAdmin
						isDev
						isTranslator
						department
						function
						departmentName
						customFunctionName
						functionName
						validationLevel
						canAssign
						color
						reference
						canEditExpense
						canEditPo
						bankDetailXid
						medicalVisitDocumentXid
						canAccessUsers
						canAccessBudget
						canAccessGreenReporting
						canAccessConfig
						canAccessMyBudget
						canAccessOnboarding
						canValidateI9
						canLockBudget
						canModifyEstimatedFinalCost
						canEditAndAddCode
						upm
						gateKeeper
						viewOnBoardingScreen
						productionAccountant
						lineProducer
						studio
						canManageDocuments
						canManageDocumentPackage
						canManageCallSheets
						canManageAccommodations
						canManageLocations
						canExportImport
						canManageCarbonRemoval
						canManageTipOfTheDay
						canViewDepartmentBudget
						canManageCrewList
						canViewGlobalBudget
						canViewConfigurationScreen
						canViewReportingScreens
						canManageTransport
						canViewWasteScreens
						canViewGreenTable
						isUserVisible
						cannotBeDeleted
					}
				}
			`,
			variables,
			fetchPolicy: 'no-cache'
		});

		return Users;
	} catch (e) {
		console.log({ e });
	}
};

export const getUser = async (userId) => {
	if (isNil(userId)) return;
	const usersList = await getUsers({ myProject: false });
	return getObjectFromValue(usersList, 'id', `${userId}`);
};

export const getUserRequestInfo = async (Xid) => {
	if (isNil(Xid)) return;
	try {
		const query = gql`
			query ($Xid: String!) {
				GetUserRequestInfo(Xid: $Xid) {
					id
					createdAt
					updatedAt
					email
					password
					projectTitle
					xid
					validated
					validatedOn
					language
					userId
					requestType
					userName
					userFirstName
					projectId
					department
					function
					schedule
					dailyRate
					hourlyRate
					totMinPerDayForContDay
					totMinPerDay
					overtimeRate
					nightTimeRate
					nightTimeStart
					nightTimeEnd
					kmRate
					startDate
					endDate
					totMinPerWeek
					sixthDay
					seventhDay
					minTimeBetweenDays
					hourBeforeTimeRate
					lunchMinimum
					lunchMinimumForContDay
					lunchPerDiem
					hotelPerDiem
					defaultDayType
					travelDailyRate
					isAdmin
					isProd
					canAssign
					validationLevel
					planningValidation
					daysForeseen
					timeManagementType
					weeklyOvertime
					workSixDays
					weeklyFiveDaysMinimumSalary
					weeklySixDaysMinimumSalary
					weeklyFiveDaysEffNumHours
					weeklyFiveDaysEquNumHours
					weeklySixDaysEffNumHours
					weeklySixDaysEquNumHours
					weeklyBonusAmount
					weeklyFiveDaysRefSalary
					weeklySixDaysRefSalary
					weeklyOvtOneLimit
					weeklyOvtOneRate
					weeklyOvtTwoLimit
					weeklyOvtTwoRate
					weeklyOvtThreeLimit
					weeklyOvtThreeRate
					weeklyBaseEffNumHours
					hourlyRateAnnexThree
					weeklyBonusAmountFiveDays
					weeklyBonusAmountSixDays
					weeklyBaseRefSalary
					weeklyBaseMinimumSalary
					weeklyFiveDaysMinimumSalaryAnThree
					weeklySixDaysMinimumSalaryAnThree
					companyCostFactor
					transportRate
					transportPaidAfter
					ovtLimit1
					ovtLimit2
					ovtRate1
					ovtRate2
					sundayPublicHolidayFactor
					minTimeBetweenWeek
					dailyRightTransfer
					employeeStatus
					useCarAllowance
					carAllowanceRate
					carAllowance
					useProductionFeeAllowance
					productionFeeAllowanceRate
					productionFeeAllowance
					useDinnerPerDiem
					dinnerPerDiem
					useComputerAllowance
					computerAllowanceRate
					computerAllowance
					minimumHourGuaranteed
					minimumHourGuaranteedType
					beforeCallTimeRate
					ifWorkAboveDailyHours
					usePhoneAllowance
					phoneAllowanceRate
					phoneAllowance
					useBoxKitAllowance
					boxKitAllowanceRate
					boxKitAllowance
					useAbroadPerDiem
					abroadPerDiem
					useHotelPerDiem
					useLunchPerDiem
					note
					canAccessUsers
					canAccessBudget
					canAccessMyBudget
					canAccessGreenReporting
					canAccessConfig
					canAccessCrewList
					canAccessOnboarding
					canValidateI9
					canManageDocuments
					canManageDocumentPackage
					canManageCallSheets
					canManageAccommodations
					canManageLocations
					canExportImport
					canManageCarbonRemoval
					canManageTipOfTheDay
					canViewDepartmentBudget
					canManageCrewList
					canViewGlobalBudget
					canViewConfigurationScreen
					canViewReportingScreens
					canManageTransport
					canViewWasteScreens
					canViewGreenTable
					gateKeeper
					productionAccountant
					upm
					lineProducer
					studio
					viewOnBoardingScreen
					costCenterPrepsType
					costCenterShootingType
					costCenterWrapType
					documentPackageId
					documentTemplateXid
					isAutomaticSentContract
					lunchMinimumForContDayStr
					lunchMinimumStr
					minTimeBetweenWeekStr
					ovtLimit1Str
					ovtLimit2Str
					totMinPerDayForContDayStr
					totMinPerDayStr
					totMinPerWeekStr
					transportPaidAfterStr
					weeklyBaseEffNumHoursStr
					weeklyOvtOneLimitStr
					weeklyOvtThreeLimitStr
					weeklyOvtTwoLimitStr
					departmentName
					functionName
					weeklyFiveDaysEffNumHoursStr
					weeklyFiveDaysEquNumHoursStr
					weeklySixDaysEffNumHoursStr
					weeklySixDaysEquNumHoursStr
					minimumHourGuaranteedStr
					contractReference
					contractType
					dailyOvertime
					ovtLimit
					ovtLimitContinuousDay
					ovtLimitStr
					ovtLimitContinuousDayStr
					monthlyRate
					useTravelDailyRate
					canEditExpense
					canEditPo
					canLockBudget
					canModifyEstimatedFinalCost
					canEditAndAddCode
					baseSalary1PayCode
					baseSalary2PayCode
					baseSalary3PayCode
					baseSalary4PayCode
					dayOvt1PayCode
					dayOvt2PayCode
					dayOvt3PayCode
					weekOvt1PayCode
					weekOvt2PayCode
					weekOvt3PayCode
					nightTimePayCode
					restPayCode
					transportPayCode
					sundayPayCode
					seventhDayPayCode
					sixthDayPayCode
					beforeTimePayCode
					travelAllowancePayCode
					carAllowancePayCode
					phoneAllowancePayCode
					computerAllowancePayCode
					boxKitAllowancePayCode
					productionFeeAllowancePayCode
					lunchPerDiemPayCode
					dinerPerDiemPayCode
					hotelPerDiemPayCode
					abroadPerDiemPayCode
					collectiveAgreement
					echelon
					analyticCode
					session1A
					session1B
					session2A
					session2B
					session3A
					session3B
					session4A
					session4B
					session5A
					session5B
					session6A
					session6B
					session7A
					session7B
					session8A
					session8B
				}
			}
		`;
		const { data } = await apollo.query({
			query,
			variables: {
				Xid: Xid
			},
			fetchPolicy: 'network-only'
		});
		const res = data.GetUserRequestInfo;
		return res;
	} catch (e) {
		console.log({ e });
	}
};

export const getNewPasswordRequestInfo = async (Xid) => {
	if (isNil(Xid)) return;
	try {
		const query = gql`
			query ($Xid: String!) {
				GetNewPasswordRequestInfo(Xid: $Xid) {
					id
					createdAt
					userId
					userName
					userFirstName
					email
					validated
					xid
					requestType
					userTypeForFilm
				}
			}
		`;
		const { data } = await apollo.query({
			query,
			variables: {
				Xid: Xid
			},
			fetchPolicy: 'network-only'
		});
		const res = data.GetNewPasswordRequestInfo;
		return res;
	} catch (e) {
		console.log({ e });
	}
};

export const myProfile = async () => {
	try {
		const { data } = await apollo.query({
			query: gql`
				query {
					myProfile {
						id
						firstName
						name
						email
						upm
						gateKeeper
						canValidateI9
						productionAccountant
						lineProducer
						studio
						iban
						birthDate
						language
						socSecNumber
						civilStatus
						placeOfBirth
						placeOfBirthZip
						nationalRegistryNumber
						bic
						phone
						picture
						nationality
						companyNumber
						isDev
						isTranslator
						currencySymbol
						distanceUnit
						appLanguage
						department
						departmentName
						function
						functionName
						contractStartDate
						contractEndDate
						gender
						driverLicense
						driverLicenseNumber
						allergies
						spouseName
						spouseFirstName
						spouseBirthDate
						spouseProfession
						spouseHasSalary
						numberOfDependentChild
						numberOfDisabledChild
						numberOfDependentPerson
						numberOfDisabledPerson
						personToContact
						personToContactPhone
						disabled
						color
						showWizard
						addressID
						address {
							street
							number
							zip
							city
							box
							state
							country
							countryCode
							countryKey
						}
						reference
						idNumber
						shoeSize
						tShirtSize
						showMyEmailInCrew
						showMyPhoneInCrew
						idName
						pictureId
						pictureIdVerso
						pictureDriverLicense
						pictureDriverLicenseVerso
						signatureId
						taxesPercentage
						otherStudyLevel
						size
						pantSize
						jacketSize
						vehicule {
							name
							brand
							model
							numberPlate
							type
							subType
							additionalSubType
							energy
							reference
							kgCoTwoPerKm
						}
						pictureRib
						maidenName
						title
						birthCountry
						congesSpectacle
						bankAccountOwnerName
						bankDetailXid
						taxCountry
						postalAddressID
						postalAddress {
							id
							street
							number
							box
							zip
							state
							city
							country
							countryCode
							countryKey
							other
							lat
							lng
						}
						socialSecurityCardXid
						insuranceCertificateXid
						socialSecurityCenter
						socialSecurityCenterAddressId
						lastMedicalVisit
						medicalVisitValidity
						medicalVisitDocumentXid
						disabilityNotification
						disabilityType
						disabilityStatus
						invalidity
						rqth
						invalidityPourcentage
						retired
						retirementDate
						trainLoyaltyCard
						trainDiscountCard
						flyingLoyaltyCard
						otherAirlineCard
						residencePermitNumber
						residencePermitEndValidity
						residentPermitXid
						workCardXid
						pseudonym
						professionalAssociation
						gateKeeper
						upm
						studio
						lineProducer
						productionAccountant
						canValidateI9
						session1A
						session1B
						session2A
						session2B
						session3A
						session3B
						session4A
						session4B
						session5A
						session5B
						session6A
						session6B
						session7A
						session7B
						session8A
						session8B
					}
				}
			`,
			variables: {},
			fetchPolicy: 'no-cache'
		});

		// idName
		// idPictureFront
		// idPictureBack
		// driverLicensePictureFront
		// driverLicensePictureBack
		return data.myProfile;
	} catch (e) {
		console.log({ e });
	}
};

export const getUsersOfProject = async (sortByName = false) => {
	try {
		const {
			data: { GetUsersOfProject }
		} = await apollo.query({
			query: gql`
				query ($sortByName: Boolean) {
					GetUsersOfProject(SortByName: $sortByName) {
						id
						name
						firstName
						fullName
						picture
						phone
						email
						phone
						isProd
						isAdmin
						isDev
						isTranslator
						department
						function
						departmentName
						functionName
						validationLevel
						color
						mariedOn
						reference
						customFunctionName
						customPosInList
						session1A
						session1B
						session2A
						session2B
						session3A
						session3B
						session4A
						session4B
						session5A
						session5B
						session6A
						session6B
						session7A
						session7B
						session8A
						session8B
					}
				}
			`,
			variables: {
				sortByName
			},
			fetchPolicy: 'no-cache'
		});

		return GetUsersOfProject;
	} catch (e) {
		console.log({ e });
	}
};

export const getUserRequest = async ({ projectId }) => {
	const {
		data: { UserRequests }
	} = await apollo.query({
		query: gql`
			query ($projectId: ID) {
				UserRequests(projectId: $projectId) {
					id
					createdAt
					userName
					userFirstName
					departmentName
					functionName
					email
					xid
				}
			}
		`,
		variables: {
			projectId: parseInt(projectId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return UserRequests;
};

export const changeUserAndSalaryVisibility = async (data) => {
	try {
		const mutation = gql`
			mutation ChangeUserAndSalaryVisibility($UserAndSalaryVisibility: [UserAndSalaryVisibilityInput!]!) {
				ChangeUserAndSalaryVisibility(UserAndSalaryVisibility: $UserAndSalaryVisibility)
			}
		`;
		const {
			data: { UserAndSalaryVisibility }
		} = await apollo.mutate({
			mutation,
			variables: {
				UserAndSalaryVisibility: data
			}
		});
		return UserAndSalaryVisibility;
	} catch (e) {
		return {
			message: e
		};
	}
};

export const addProd = async ({ userId, projectId }) => {
	const mutation = gql`
		mutation ADD_PROD($userId: ID!, $projectId: ID) {
			AddProdToProject(UserID: $userId, ProjectID: $projectId) {
				id
				isProd
			}
		}
	`;
	const {
		data: { AddProdToProject }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			projectId: parseInt(projectId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return AddProdToProject;
};

export const delProd = async ({ userId, projectId }) => {
	const mutation = gql`
		mutation DEL_PROD($userId: ID!, $projectId: ID) {
			DelProdFromProject(UserID: $userId, ProjectID: $projectId) {
				id
				isProd
			}
		}
	`;
	const {
		data: { DelProdFromProject }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			projectId: parseInt(projectId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return DelProdFromProject;
};

export const addAdmin = async (userId) => {
	const mutation = gql`
		mutation ADD_ADMIN($userId: ID!) {
			AddAdminToProject(UserID: $userId) {
				id
				isAdmin
			}
		}
	`;
	const {
		data: { AddAdminToProject }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return AddAdminToProject;
};

export const delAdmin = async (userId) => {
	const mutation = gql`
		mutation DEL_ADMIN($userId: ID!) {
			DelAdminFromProject(UserID: $userId) {
				id
				isAdmin
			}
		}
	`;
	const {
		data: { DelAdminFromProject }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return DelAdminFromProject;
};

export const addDev = async (userId) => {
	const mutation = gql`
		mutation ADD_DEV($userId: ID!) {
			AddIsDev(UserID: $userId) {
				id
				isDev
			}
		}
	`;
	const {
		data: { AddIsDev }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return AddIsDev;
};

export const delDev = async (userId) => {
	const mutation = gql`
		mutation DEL_DEV($userId: ID!) {
			DelIsDev(UserID: $userId) {
				id
				isDev
			}
		}
	`;
	const {
		data: { DelIsDev }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return DelIsDev;
};

export const addTranslator = async (userId) => {
	const mutation = gql`
		mutation ADD_TRANSLATOR($userId: ID!) {
			AddIsTranslator(UserID: $userId) {
				id
				isTranslator
			}
		}
	`;
	const {
		data: { AddIsTranslator }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return AddIsTranslator;
};

export const delTranslator = async (userId) => {
	const mutation = gql`
		mutation DEL_TRANSLATOR($userId: ID!) {
			DelIsTranslator(UserID: $userId) {
				id
				isTranslator
			}
		}
	`;
	const {
		data: { DelIsTranslator }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return DelIsTranslator;
};

export const signinAs = async (userId) => {
	const mutation = gql`
		mutation SIGNIN_AS($userId: ID!) {
			signInAs(userID: $userId)
		}
	`;
	const {
		data: { signInAs }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return signInAs;
};

export const SwitchCustomPositionInList = async (UserIdOne, UserIdTwo) => {
	const mutation = gql`
		mutation SWITCH_CUSTOM_POSITION_IN_LIST($UserIdOne: ID!, $UserIdTwo: ID!) {
			SwitchCustomPositionInList(UserIdOne: $UserIdOne, UserIdTwo: $UserIdTwo)
		}
	`;
	const {
		data: { SwitchCustomPositionInList }
	} = await apollo.mutate({
		mutation,
		variables: {
			UserIdOne: parseInt(UserIdOne, 10),
			UserIdTwo: +UserIdTwo
		},
		fetchPolicy: 'no-cache'
	});

	return SwitchCustomPositionInList;
};
export const delUser = async (userId) => {
	const mutation = gql`
		mutation DEL_USER($userId: ID!) {
			DelUser(userID: $userId)
		}
	`;
	const {
		data: { DelUser }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return DelUser;
};

export const delUserValidation = async (userId) => {
	const mutation = gql`
		mutation DEL_ALL_TYPE_NORMAL_VALIDATION($UserId: ID!) {
			DelAllTypeNormalValidation(UserId: $UserId)
		}
	`;
	const {
		data: { DelAllTypeNormalValidation }
	} = await apollo.mutate({
		mutation,
		variables: {
			UserId: parseInt(userId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return DelAllTypeNormalValidation;
};

export const delUserFromProject = async (UserId) => {
	const mutation = gql`
		mutation DEL_USER_FROM_PROJECT($UserId: ID!) {
			DelUserFromProject(UserId: $UserId)
		}
	`;
	const {
		data: { DelUserFromProject }
	} = await apollo.mutate({
		mutation,
		variables: {
			UserId: parseInt(UserId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return DelUserFromProject;
};

export const delUserRequest = async (userRequestId) => {
	const mutation = gql`
		mutation DEL_USER_REQUEST($userRequestId: ID!) {
			DelUserRequest(UserRequestId: $userRequestId)
		}
	`;
	const {
		data: { DelUserRequest }
	} = await apollo.mutate({
		mutation,
		variables: {
			userRequestId: parseInt(userRequestId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return DelUserRequest;
};

export const validateFimalac = async ({ userId, departmentNumber, levelTs, levelPo, levelExp, projectId, replace, type = 0, validationId = null }) => {
	const mutation = gql`
		mutation VALIDATE($ValidationId: ID!, $UserId: ID!, $ProjectId: ID!, $UpdValidation: ValidationInput!, $Replace: Boolean) {
			AddUpdValidation(ValidationId: $ValidationId, UserId: $UserId, ProjectId: $ProjectId, UpdValidation: $UpdValidation, Replace: $Replace) {
				id
			}
		}
	`;
	const {
		data: { AddUpdValidation }
	} = await apollo.mutate({
		mutation,
		variables: {
			ValidationId: validationId,
			UserId: parseInt(userId, 10),
			UpdValidation: {
				validationLevelTs: parseInt(levelTs, 10),
				validationLevelPo: parseInt(levelPo, 10),
				validationLevelExp: parseInt(levelExp, 10),
				department: parseInt(departmentNumber, 10),
				type: type
			},
			ProjectId: projectId,
			Replace: replace
		},
		fetchPolicy: 'no-cache'
	});

	return AddUpdValidation;
};

export const validate = async ({ userId, departmentNumber, levelTs, levelPo, levelExp, projectId, replace, type = 0 }) => {
	const mutation = gql`
		mutation VALIDATE($ValidationId: ID!, $UserId: ID!, $ProjectId: ID!, $UpdValidation: ValidationInput!, $Replace: Boolean) {
			AddUpdValidation(ValidationId: $ValidationId, UserId: $UserId, ProjectId: $ProjectId, UpdValidation: $UpdValidation, Replace: $Replace) {
				id
			}
		}
	`;
	const {
		data: { AddUpdValidation }
	} = await apollo.mutate({
		mutation,
		variables: {
			ValidationId: 0,
			UserId: parseInt(userId, 10),
			UpdValidation: {
				validationLevelTs: parseInt(levelTs, 10),
				validationLevelPo: parseInt(levelPo, 10),
				validationLevelExp: parseInt(levelExp, 10),
				department: parseInt(departmentNumber, 10),
				type: type
			},
			ProjectId: projectId,
			Replace: replace
		},
		fetchPolicy: 'no-cache'
	});

	return AddUpdValidation;
};

export const canAssign = async ({ userId, value }) => {
	const mutation = gql`
		mutation CAN_ASSIGN_USER($userId: ID!, $value: Boolean) {
			CanAssign(UserId: $userId, Value: $value)
		}
	`;
	const {
		data: { CanAssign }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			value
		},
		fetchPolicy: 'no-cache'
	});

	return CanAssign;
};

export const updateUserProjectReference = async ({ userId, reference }) => {
	const mutation = gql`
		mutation UPDATE_USERPRPOJECT_REFERENCE($userId: ID!, $reference: String!) {
			UpdUserProjectReference(UserId: $userId, Reference: $reference)
		}
	`;
	const {
		data: { UpdUserProjectReference }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			reference
		},
		fetchPolicy: 'no-cache'
	});

	return UpdUserProjectReference;
};

export const addUpdAccessUsers = async ({ userId, canAccessUsers }) => {
	const mutation = gql`
		mutation ADD_UPD_ACCESS_USERS($userId: ID!, $canAccessUsers: Boolean!) {
			AddUpdAccessUsers(UserID: $userId, CanAccessUsers: $canAccessUsers) {
				id
			}
		}
	`;

	const {
		data: { AddUpdAccessUsers }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			canAccessUsers
		}
	});

	return AddUpdAccessUsers;
};

export const addUpdAccessBudget = async ({ userId, canAccessBudget }) => {
	const mutation = gql`
		mutation ADD_UPD_ACCESS_BUDGET($userId: ID!, $canAccessBudget: Boolean!) {
			AddUpdAccessBudget(UserID: $userId, CanAccessBudget: $canAccessBudget) {
				id
			}
		}
	`;

	const {
		data: { AddUpdAccessBudget }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			canAccessBudget
		}
	});

	return AddUpdAccessBudget;
};

export const addUpdAccessGreenReporting = async ({ userId, canAccessGreenReporting }) => {
	const mutation = gql`
		mutation ADD_UPD_ACCESS_GREEN_REPORTING($userId: ID!, $canAccessGreenReporting: Boolean!) {
			AddUpdAccessGreenReporting(UserID: $userId, CanAccessGreenReporting: $canAccessGreenReporting) {
				id
			}
		}
	`;

	const {
		data: { AddUpdAccessGreenReporting }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			canAccessGreenReporting
		}
	});

	return AddUpdAccessGreenReporting;
};

export const addUpdAccessConfig = async ({ userId, canAccessConfig }) => {
	const mutation = gql`
		mutation ADD_UPD_ACCESS_GREEN_REPORTING($userId: ID!, $canAccessConfig: Boolean!) {
			AddUpdAccessConfig(UserID: $userId, CanAccessConfig: $canAccessConfig) {
				id
			}
		}
	`;

	const {
		data: { AddUpdAccessConfig }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			canAccessConfig
		}
	});

	return AddUpdAccessConfig;
};

export const addUpdAccessMyBudget = async ({ userId, canAccessMyBudget }) => {
	const mutation = gql`
		mutation ADD_UPD_ACCESS_GREEN_REPORTING($userId: ID!, $canAccessMyBudget: Boolean!) {
			AddUpdAccessMyBudget(UserID: $userId, CanAccessMyBudget: $canAccessMyBudget) {
				id
			}
		}
	`;

	const {
		data: { AddUpdAccessMyBudget }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			canAccessMyBudget
		}
	});

	return AddUpdAccessMyBudget;
};

export const addUpdProdFromProject = async ({ userId, isProd }) => {
	const mutation = gql`
		mutation ADD_UPD_PROD_FROM_PROJECT($userId: ID!, $isProd: Boolean!) {
			AddUpdProdFromProject(UserID: $userId, IsProd: $isProd) {
				id
			}
		}
	`;

	const {
		data: { AddUpdProdFromProject }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			isProd
		}
	});

	return AddUpdProdFromProject;
};

export const addUpdAdminToProject = async ({ userId, isAdmin }) => {
	const mutation = gql`
		mutation ADD_UPD_ADMIN_FROM_PROJECT($userId: ID!, $isAdmin: Boolean!) {
			AddUpdAdminToProject(UserID: $userId, IsAdmin: $isAdmin) {
				id
			}
		}
	`;

	const {
		data: { AddUpdAdminToProject }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			isAdmin
		}
	});

	return AddUpdAdminToProject;
};

export const updateShowWizard = async ({ userId, showWizard }) => {
	const mutation = gql`
		mutation UPDATE_SHOW_WIZARD($userId: ID!, $showWizard: Boolean!) {
			UpdateShowWizard(UserId: $userId, ShowWizard: $showWizard)
		}
	`;

	const {
		data: { updateShowWizard }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			showWizard
		}
	});

	return updateShowWizard;
};

export const AddUpdGateKeeper = async ({ userId, GateKeeper }) => {
	const mutation = gql`
		mutation ADD_UPD_GATE_KEEPER($userId: ID!, $GateKeeper: Boolean!) {
			AddUpdGateKeeper(UserID: $userId, GateKeeper: $GateKeeper) {
				id
			}
		}
	`;

	const {
		data: { AddUpdGateKeeper }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			GateKeeper
		}
	});

	return AddUpdGateKeeper;
};

export const addUpdUpm = async ({ userId, Upm }) => {
	const mutation = gql`
		mutation ADD_UPD_UPM($userId: ID!, $Upm: Boolean!) {
			AddUpdUpm(UserID: $userId, Upm: $Upm) {
				id
			}
		}
	`;

	const {
		data: { AddUpdUpm }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			Upm
		}
	});

	return AddUpdUpm;
};

export const AddUpdProductionAccountant = async ({ userId, ProductionAccountant }) => {
	const mutation = gql`
		mutation ADD_UPD_PRODUCTION_ACCOUNTANT($userId: ID!, $ProductionAccountant: Boolean!) {
			AddUpdProductionAccountant(UserID: $userId, ProductionAccountant: $ProductionAccountant) {
				id
			}
		}
	`;

	const {
		data: { AddUpdProductionAccountant }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			ProductionAccountant
		}
	});

	return AddUpdProductionAccountant;
};

export const AddUpdLineProducer = async ({ userId, LineProducer }) => {
	const mutation = gql`
		mutation ADD_UPD_LINE_PRODUCER($userId: ID!, $LineProducer: Boolean!) {
			AddUpdLineProducer(UserID: $userId, LineProducer: $LineProducer) {
				id
			}
		}
	`;

	const {
		data: { AddUpdLineProducer }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			LineProducer
		}
	});

	return AddUpdLineProducer;
};

export const addUpdStudio = async ({ userId, Studio }) => {
	const mutation = gql`
		mutation ADD_UPD_STUDIO($userId: ID!, $Studio: Boolean!) {
			AddUpdStudio(UserID: $userId, Studio: $Studio) {
				id
			}
		}
	`;

	const {
		data: { AddUpdStudio }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			Studio
		}
	});

	return AddUpdStudio;
};

export const AddUpdCanAccessOnBoardingScreen = async ({ userId, CanAccessOnBoardingScreen }) => {
	const mutation = gql`
		mutation ADD_UPD_CAN_ACCESS_ONBOARDING_SCREEN($userId: ID!, $CanAccessOnBoardingScreen: Boolean!) {
			AddUpdCanAccessOnBoardingScreen(UserID: $userId, CanAccessOnBoardingScreen: $CanAccessOnBoardingScreen) {
				id
			}
		}
	`;

	const {
		data: { AddUpdCanAccessOnBoardingScreen }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: parseInt(userId, 10),
			CanAccessOnBoardingScreen
		}
	});

	return AddUpdCanAccessOnBoardingScreen;
};

export const verifyPassword = async (email, password) => {
	try {
		const MUTATION_VERIFY_PASSWORD = gql`
			mutation ($email: String!, $password: String!) {
				VerifyPassword(email: $email, password: $password)
			}
		`;

		const {
			data: { VerifyPassword }
		} = await apollo.mutate({
			mutation: MUTATION_VERIFY_PASSWORD,
			variables: {
				email,
				password
			}
		});

		return VerifyPassword;
	} catch (error) {
		console.log(error);
	}
};

export const DelValidationRole = async ({ ValidationId, UserId, ProjectId, ValLvlDoc }) => {
	if (isNil(UserId)) return;
	try {
		const mutation = gql`
			mutation ($ValidationId: ID!, $UserId: ID, $ProjectId: ID, $ValLvlDoc: ID) {
				DelValidation(ValidationId: $ValidationId, UserId: $UserId, ProjectId: $ProjectId, ValLvlDoc: $ValLvlDoc)
			}
		`;
		const {
			data: { DelValidation }
		} = await apollo.mutate({
			mutation,
			variables: {
				ValidationId: ValidationId,
				UserId: UserId,
				ProjectId: ProjectId,
				ValLvlDoc: ValLvlDoc
			}
		});
		return DelValidation;
	} catch (e) {
		console.error({ e });
	}
};

export const AddUpdValidationRole = async ({ ValidationId, UserId, ProjectId, UpdValidation }) => {
	if (isNil(UserId)) return;
	try {
		const mutation = gql`
			mutation ($ValidationId: ID!, $UserId: ID!, $ProjectId: ID!, $UpdValidation: ValidationInput!) {
				AddUpdValidation(ValidationId: $ValidationId, UserId: $UserId, ProjectId: $ProjectId, UpdValidation: $UpdValidation) {
					id
				}
			}
		`;
		const {
			data: { AddUpdValidation }
		} = await apollo.mutate({
			mutation,
			variables: {
				ValidationId: ValidationId,
				UserId: UserId,
				ProjectId: ProjectId,
				UpdValidation: UpdValidation
			}
		});
		return AddUpdValidation;
	} catch (e) {
		console.error({ e });
	}
};

export const actionAccessScreen = async ({ UpAccess }) => {
	try {
		const mutation = gql`
			mutation ($UpAccess: UserProjectInput!) {
				AddUpdUserProjectAccessLevel(UpAccess: $UpAccess)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				UpAccess: UpAccess
			}
		});
		return data.AddUpdUserProjectAccessLevel;
	} catch (e) {
		console.log(e);
	}
};

const actionAccessRole = async (payload, arg) => {
	const { delValidationRole, addUpdValidationRole } = ACCESS_LEVEL;
	if (arg === delValidationRole) {
		let result = await DelValidationRole({ ...payload });
		return result;
	}
	if (arg === addUpdValidationRole) {
		let result = await AddUpdValidationRole({ ...payload });
		return result;
	}
};

export const addUpdUserProjectAccessLevel = async (payload, ...args) => {
	const { isRole, screenAccess } = ACCESS_LEVEL;
	if (args[0] === screenAccess) {
		let result = await actionAccessScreen({ UpAccess: { ...payload } });
		return result;
	}
	if (args[0] === isRole) {
		let result = await actionAccessRole(payload, args[1]);
		return result;
	}
};

export const addEncodingValidation = async (ProjectId, UserId = 0, TimeSheet = false, Department = [], Expense = false, Po = false, UserRequestXid) => {
	try {
		const mutation = gql`
			mutation ($ProjectId: ID!, $UserId: ID!, $TimeSheet: Boolean!, $Expense: Boolean!, $Po: Boolean!, $Department: [ID]!, $UserRequestXid: String) {
				AddEncodingValidation(
					ProjectId: $ProjectId
					UserId: $UserId
					TimeSheet: $TimeSheet
					Expense: $Expense
					Po: $Po
					Department: $Department
					UserRequestXid: $UserRequestXid
				)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				ProjectId: ProjectId,
				UserId: parseInt(UserId, 10),
				TimeSheet: TimeSheet,
				Expense: Expense,
				Po: Po,
				Department: Department,
				UserRequestXid
			}
		});
		return data.AddEncodingValidation;
	} catch (error) {
		console.log(error);
	}
};

export const GetEncodingValidation = async (ProjectId = null, UserId = null, UserRequestXid = null) => {
	try {
		const {
			data: { GetEncodingValidation }
		} = await apollo.query({
			query: gql`
				query ($ProjectId: ID!, $UserId: ID!, $UserRequestXid: String) {
					GetEncodingValidation(ProjectId: $ProjectId, UserId: $UserId, UserRequestXid: $UserRequestXid) {
						userProjectId
						timeSheets
						expenses
						po
						department {
							value
						}
					}
				}
			`,
			variables: {
				ProjectId: parseInt(ProjectId, 10),
				UserId: parseInt(UserId, 10),
				UserRequestXid
			},
			fetchPolicy: 'no-cache'
		});

		return GetEncodingValidation;
	} catch (e) {
		console.log({ e });
	}
};

export const GetPlanningValidation = async (UserID = null) => {
	try {
		const {
			data: { GetPlanningValidation }
		} = await apollo.query({
			query: gql`
				query ($UserID: ID!) {
					GetPlanningValidation(UserID: $UserID) {
						id
						createdAt
						updatedAt
						userProjectId
						userRequestXid
						type
						department
						validationLevelTs
						validationLevelPo
						validationLevelExp
						validationLevelDoc
						departmentName
						managerSalaryAccess {
							id
							managerID
							salaryType
							disable
						}
						user {
							id
							name
							email
						}
					}
				}
			`,
			variables: {
				UserID: +UserID
			},
			fetchPolicy: 'no-cache'
		});

		return GetPlanningValidation;
	} catch (e) {
		console.error({ e });
	}
};

export const getUserDashboard = async () => {
	try {
		const {
			data: { GetUserDashboard }
		} = await apollo.query({
			query: gql`
				query {
					GetUserDashboard {
						myTotalExpenses
						myTotalSalary
						myTotalPurchaseOrders
					}
				}
			`,
			variables: {},
			fetchPolicy: 'no-cache'
		});

		return GetUserDashboard;
	} catch (e) {
		console.log({ e });
	}
};

export const generateVcfFile = async (userId, sendMail = false) => {
	const mutation = gql`
		mutation ($userId: ID!, $sendMail: Boolean) {
			GenerateVcfFile(UserId: $userId, SendMail: $sendMail)
		}
	`;

	const {
		data: { GenerateVcfFile }
	} = await apollo.mutate({
		mutation,
		variables: {
			userId: +userId,
			sendMail
		}
	});

	return GenerateVcfFile;
};

export const getUsersByLicence = async (keyWord = '', limit, offset) => {
	try {
		const query = gql`
			query ($keyWord: String, $limit: ID, $offset: ID) {
				GetUsersByLicence(KeyWord: $keyWord, Limit: $limit, Offset: $offset) {
					id
					name
					firstName
					email
					function
					functionName
					departmentName
					department
					phone
				}
			}
		`;

		const {
			data: { GetUsersByLicence }
		} = await apollo.query({
			query,
			variables: {
				keyWord,
				limit,
				offset
			},
			fetchPolicy: 'no-cache'
		});

		return GetUsersByLicence;
	} catch (e) {
		console.log({ GET_USERS_BY_LICENCE: e });
	}
};
export const updateUser = async (UserId, UpdatedUser) => {
	try {
		const mutation = gql`
			mutation ($UserId: ID!, $UpdatedUser: UserInput!) {
				UpdUser(UserId: $UserId, UpdatedUser: $UpdatedUser) {
					id
				}
			}
		`;
		const {
			data: { UpdUser }
		} = await apollo.mutate({
			mutation,
			variables: {
				UserId,
				UpdatedUser
			}
		});

		return UpdUser;
	} catch (e) {
		throw new Error('Error request');
	}
};
export const addUsersToProject = async (userInput = [], newUser = false) => {
	try {
		const mutation = gql`
			mutation ($userInput: [UserInput!]!, $newUser: Boolean) {
				AddUsersToProject(UserInput: $userInput, NewUser: $newUser)
			}
		`;

		const {
			data: { AddUsersToProject }
		} = await apollo.mutate({
			mutation,
			variables: {
				userInput,
				newUser
			}
		});

		return AddUsersToProject;
	} catch (e) {
		return false;
	}
};

export const getUsersForManageRole = async (limit, offset, keyWord, onlyVisibleUser = false, hideAdmin = false) => {
	try {
		const query = gql`
			query ($offset: Int, $limit: Int, $keyWord: String, $onlyVisibleUser: Boolean, $hideAdmin: Boolean) {
				GetUsers(Offset: $offset, Limit: $limit, KeyWord: $keyWord, OnlyVisibleUser: $onlyVisibleUser, HideAdmin: $hideAdmin) {
					id
					name
					firstName
					fullName
					department
					departmentName
					function
					functionName
					role
					isUserVisible
					cannotBeDeleted
					managerSalaryAccess {
						id
						managerID
						salaryType
						disable
					}
				}
			}
		`;

		const {
			data: { GetUsers }
		} = await apollo.query({
			query,
			variables: {
				limit,
				offset,
				keyWord,
				onlyVisibleUser,
				hideAdmin
			},
			fetchPolicy: 'no-cache'
		});

		return GetUsers;
	} catch (e) {
		console.log({ ERROR_GET_USERS_FOR_MANAGE_ROLE: e });
	}
};
