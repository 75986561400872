import jwtDataFunction from './jwtDataFunction';
import { isNil } from '@/shared/utils';

export default function canAccessUsersMiddleware({ next, router, localStore }) {
	let token = localStorage.getItem('jwt-token');
	const canAccessUsers = () => {
		if (isNil(jwtDataFunction(token).TokenData)) return false;
		const ret = jwtDataFunction(token).TokenData.CanAccessUsers;
		if (ret) {
			return ret;
		}
		return false;
	};

	if (!canAccessUsers()) {
		router.replace({ name: 'Page404' });
	}

	next();
}
