import jsSHA from 'jssha';
import * as _ from 'lodash';

export function random(min, max) {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
export const shuffleArray = (array) => {
	for (let i = array.length - 1; i > 0; i--) {
		let j = Math.floor(Math.random() * (i + 1));
		let temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
	return array;
};

/**
 * check if object is empty
 * @param {Object or String} elem
 */
export const isEmpty = (elem) => {
	let _C = false;
	if (typeof elem === 'string' || elem instanceof String) {
		_C = !elem || 0 === elem.length;
	} else {
		_C = Object.keys(elem).length === 0 && elem.constructor === Object;
	}
	return _C;
};

/**
 * Convert CamelCaseString to kebab-case-string
 * @param {String} str - String to convert
 * @return {String}
 */
export const toKebabCase = (str) => {
	if (isNil(str)) return null;
	return str
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		.replace(/\s+/g, '-')
		.toLowerCase();
};

/**
 * Get child object form value
 * from Object or Array
 *
 * only use if your value is inside a object
 *
 * @param {Array or Object} focus
 * @param {String} keyName - in witch key
 * @param {Any} value - searched value
 * @return {Object}
 */
export const getObjectFromValue = (focus, keyName, value) => {
	let res = null;
	if (Array.isArray(focus)) {
		focus.forEach((elem) => {
			if (elem[keyName] === value) res = elem;
		});
	} else {
		Object.keys(focus).forEach((elem) => {
			if (focus[elem][keyName] === value) res = focus[elem];
		});
	}
	return res;
};

/**
 * transform string in Object path
 * @param source {Array|Object}
 * @param string {String}
 * @returns Object path
 */
export const convertStringToPropGetter = (source, string) => {
	if (isNil(string)) return;
	return string.split('.').reduce((o, i) => o[i], source);
};

/**
 * Check if element is null or undefined
 * @param {*} any - String to convert
 * @return {Boolean}
 */
export const isNil = (any) => {
	return any === undefined || any === null;
};

/**
 * Return only object's keys needed
 * @param {Array} arr
 * @param {Object} obj
 * @return {Object}
 */
export const objReducer = (arr, obj) => {
	const res = {};
	arr.forEach((key) => {
		Object.assign(res, { [key]: obj[key] });
	});
	return res;
};

/**
 * Omit one or multiple keys from Object
 * @param {Array} keys
 * @param {Object} obj
 */
export const omit = (keys, obj) => {
	const _o = Object.entries(obj);
	return Object.fromEntries(_o.filter(([k]) => !keys.includes(k)));
};

/**
 * @param {Array} arr
 * @param {*} val
 * @return {Array}
 */
export const removeFromArray = (arr, val) => {
	Array.prototype.remove = function () {
		var what,
			a = arguments,
			L = a.length,
			ax;
		while (L && this.length) {
			what = a[--L];
			while ((ax = this.indexOf(what)) !== -1) {
				this.splice(ax, 1);
			}
		}
		return this;
	};

	arr.remove(val);
};

/**
 * Check if a string is a base64 format
 * @param {String} str
 * @return {Boolean}
 */
export const isBase64 = (str) => {
	return str.includes(';base64,');
};

/**
 * generate a random string
 * @param {String} name - name of the id
 * @param {Number} length - length of random string
 * @return {String}
 */
export const makeID = (length = 10) => {
	const n = length === undefined ? 6 : length;
	let result = '';
	const chr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const chrLength = chr.length;
	for (let i = 0; i < n; i++) {
		result += chr.charAt(Math.floor(Math.random() * chrLength));
	}
	return '_' + result;
};

/**
 * gonna merge two object if 2th params is true
 * @param {Object} source
 * @param {Boolean} condition
 * @param {Object} merge
 * @return {Object}
 */
export const mergeObjIf = (source = {}, condition = false, merge = {}) => {
	const _m = condition === false ? {} : merge;
	return Object.assign({}, source, _m);
};

/**
 * Gonna rend a string of params
 * @param {Object} obj
 * @param {String} firstChar
 * @return {String}
 */
export const constructQueries = (obj, firstChar = '?') => {
	const arr = [];
	Object.keys(obj).forEach((key) => arr.push(`${key}=${obj[key]}`));
	return firstChar + arr.join('&');
};

/**
 * @param {String} str
 * @return {String}
 */
export const nameKey = (str) => {
	return str.trim().toLowerCase();
};

/**
 * Check if element is an object
 * @param {*} any
 * @return {Boolean}
 */
export const isObj = (any) => {
	if (isNil(any)) return false;
	return typeof any === 'object' && !Array.isArray(any);
};

/**
 * Detect if it's an Array or Object
 * @param {Object or Array} a
 * @param {Object or Array} b
 * @return {Boolean}
 */
export const isEqual = (a, b) => {
	if (arguments.length < 2) throw Error('You only can compar 2 element');

	Object.compare = function (obj1, obj2) {
		//Loop through properties in object 1
		for (var p in obj1) {
			//Check property exists on both objects
			if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

			switch (typeof obj1[p]) {
				//Deep compare objects
				case 'object':
					if (!Object.compare(obj1[p], obj2[p])) return false;
					break;
				//Compare function code
				case 'function':
					if (typeof obj2[p] == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) return false;
					break;
				//Compare values
				default:
					if (obj1[p] != obj2[p]) return false;
			}
		}

		//Check object 2 for any extra properties
		for (var p in obj2) if (isNil(obj1[p])) return false;
		return true;
	};

	Array.prototype.equals = function (array) {
		// if the other array is a falsy value, return
		if (!array) return false;

		// compare lengths - can save a lot of time
		if (this.length != array.length) return false;

		for (var i = 0, l = this.length; i < l; i++) {
			// Check if we have nested arrays
			if (this[i] instanceof Array && array[i] instanceof Array) {
				// recurse into the nested arrays
				if (!this[i].equals(array[i])) return false;
			} else if (this[i] != array[i]) {
				// Warning - two different object instances will never be equal: {x:20} != {x:20}
				return false;
			}
		}
		return true;
	};

	const isObject = isObj(a) && isObj(b);
	const isArray = Array.isArray(a) && Array.isArray(b);

	if (isObject) return Object.compare(a, b);
	if (isArray) return a.equals(b);

	throw new Error("Can only compare 'Arrays' or 'Object'");
};

export const capitalize = (s) => {
	if (typeof s !== 'string') return '';

	return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
	// return s.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
};

export const noTilde = (s) => {
	if (s.normalize !== undefined) {
		s = s.normalize('NFKD');
	}

	return s.normalize('NFKD').replace(/[\u0300-\u036F]/g, '');
};

export const toTilde = (s) => {
	return s
		.replace(/ae/g, '[ae,æ]')
		.replace(/oe/g, '[oe,œ]')
		.replace(/a/g, '[a,á,à,â,ã,ª,ä]')
		.replace(/e/g, '[e,é,è,ê,ë]')
		.replace(/i/g, '[i,í,ì,î,ï]')
		.replace(/o/g, '[o,ó,ò,ô,õ,º,ö]')
		.replace(/u/g, '[u,ú,ù,û,ü]')
		.replace(/c/g, '[c,ç]');
};

/**
 * @param {String} text
 * @return {Html}
 */
export const urlMatcher = (text) => {
	const regex =
		/(?:(?:https?|http|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

	return text.replace(regex, (match) => `<a target="_blank" href="${match}">${match}</a>`);
};

/**
 * @param {Object} obj
 * @param {String} key
 * @return {Boolean}
 */
export const hasOwnProperty = (obj, key) => {
	return Object.prototype.hasOwnProperty.call(obj, key);
};

export const generateRandomFilename = (len = 20) => {
	const sha = new jsSHA('SHA-256', 'TEXT');
	sha.update(new Date().getTime());

	const characters = sha.getHash('HEX').substring(0, len);
	let random = '';

	for (let i = 0; i < len; i++) {
		random += characters.charAt(Math.floor(Math.random() * len));
	}

	return random;
};

export const generateSecureId = (id) => {
	const sha = new jsSHA('SHA-256', 'TEXT');
	sha.update(id);

	return sha.getHash('HEX');
};

export const greaterThanZero = (value) => !isNaN(value) && +value > 0;

export const hourMinuteValid = (value) => {
	return !/^([0]{2}:[0]{2})$/.test(value);
};

export const emailValid = (value) => {
	/**
	 * source: https://www.w3resource.com/javascript/form/email-validation.php
	 */
	const exp = /^\w+([\.\-\/+]?\w+)*@\w+([\.\-]?\w+)*(\.\w{2,})+$/;

	return exp.test(value);
};

export const phoneNumberValid = (value) => {
	/**
	 * source: https://uibakery.io/regex-library/phone-number
	 */
	const exp = /^\+?\d{1,4}?[\-\.\s]?\(?\d{1,3}?\)?[\-\.\s]?\d{1,4}[\-\.\s]?\d{1,4}[\-\.\s]?\d{1,9}$/;

	return exp.test(value);
};

export const removeAttributeTree = (originalTree, attrToRemove, criteria, copyTree = null) => {
	if (Array.isArray(originalTree)) {
		if (_.isNil(copyTree)) {
			copyTree = originalTree;
		}

		for (const node of originalTree) {
			if (node[attrToRemove] === criteria) {
				delete node[attrToRemove];
			} else {
				originalTree = removeAttributeTree(node.children, attrToRemove, criteria, copyTree);
			}
			originalTree = copyTree;
		}

		return originalTree;
	}

	return [];
};

export const searchInTheTree = (ob, key, value) => {
	let path = [];
	const keyExists = (obj) => {
		if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
			return false;
		} else if (obj.hasOwnProperty(key) && obj[key].toLowerCase().indexOf(value) > -1) {
			return true;
		} else if (Array.isArray(obj)) {
			let parentKey = path.length ? path.pop() : '';

			for (let i = 0; i < obj.length; i++) {
				path.push(`${i}`);
				const result = keyExists(obj[i], key);
				if (result) {
					return result;
				}
				path.pop();
			}
		} else {
			for (const k in obj) {
				path.push(k);
				const result = keyExists(obj[k], key);
				if (result) {
					return result;
				}
				path.pop();
			}
		}

		return false;
	};

	keyExists(ob);

	return path.join('.');
};

export const getDefaultValueCategoryTree = (categoryTree, functionId, departmentId = 0, level = 0) => {
	if (!Array.isArray(categoryTree) || _.isNil(functionId) || parseInt(functionId, 10) === 0) return 0;

	functionId = parseInt(functionId, 10);
	departmentId = parseInt(departmentId, 10);
	level = parseInt(level, 10);

	// search at level 0
	let foundAtLevel = 0;
	let hasChildren = false;

	for (const node of categoryTree) {
		if (_.isNil(node.children)) {
			if (!isNaN(node.data.category) && parseInt(node.data.category, 10) === functionId) {
				foundAtLevel = node.id;
				break;
			}
		} else {
			hasChildren = true;
			continue;
		}
	}

	// search at deep
	if (foundAtLevel === 0 && hasChildren) {
		const getDefaultValue = (categoryTree, functionId, departmentId, level) => {
			let tmpFoundAtLevel = 0;
			let holdDefaultValueFound = 0;
			for (const node of categoryTree) {
				if (!_.isNil(node.children)) {
					if (level === 0 && parseInt(node.data.department, 10) === departmentId) {
						if (!_.isNil(node.children)) {
							level = level + 1;
							tmpFoundAtLevel = getDefaultValue(node.children, functionId, departmentId, level);
						} else {
							tmpFoundAtLevel = node.id;
							break;
						}
					} else {
						if (parseInt(node.data.category, 10) === functionId) {
							if (!_.isNil(node.children)) {
								const children = node.children;
								for (const [index, child] of children.entries()) {
									if (!_.isNil(child.children)) {
										level = level + 1;
										tmpFoundAtLevel = getDefaultValue(child.children, functionId, departmentId, level);
									} else {
										if (index === 0) {
											holdDefaultValueFound = child.id;
										}
										if (parseInt(child.data.category, 10) === functionId) {
											tmpFoundAtLevel = child.id;
											break;
										}
									}
								}
							} else {
								tmpFoundAtLevel = node.id;
								break;
							}
						}
					}
				} else {
					if (parseInt(node.data.category, 10) === functionId) {
						tmpFoundAtLevel = node.id;
						break;
					}
					continue;
				}
			}

			if (tmpFoundAtLevel === 0) tmpFoundAtLevel = holdDefaultValueFound;

			return tmpFoundAtLevel;
		};

		foundAtLevel = getDefaultValue(categoryTree, functionId, departmentId, level);
	}

	return foundAtLevel;
};

export const dateValid = (value) => {
	const exp = /(^[12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$)|(^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/[12]\d{3}$)/;

	return exp.test(value);
};

export const zipCodeValid = (value) => {
	/**
	 * source: https://uibakery.io/regex-library/zip-code
	 */
	const exp = /^[0-9]{4,5}(?:-[0-9]{4})?$/;

	return exp.test(value);
};

export const minMaxLengthValue = (value, min, max, isString = true) => {
	let exp;

	if (isString) {
		// exp = //;
	}

	return exp.test(value);
};

export const extensiontValid = (value) => {
	const exp = /^[a-z]{2,}$/;

	return exp.test(value);
};

export const formatFileName = (value, separator = '-') => {
	value = noTilde(value);
	value = value.replace(/[\.\-\/+&@#%=~_|$?!:,*;\'"\s\`/]+/g, separator);
	value = /[\-_]$/.test(value) ? value.substring(0, value.length - 1) : value;
	value = /^[\-_]/.test(value) ? value.substring(1) : value;

	return value;
};
