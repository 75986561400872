import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { getPoItems } from '@/cruds/po-item.crud';

const state = () => ({
	poItems: []
});

const getters = {
	poItems: (state) => state.poItems
};

const mutations = {
	SET_PO_ITEMS(state, items) {
		state.poItems = items;
	}
};

const actions = {
	async updatePoItem({ dispatch }, { id, item, poid }) {
		try {
			const mutation = gql`
				mutation ($PoItemId: ID!, $UpdatedPoItem: PoItemInput!) {
					UpdatePoItem(PoItemId: $PoItemId, UpdatedPoItem: $UpdatedPoItem) {
						id
						poId
						name
						quantity
						price
						total
						poTotal
					}
				}
			`;

			await apollo.mutate({
				mutation,
				variables: {
					PoItemId: parseInt(id),
					UpdatedPoItem: item
				}
			});
			dispatch('getPoItems', poid);
		} catch (e) {
			console.log({ e });
		}
	},

	async deletePoItem({ dispatch }, { id, poid }) {
		try {
			const mutation = gql`
				mutation ($PoItemId: ID!) {
					DelPoItem(PoItemId: $PoItemId) {
						id
					}
				}
			`;
			await apollo.mutate({
				mutation,
				variables: {
					PoItemId: parseInt(id)
				}
			});
			dispatch('getPoItems', poid);
		} catch (e) {
			console.log({ e });
		}
	},

	async getPoItems({ commit }, poid) {
		try {
			const GetPoItemList = await getPoItems(poid);
			commit('SET_PO_ITEMS', GetPoItemList);
		} catch (e) {
			console.log({ e });
		}
	},

	async cleanPOItems({ commit }) {
		commit('SET_PO_ITEMS', []);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
