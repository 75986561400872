import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';
// import { store } from "@/store";

/**
 * get form Names array
 * @param {}
 */

export const getNavigatorLanguageValue = (lang) => {
	var LANG_EN = 0;
	var LANG_FR = 1;
	var LANG_DE = 2; // allemands
	var LANG_IT = 3;
	var LANG_NE = 4; // neerlandais
	var LANG_ES = 5;
	var LANG_PT = 6;
	var LANG_SK = 7;
	var curLanguage = LANG_EN; // default english
	//string{"en", "fr", "de", "it", "nl", "es", "pt", "sl"}
	var lang = navigator.language;
	if (lang.slice(0, 2) == 'fr') {
		curLanguage = LANG_FR; // french
	} else if (lang.slice(0, 2) == 'de') {
		curLanguage = LANG_DE; // german
	} else if (lang.slice(0, 2) == 'it') {
		curLanguage = LANG_IT; // italian
	} else if (lang.slice(0, 2) == 'ne') {
		curLanguage = LANG_NE; // dutch
	} else if (lang.slice(0, 2) == 'es') {
		curLanguage = LANG_ES; // spanish
	} else if (lang.slice(0, 2) == 'pt') {
		curLanguage = LANG_PT; // portuguese
	} else if (lang.slice(0, 2) == 'sk') {
		curLanguage = LANG_SK; // slovak
	}

	return curLanguage;
};

export const getFormNames = async () => {
	const { data } = await apollo.query({
		query: gql`
			query {
				GetForms {
					message
					value
				}
			}
		`,
		variables: {},
		fetchPolicy: 'no-cache'
	});

	return data.GetForms;
};

export const getAllFormsData = async (targetLanguage) => {
	//console.log("in getallformsdata, targetLanguage=",targetLanguage);

	var curLanguage = getNavigatorLanguageValue(navigator.language);

	//console.log("navigator languate value in getallformdata:",curLanguage);

	const { data } = await apollo.query({
		query: gql`
			query ($WebForm: Boolean, $TargetLanguage: Int!) {
				GetAllFormsContent(WebForm: $WebForm, TargetLanguage: $TargetLanguage) {
					formName
					formNumber
					message
					value
				}
			}
		`,
		variables: {
			WebForm: true,
			//TargetLanguage: !isNil(targetLanguage) ? parseInt(targetLanguage) : -1
			TargetLanguage: !isNil(targetLanguage) ? parseInt(targetLanguage) : parseInt(curLanguage)
		},
		fetchPolicy: 'no-cache'
	});

	return data.GetAllFormsContent;
};

export const getAllLoginFormsData = async (targetLanguage) => {
	// console.log("in getallLOGINformsdata, targetLanguage=",targetLanguage);
	const { data } = await apollo.query({
		query: gql`
			query ($WebForm: Boolean, $TargetLanguage: Int!) {
				GetAllLoginFormsContent(WebForm: $WebForm, TargetLanguage: $TargetLanguage) {
					formName
					formNumber
					message
					value
				}
			}
		`,
		variables: {
			WebForm: true,
			TargetLanguage: !isNil(targetLanguage) ? parseInt(targetLanguage) : -1
		},
		fetchPolicy: 'no-cache'
	});

	return data.GetAllLoginFormsContent;
};

export const getAllMenus = async (targetLanguage) => {
	var curLanguage = getNavigatorLanguageValue(navigator.language);

	const { data } = await apollo.query({
		query: gql`
			query ($TargetLanguage: Int!) {
				GetAllMenus(TargetLanguage: $TargetLanguage) {
					number
					message
					value
					paramFloat
				}
			}
		`,
		variables: {
			//  TargetLanguage:!isNil(targetLanguage) ? parseInt(targetLanguage) : -1
			TargetLanguage: !isNil(targetLanguage) ? parseInt(targetLanguage) : parseInt(curLanguage)
		},
		fetchPolicy: 'no-cache'
	});
	//console.log("in getAllMenus all menus:",data.GetAllMenus);
	return data.GetAllMenus;
};

export const translateAllItems = async (targetLanguage = null) => {
	const { data } = await apollo.query({
		query: gql`
			query ($targetLanguage: Int) {
				GenerateAllTargetLanguages(TargetLanguage: $targetLanguage) {
					message
					type
					number
					value
					translatedMessage
				}
			}
		`,
		variables: {
			targetLanguage
		},
		fetchPolicy: 'no-cache'
	});
	//console.log("in getAllMenus all menus:",data.GetAllMenus);
	return data.GenerateAllTargetLanguages;
};

export const translateLanguageItems = async (targetLanguage = null) => {
	if (isNil(targetLanguage)) return [];

	const {
		data: { GenerateTargetLanguage }
	} = await apollo.query({
		query: gql`
			query ($targetLanguage: Int!) {
				GenerateTargetLanguage(TargetLanguage: $targetLanguage) {
					message
					type
					number
					value
					translatedMessage
				}
			}
		`,
		variables: {
			targetLanguage
		},
		fetchPolicy: 'no-cache'
	});

	return GenerateTargetLanguage;
};

export const GetAllFormsContentForTranslation = async (targetLanguage, webForm, formNumber = 0, toCheck = false, size = 0, offset = 0) => {
	const { data } = await apollo.query({
		query: gql`
			query ($TargetLanguage: Int!, $WebForm: Boolean, $FormNumber: Int, $ToCheck: Boolean, $Size: Int, $Offset: Int) {
				GetAllFormsContentForTranslation(
					TargetLanguage: $TargetLanguage
					WebForm: $WebForm
					FormNumber: $FormNumber
					ToCheck: $ToCheck
					Size: $Size
					Offset: $Offset
				) {
					id
					formName
					formNumber
					message
					type
					number
					value
					paramFloat
					translatedMessage
					toCheck
				}
			}
		`,
		variables: {
			TargetLanguage: parseInt(targetLanguage, 10),
			WebForm: webForm,
			FormNumber: parseInt(formNumber, 10),
			ToCheck: toCheck,
			Size: parseInt(size, 10),
			Offset: parseInt(offset, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return data.GetAllFormsContentForTranslation;
};

export const getMenuArrayFromDb = async (menuId) => {
	var menuFromDB = await getMenu(menuId);
	console.log('in getMenuArrayFromDb menu:', menuFromDB);
	var foundMenuArray = [];
	for (var i = 0; i < menuFromDB.length; i++) {
		// adding item in curMenuObj
		var curMenuItem = {};
		curMenuItem.value = parseInt(menuFromDB[i].value);
		curMenuItem.text = menuFromDB[i].message;
		console.log('in FromMenu, getting menu from DB:', curMenuItem);
		foundMenuArray.push(curMenuItem);
	}
	console.log('in getMenuArrayFromDb, menuarray:', foundMenuArray);
	return foundMenuArray;
};

export const getMenu = async (menuId) => {
	const { data } = await apollo.query({
		query: gql`
			query ($MenuNumber: Int!) {
				GetMenu(MenuNumber: $MenuNumber) {
					message
					value
				}
			}
		`,
		variables: {
			MenuNumber: parseInt(menuId)
		},
		fetchPolicy: 'no-cache'
	});

	return data.GetMenu;
};

export const GetAllMenusContentForTranslation = async (targetLanguage, toCheck = false, size = 0, offset = 0) => {
	const { data } = await apollo.query({
		query: gql`
			query ($TargetLanguage: Int!, $ToCheck: Boolean, $Size: Int, $Offset: Int) {
				GetAllMenusContentForTranslation(TargetLanguage: $TargetLanguage, ToCheck: $ToCheck, Size: $Size, Offset: $Offset) {
					id
					formName
					formNumber
					message
					type
					number
					value
					paramFloat
					translatedMessage
					toCheck
				}
			}
		`,
		variables: {
			TargetLanguage: parseInt(targetLanguage, 10),
			ToCheck: toCheck,
			Size: parseInt(size, 10),
			Offset: parseInt(offset, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return data.GetAllMenusContentForTranslation;
};

export const GetAllMessagesForTranslation = async (targetLanguage, toCheck = false, size = 0, offset = 0) => {
	const { data } = await apollo.query({
		query: gql`
			query ($TargetLanguage: Int!, $ToCheck: Boolean, $Size: Int, $Offset: Int) {
				GetAllMessagesForTranslation(TargetLanguage: $TargetLanguage, ToCheck: $ToCheck, Size: $Size, Offset: $Offset) {
					id
					formName
					formNumber
					message
					type
					number
					value
					paramFloat
					translatedMessage
					toCheck
				}
			}
		`,
		variables: {
			TargetLanguage: parseInt(targetLanguage, 10),
			ToCheck: toCheck,
			Size: parseInt(size, 10),
			Offset: parseInt(offset, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return data.GetAllMessagesForTranslation;
};

/**
 * get form content, returns all translated messages for the form
 * @param {String, Number} supplierID
 */
export const getFormContent = async (formNumber) => {
	if (isNil(formNumber)) return;
	try {
		const {
			data //: { GetFormContent }
		} = await apollo.query({
			query: gql`
				query ($FormNumber: Int!) {
					GetFormContent(FormNumber: $FormNumber) {
						message
						value
					}
				}
			`,
			variables: {
				FormNumber: parseInt(formNumber)
			},
			fetchPolicy: 'no-cache'
		});

		return data.GetFormContent;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * add a new form to database
 * @param {String, Number} supplierID
 */
export const addForm = async (formName) => {
	try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation ($Name: String!, $WebForm: Boolean) {
					AddForm(Name: $Name, WebForm: $WebForm) {
						message
						value
					}
				}
			`,
			variables: {
				Name: formName,
				WebForm: true
			}
		});

		return data.AddForm;
	} catch (e) {
		throw new Error(e);
	}
};

export const addLabelToFormContentInDB = async (formId, labelId, labelText, language, webForm) => {
	try {
		const mutation = gql`
			mutation ($FormNumber: ID!, $Message: String!, $LabelId: Int!, $Language: Int, $WebForm: Boolean) {
				AddUpdFormItem(FormNumber: $FormNumber, Message: $Message, LabelId: $LabelId, Language: $Language, WebForm: $WebForm) {
					message
					value
				}
			}
		`;
		const {
			data //: { AddUpdFormItem }
		} = await apollo.mutate({
			mutation,
			variables: {
				FormNumber: parseInt(formId),
				LabelId: parseInt(labelId),
				Message: labelText,
				Language: parseInt(language),
				WebForm: webForm
			}
		});

		return data.AddUpdFormItem;
	} catch (e) {
		console.log({ e });
	}
};

export const AddUpdLangResItem = async (resType, resNumber, resValue, language, message, paramFloat) => {
	try {
		const mutation = gql`
			mutation ($ResType: Int!, $ResNumber: Int!, $ResValue: Int!, $Language: Int!, $Message: String!, $ParamFloat: Float) {
				AddUpdLangResItem(
					ResType: $ResType
					ResNumber: $ResNumber
					ResValue: $ResValue
					Language: $Language
					Message: $Message
					ParamFloat: $ParamFloat
				) {
					message
					type
					number
					value
					paramFloat
				}
			}
		`;
		const {
			data //: { AddUpdFormItem }
		} = await apollo.mutate({
			mutation,
			variables: {
				ResType: parseInt(resType),
				ResNumber: parseInt(resNumber),
				ResValue: parseInt(resValue),
				Message: message,
				Language: parseInt(language),
				ParamFloat: parseFloat(paramFloat)
			}
		});

		return data.AddUpdLangResItem;
	} catch (e) {
		console.log({ e });
	}
};

export const deleteLanguageItem = async (resType, resNumber, resValue) => {
	try {
		const mutation = gql`
			mutation ($ResType: Int!, $ResNumber: Int!, $ResValue: Int!) {
				DelLangResItem(ResType: $ResType, ResNumber: $ResNumber, ResValue: $ResValue)
			}
		`;
		//console.log("webForm:",webForm);
		const {
			data //: { AddUpdFormItem }
		} = await apollo.mutate({
			mutation,
			variables: {
				ResType: parseInt(resType),
				ResNumber: parseInt(resNumber),
				ResValue: parseInt(resValue)
			}
		});

		return data.DelLangResItem;
	} catch (e) {
		console.log({ e });
	}
};

export const deleteFormItem = async (formId, labelId, webForm) => {
	try {
		const mutation = gql`
			mutation ($FormNumber: Int!, $ItemValue: Int!, $WebForm: Boolean) {
				DelFormItem(FormNumber: $FormNumber, ItemValue: $ItemValue, WebForm: $WebForm)
			}
		`;
		//console.log("webForm:",webForm);
		const {
			data //: { AddUpdFormItem }
		} = await apollo.mutate({
			mutation,
			variables: {
				FormNumber: parseInt(formId),
				ItemValue: parseInt(labelId),
				WebForm: webForm
			}
		});

		return data.DelFormItem;
	} catch (e) {
		console.log({ e });
	}
};

export const setGlobalMenuMap = (menuMap) => {
	//console.log("GlobalMenuMap:",menuMap);
	GlobalMenuMap = menuMap;
};

export let GlobalMenuMap;

export const getTextFromMenuNumberAndMenuValue = (mnuNumber, mnuValue) => {
	var menuMap = GlobalMenuMap;
	if (isNil(menuMap)) {
		return 'Not loaded yet';
	}
	//console.log("getTextFromMenuNumberAndMenuValue menuMap:", menuMap);
	var mnu = menuMap.get(parseInt(mnuNumber));
	//console.log("getTextFromMenuNumberAndMenuValue, foundMenuArray:", mnu);
	if (mnu == undefined) {
		//console.log("in FormMenu menu not found:", menuId);
		return 'not found';
	}
	//console.log("in GetTextFromMenuNumberAndMenuValue, mnuNumber mnuValue",mnuNumber, mnuValue);
	var mnuVal = parseInt(mnuValue);
	for (var i = 0; i < mnu.length; i++) {
		if (mnu[i].value == mnuVal) {
			//console.log("found message:",mnu[i].text);
			return mnu[i].text;
		}
	}

	return 'Not found';
};

export const getFormItem = async (number, value) => {
	try {
		const {
			data: { GetFormItem }
		} = await apollo.query({
			query: gql`
				query ($number: ID!, $value: ID!) {
					GetFormItem(ResNumber: $number, ResValue: $value) {
						number
						value
						message
						paramFloat
					}
				}
			`,
			variables: {
				number: parseInt(number, 10),
				value: parseInt(value, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetFormItem;
	} catch (e) {
		console.log({ e });
	}
};

export const getAllFormsNameOnly = async (targetLanguage, webForm) => {
	const {
		data: { GetAllFormsNameOnly }
	} = await apollo.query({
		query: gql`
			query ($targetLanguage: Int!, $webForm: Boolean) {
				GetAllFormsNameOnly(TargetLanguage: $targetLanguage, WebForm: $webForm) {
					formName
					formNumber
				}
			}
		`,
		variables: {
			targetLanguage: parseInt(targetLanguage, 10),
			webForm
		},
		fetchPolicy: 'no-cache'
	});

	return GetAllFormsNameOnly;
};

export const searchtAllFormsContentForTranslation = async (targetLanguage, webForm, formNumber = 0, toCheck = false, text = null, size = 0, offset = 0) => {
	const {
		data: { SearchtAllFormsContentForTranslation }
	} = await apollo.query({
		query: gql`
			query ($TargetLanguage: Int!, $WebForm: Boolean, $FormNumber: Int, $ToCheck: Boolean, $Text: String, $Size: Int, $Offset: Int) {
				SearchtAllFormsContentForTranslation(
					TargetLanguage: $TargetLanguage
					WebForm: $WebForm
					FormNumber: $FormNumber
					ToCheck: $ToCheck
					Text: $Text
					Size: $Size
					Offset: $Offset
				) {
					id
					formName
					formNumber
					message
					type
					number
					value
					paramFloat
					translatedMessage
					toCheck
				}
			}
		`,
		variables: {
			TargetLanguage: parseInt(targetLanguage, 10),
			WebForm: webForm,
			FormNumber: parseInt(formNumber, 10),
			ToCheck: toCheck,
			Text: text,
			Size: parseInt(size, 10),
			Offset: parseInt(offset, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return SearchtAllFormsContentForTranslation;
};

export const searchAllMenusContentForTranslation = async (targetLanguage, toCheck = false, text = null, size = 0, offset = 0) => {
	const {
		data: { SearchAllMenusContentForTranslation }
	} = await apollo.query({
		query: gql`
			query ($TargetLanguage: Int!, $ToCheck: Boolean, $Text: String, $Size: Int, $Offset: Int) {
				SearchAllMenusContentForTranslation(TargetLanguage: $TargetLanguage, ToCheck: $ToCheck, Text: $Text, Size: $Size, Offset: $Offset) {
					id
					formName
					formNumber
					message
					type
					number
					value
					paramFloat
					translatedMessage
					toCheck
				}
			}
		`,
		variables: {
			TargetLanguage: parseInt(targetLanguage, 10),
			ToCheck: toCheck,
			Text: text,
			Size: parseInt(size, 10),
			Offset: parseInt(offset, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return SearchAllMenusContentForTranslation;
};

export const searchAllMessagesForTranslation = async (targetLanguage, toCheck = false, text = null, size = 0, offset = 0) => {
	const {
		data: { SearchAllMessagesForTranslation }
	} = await apollo.query({
		query: gql`
			query ($TargetLanguage: Int!, $ToCheck: Boolean, $Text: String, $Size: Int, $Offset: Int) {
				SearchAllMessagesForTranslation(TargetLanguage: $TargetLanguage, ToCheck: $ToCheck, Text: $Text, Size: $Size, Offset: $Offset) {
					id
					formName
					formNumber
					message
					type
					number
					value
					paramFloat
					translatedMessage
					toCheck
				}
			}
		`,
		variables: {
			TargetLanguage: parseInt(targetLanguage, 10),
			ToCheck: toCheck,
			Text: text,
			Size: parseInt(size, 10),
			Offset: parseInt(offset, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return SearchAllMessagesForTranslation;
};

export const updateLanguageCheckStatus = async (id, toCheck = false) => {
	try {
		const mutation = gql`
			mutation ($id: ID!, $toCheck: Boolean) {
				UpdateLanguageCheckStatus(LangResId: $id, ToCheck: $toCheck) {
					id
					formName
					formNumber
					message
					type
					number
					value
					paramFloat
					translatedMessage
					toCheck
				}
			}
		`;
		const {
			data: { UpdateLanguageCheckStatus }
		} = await apollo.mutate({
			mutation,
			variables: {
				id: parseInt(id, 10),
				toCheck
			}
		});

		return UpdateLanguageCheckStatus;
	} catch (e) {
		console.log({ e });
	}
};
