var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "cursor-pointer footer-item",
      class: `${_vm.toggleDetail ? "collapsed" : "expanded"}`,
      on: { click: _vm.handleToggleDetail },
    },
    [
      _c("span", { staticClass: "mr-1" }, [
        _vm._v(
          _vm._s(
            _vm.toggleDetail
              ? _vm.FormMSG(1, "Show details")
              : _vm.FormMSG(2, "Hide details")
          )
        ),
      ]),
      _vm.toggleDetail
        ? _c("span", [_c("chevron-down", { attrs: { size: 18 } })], 1)
        : _c("span", [_c("chevron-up", { attrs: { size: 18 } })], 1),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }