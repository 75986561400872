export const ICONS = {
	TRASH: { name: 'Trash2', color: '#EA4E2C' },
	TRASH_MOBILE: { name: 'Trash', color: '#06263E' },
	EDIT: { name: 'Edit', color: '#225CBD' },
	EDIT_2: { name: 'Edit2', color: '#06263E' },
	EDIT_MOBILE: { name: 'Edit', color: '#06263E' },
	SEARCH: { name: 'Search', color: '#FFFFFF' },
	X: { name: 'X', color: '#FFFFFF' },
	INFO: { name: 'Info', color: '#225CBD' },
	PENCIL: { name: 'Pencil', color: '#225CBD' },
	EYE: { name: 'Eye', color: '#06263E' },
	CHECK_SQUARE: { name: 'CheckSquare', color: '#225CBD' },
	X_SQUARE: { name: 'XSquare', color: '#EA4E2C' },
	X_CIRCLE: { name: 'XCircle', color: '#EA4E2C' },
	X_CLEAR: { name: 'X', color: '#EA4E2C' },
	UNDO_2: { name: 'Undo2', color: '#225CBD' },
	CHEVRON_DOWN: { name: 'ChevronDown', color: '#225cbd' },
	CHEVRON_UP: { name: 'ChevronUp', color: '#225cbd' },
	CLOCK: { name: 'Clock', color: '#06263E' },
	LIST_CHECK: { name: 'ListChecks', color: '#06263E' },
	ALARM_PLUS: { name: 'AlarmPlus', color: '#06263E' },
	ALARM_CHECK: { name: 'AlarmCheck', color: '#06263E' },
	PERCENT: { name: 'Percent', color: '#06263E' },
	MOON: { name: 'Moon', color: '#06263E' },
	STAR: { name: 'Star', color: '#06263E' },
	UTENSIL: { name: 'Utensils', color: '#06263E' },
	AWARD: { name: 'Award', color: '#06263E' },
	SUNMOON: { name: 'SunMoon', color: '#06263E' },
	BUS: { name: 'Bus', color: '#06263E' },
	MENU: { name: 'Menu', color: '#06263E' },
	SEND: { name: 'Send', color: '#225CBD' },
	DOWNLOAD: { name: 'Download', color: '#225CBD' },
	PICTURE: { name: 'FileImage', color: '#3C7442' },
	PHONE: { name: 'Phone', color: '#225CBD' },
	MAIL: { name: 'Mail', color: '#225CBD' },
	SPROUT: { name: 'Sprout', color: '#47C7BF' },
	ARROWLEFTRIGHT: { name: 'ArrowLeftRight', color: '#47C7BF' },
	TICKET: { name: 'Ticket', color: '#5B6164D6' },
	FILE_TEXT: { name: 'FileText', color: '#5B6164D6' },
	CAR: { name: 'Car', color: '#5B6164D6' },
	PHOTO_MOBILE: { name: 'Photo', color: '#06263E' },
	INFO: { name: 'Info', color: '#225CBD' },
	SKIP_FORWARD: { name: 'SkipForward', color: '#FFFFFF' },
	SKIP_BACK: { name: 'SkipBack', color: '#FFFFFF' },
	MINUS_CIRCLE: { name: 'MinusCircle', color: '#EA4E2C' },
	PLUS_CIRCLE: { name: 'PlusCircle', color: '#47C7BF' },
	COMPONENT: { name: 'Component', color: '#5B6164D6' },
	MESSAGE_CIRCLE: {
		name: 'MessageCircle',
		color: '#F5C567'
	},
	SKIP_BACK_MONTH: { name: 'SkipBack', color: '#47C7BF' },
	CHEVRON_FIRST_MONTH: { name: 'ChevronFirst', color: '#47C7BF' },
	SKIP_FORWARD_MONTH: { name: 'SkipForward', color: '#47C7BF' },
	CHEVRON_LAST: { name: 'ChevronLast', color: '#47C7BF' },
	CHEVRONS_RIGHT: { name: 'ChevronsRight', color: '#FFFFFF' },
	CHEVRONS_LEFT: { name: 'ChevronsLeft', color: '#FFFFFF' },
	ALERT_TRIANGLE: { name: 'AlertTriangle', color: '#EA4E2C' },
	PLUS_CIRCLE_II: { name: 'PlusCircle', color: '#FFFFFF' },
	CHECK_CIRCLE: { name: 'CheckCircle', color: '#06263E' },
	COPY: { name: 'Copy', color: '#06263E' },
	CLIPBOARD_X: { name: 'ClipboardX', color: '#06263E' },
	USERS: { name: 'Users', color: '#06263E' },
	PLUS_CIRCLE_USER: { name: 'PlusCircle', color: '#FFFFFF' },
	FILE_SIGNATURE: { name: 'FileSignature', color: '#EA4E2C' },
	UNDO: { name: 'Undo', color: '#47C7BF' },
	LIST_TODO: { name: 'List', color: '#5B6164D6' },
	VIEW: { name: 'View', color: '#FFFFFF' },
	LOCK_OPEN: { name: 'LockOpen', color: '#225CBD' },
	CIRCLE_CHECK: { name: 'CircleCheck', color: '#5cb85c' }
};
