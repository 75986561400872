import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

export const addPoItem = async ({ item, poid, projectFlagItemId = null }) => {
	if (isNil(poid)) return;
	try {
		const mutation = gql`
			mutation ($PurchaseOrderId: ID!, $AddedPoItem: PoItemInput!, $projectFlagItemId: ID) {
				AddPoItem(PurchaseOrderId: $PurchaseOrderId, AddedPoItem: $AddedPoItem, ProjectFlagItemId: $projectFlagItemId) {
					id
					poId
					name
					quantity
					price
					total
					poTotal
					priceVat
					priceTotal
					priceVatTotal
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				PurchaseOrderId: +poid,
				AddedPoItem: item,
				projectFlagItemId
			}
		});

		return data.AddPoItem;
	} catch (e) {
		console.log({ e });
	}
};

export const updatePoItem = async ({ id, item, poid, projectFlagItemId = null }) => {
	if (isNil(id) || isNil(poid)) return;
	try {
		const mutation = gql`
			mutation ($PoItemId: ID!, $UpdatedPoItem: PoItemInput!, $projectFlagItemId: ID) {
				UpdatePoItem(PoItemId: $PoItemId, UpdatedPoItem: $UpdatedPoItem, ProjectFlagItemId: $projectFlagItemId) {
					id
					poId
					name
					quantity
					category
					price
					total
					poTotal
					priceVat
					priceTotal
					priceVatTotal
				}
			}
		`;

		const { data } = await apollo.mutate({
			mutation,
			variables: {
				PoItemId: +id,
				UpdatedPoItem: item,
				projectFlagItemId
			}
		});

		return data.UpdatePoItem;
	} catch (e) {
		console.log({ e });
	}
};

export const deletePoItem = async ({ id, poid }) => {
	if (isNil(id) || isNil(poid)) return;
	try {
		const mutation = gql`
			mutation ($PoItemId: ID!) {
				DelPoItem(PoItemId: $PoItemId) {
					id
					total
					poTotal
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				PoItemId: +id
			}
		});

		return data.DelPoItem;
	} catch (e) {
		console.log({ e });
	}
};

export const getPoItems = async (poId, consumed = false) => {
	if (isNil(poId)) return;
	try {
		const query = gql`
			query ($poId: ID!, $consumed: Int) {
				GetPoItemList(PurchaseOrderId: $poId, NotConsumed: $consumed) {
					id
					poId
					encoderId
					name
					description
					quantity
					category
					price
					priceVat
					priceTotal
					priceVatTotal
					total
					currency
					poTotal
					categoryName
					categoryCostCenter
					departmentName
					user {
						id
						name
						firstName
					}
					encoder {
						id
						name
						firstName
					}
					purchaseOrder {
						departmentName
					}
					currencyName
					categoryDetail {
						id
					}
					projectFlagPoItem {
						id
						factor
					}
					categoryCostCenter
					categoryDetail {
						id
						description
						appName
					}
				}
			}
		`;

		const {
			data: { GetPoItemList }
		} = await apollo.query({
			query,
			variables: {
				poId: +poId,
				consumed: consumed ? 1 : 0
			},
			fetchPolicy: 'no-cache'
		});

		return GetPoItemList;
	} catch (e) {
		console.log({ e });
	}
};

export const getPO = async (poId) => {
	try {
		const QUERY_GET_PO = gql`
			query ($poId: ID!) {
				GetPo(PurchaseOrderId: $poId) {
					id
					createdAt
					updatedAt
					userProjectId
					poReference
					description
					department
					supplierId
					consumed
					requestDate
					deliveryDate
					deliveryAddress
					invoicedToProduction
					validated
					laValidatedId
					laValidatedWhen
					lbValidatedId
					lbValidatedWhen
					amount
					amountVat
					amountTotal
					information
					comment
					departmentName
					supplierName
					itemCategory
					notDetailed
					address {
						id
						createdAt
						updatedAt
						projectId
						street
						number
						box
						zip
						state
						city
						country
						countryCode
						countryKey
						other
						lat
						lng
					}
					user {
						id
					}
					encoderId
					encoder {
						id
						name
						fullName
						firstName
					}
					function
					functionName
					expenseTotal
					images
				}
			}
		`;

		const {
			data: { GetPo }
		} = await apollo.query({
			query: QUERY_GET_PO,
			variables: {
				poId: +poId
			},
			fetchPolicy: 'no-cache'
		});

		return GetPo;
	} catch (e) {
		console.error(e.graphQLErrors[0].message);
	}
};

export const validatePurchaseOrder = async (purchaseOrderId, comment) => {
	const mutation = gql`
		mutation ($purchaseOrderId: ID!, $comment: String) {
			ValidatePurchaseOrder(PurchaseOrderId: $purchaseOrderId, Comment: $comment) {
				id
				amount
				comment
				validated
			}
		}
	`;

	const {
		data: { ValidatePurchaseOrder }
	} = await apollo.mutate({
		mutation,
		variables: {
			purchaseOrderId: +purchaseOrderId,
			comment
		}
	});

	return ValidatePurchaseOrder;
};

export const rejectPurchaseOrder = async (purchaseOrderId, comment) => {
	const mutation = gql`
		mutation ($purchaseOrderId: ID!, $comment: String) {
			RejectPurchaseOrder(PurchaseOrderId: $purchaseOrderId, Comment: $comment) {
				id
				amount
				comment
				validated
			}
		}
	`;

	const {
		data: { RejectPurchaseOrder }
	} = await apollo.mutate({
		mutation,
		variables: {
			purchaseOrderId: +purchaseOrderId,
			comment
		}
	});

	return RejectPurchaseOrder;
};

export const getPurchaseOrdersToValidate = async () => {
	const query = gql`
		query {
			GetPurchaseOrdersToValidate {
				id
				description
				requestDate
				amount
				comment
				validated
				departmentName
				functionName
				supplierName
				poReference
				amountTotal
				isInContract
				user {
					id
					name
					firstName
					email
					departmentName
				}
				encoderId
				encoder {
					id
					name
					fullName
					firstName
				}
				function
				functionName
			}
		}
	`;

	const {
		data: { GetPurchaseOrdersToValidate }
	} = await apollo.query({
		query,
		fetchPolicy: 'no-cache'
	});

	return GetPurchaseOrdersToValidate;
};

export const computePoItemPrice = async (priceDetail = {}) => {
	try {
		const mutation = gql`
			mutation ($priceDetail: PoItemPriceDetailInput!) {
				ComputePoItemPrice(PriceDetail: $priceDetail) {
					id
					createdAt
					updatedAt
					unitPriceVatExcluded
					unitPriceVatIncluded
					unitVat
					quantity
					category
					department
					priceTotal
				}
			}
		`;

		const {
			data: { ComputePoItemPrice }
		} = await apollo.mutate({
			mutation,
			variables: {
				priceDetail
			}
		});

		return ComputePoItemPrice;
	} catch (e) {
		console.log(e);
	}
};
