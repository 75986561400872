import Vue from 'vue';
import VueApollo from 'vue-apollo';
// import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'

import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

// import { createApolloFetch } from 'apollo-fetch'
// https://github.com/apollographql/apollo-fetch

import { store } from './store';
import { version } from './../package.json';

// Install the vue plugin
Vue.use(VueApollo);

export const BASE_URL = process.env.BASE_URL;
const uri = `${process.env.VUE_APP_GQL}/graphql`;

// export const apolloFetch = createApolloFetch({ uri });
const httpLink = createHttpLink({ uri });
const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const { token } = store.state || {};
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
			'X-Client-Version': version
		}
	};
});

// Create apollo client
export const apolloClient = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache()
});

/**
 * Call this in the Vue app file
 *
 * @param {Object} [options={}]
 * @return {Function}
 */
export function createProvider(options = {}) {
	// Accès au client : this.$apollo.provider.defaultClient

	// Create vue apollo provider
	const apolloProvider = new VueApollo({
		defaultClient: apolloClient,
		// defaultOptions: {
		//   $query: {
		//     // fetchPolicy: 'cache-and-network',
		//   },
		// },
		errorHandler(error) {
			console.log({ error });
			// eslint-disable-next-line no-console
			console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message);
		}
	});

	return apolloProvider;
}
