import gql from 'graphql-tag';
import { store } from '@/store';
import { apolloClient as apollo } from '@/vue-apollo';

const state = () => ({
	totalNotification: 0,
	totalDocToRead: 0,
	totalAccToRead: 0,
	totalTsToValidate: 0,
	totalPoToValidate: 0,
	totalExpToValidate: 0,
	totalTsRefused: 0,
	totalPoRefused: 0,
	totalExpRefused: 0,
	totalExpItemRefused: 0,
	totalTsToDelete: 0,
	count: 1
});

const getters = {
	getTotalNotification: (state) => state.totalNotification,
	getTotalDocToRead: (state) => state.totalDocToRead,
	getTotalAccToRead: (state) => state.totalAccToRead,
	getTotalTsToValidate: (state) => state.totalTsToValidate,
	getTotalPoToValidate: (state) => state.totalPoToValidate,
	getTotalExpToValidate: (state) => state.totalExpToValidate,
	getTotalTsRefused: (state) => state.totalTsRefused,
	getTotalPoRefused: (state) => state.totalPoRefused,
	getTotalExpRefused: (state) => state.totalExpRefused,
	getTotalExpItemRefused: (state) => state.totalExpItemRefused,
	getCount: (state) => state.count,
	getTsToDelete: (state) => state.totalTsToDelete
};

const mutations = {
	SET_TOTAL_NOTIFICATION(state, total) {
		state.totalNotification = total;
	},
	SET_TOTAL_DOC_TO_READ(state, total) {
		state.totalDocToRead = total;
	},
	SET_TOTAL_ACC_TO_READ(state, total) {
		state.totalAccToRead = total;
	},
	SET_TOTAL_TS_TO_VALIDATE(state, total) {
		state.totalTsToValidate = total;
	},
	SET_TOTAL_PO_TO_VALIDATE(state, total) {
		state.totalPoToValidate = total;
	},
	SET_TOTAL_EXP_TO_VALIDATE(state, total) {
		state.totalExpToValidate = total;
	},
	SET_TOTAL_TS_REFUSED(state, total) {
		state.totalTsRefused = total;
	},
	SET_TOTAL_EXP_REFUSED(state, total) {
		state.totalExpRefused = total;
	},
	SET_TOTAL_PO_REFUSED(state, total) {
		state.totalPoRefused = total;
	},
	SET_TOTAL_EXP_ITEM_REFUSED(state, total) {
		state.totalExpItemRefused = total;
	},
	SET_TOTAL_TS_TO_DELETE(state, total) {
		state.totalTsToDelete = total;
	},
	SET_COUNT(state) {
		state.count++;
	}
};

const actions = {
	async getNotifications(
		{ commit },
		projectId = +store.projectID() || 0,
		numDocToRead = false,
		numAccToRead = false,
		numTsToValidate = true,
		numExpToValidate = true,
		numPoToValidate = true,
		numTsRefused = true,
		numExpRefused = true,
		numPoRefused = true,
		numExpItemRefused = true,
		numTsToDelete = true
	) {
		const {
			data: { GetHeaderData }
		} = await apollo.query({
			query: gql`
				query (
					$projectId: ID
					$numDocToRead: Boolean
					$numAccToRead: Boolean
					$numTsToValidate: Boolean
					$numExpToValidate: Boolean
					$numPoToValidate: Boolean
					$numTsRefused: Boolean
					$numExpRefused: Boolean
					$numPoRefused: Boolean
					$numExpItemRefused: Boolean
					$numTsToDelete: Boolean
				) {
					GetHeaderData(
						ProjectId: $projectId
						NumDocToRead: $numDocToRead
						NumAccToRead: $numAccToRead
						NumTsToValidate: $numTsToValidate
						NumExpToValidate: $numExpToValidate
						NumPoToValidate: $numPoToValidate
						NumTsRefused: $numTsRefused
						NumExpRefused: $numExpRefused
						NumPoRefused: $numPoRefused
						NumExpItemRefused: $numExpItemRefused
						NumTsToDelete: $numTsToDelete
					) {
						numDocToRead
						numAccToRead
						numTsToValidate
						numPoToValidate
						numExpToValidate
						numTsRefused
						numExpRefused
						numPoRefused
						numExpItemRefused
						numTsToDelete
					}
				}
			`,
			variables: {
				projectId: parseInt(projectId, 10),
				numDocToRead,
				numAccToRead,
				numTsToValidate,
				numExpToValidate,
				numPoToValidate,
				numTsRefused,
				numExpRefused,
				numPoRefused,
				numExpItemRefused,
				numTsToDelete
			},
			fetchPolicy: 'no-cache'
		});

		commit(
			'SET_TOTAL_NOTIFICATION',
			GetHeaderData.numDocToRead +
				GetHeaderData.numAccToRead +
				GetHeaderData.numTsToValidate +
				GetHeaderData.numPoToValidate +
				GetHeaderData.numExpToValidate +
				GetHeaderData.numTsRefused +
				GetHeaderData.numExpRefused +
				GetHeaderData.numPoRefused +
				GetHeaderData.numExpItemRefused +
				GetHeaderData.numTsToDelete
		);
		commit('SET_TOTAL_DOC_TO_READ', GetHeaderData.numDocToRead);
		commit('SET_TOTAL_ACC_TO_READ', GetHeaderData.numAccToRead);
		commit('SET_TOTAL_TS_TO_VALIDATE', GetHeaderData.numTsToValidate);
		commit('SET_TOTAL_PO_TO_VALIDATE', GetHeaderData.numPoToValidate);
		commit('SET_TOTAL_EXP_TO_VALIDATE', GetHeaderData.numExpToValidate);
		commit('SET_TOTAL_TS_REFUSED', GetHeaderData.numTsRefused);
		commit('SET_TOTAL_EXP_REFUSED', GetHeaderData.numExpRefused);
		commit('SET_TOTAL_PO_REFUSED', GetHeaderData.numPoRefused);
		commit('SET_TOTAL_EXP_ITEM_REFUSED', GetHeaderData.numExpItemRefused);
		commit('SET_TOTAL_TS_TO_DELETE', GetHeaderData.numTsToDelete);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
