import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '@/shared/utils';
import { PROJECT_CARBON_FIELD } from '@/shared/constants';

export const sendSelectedExpensesForValidation = async (expenseTitle, expenseData) => {
	try {
		const mutation = gql`
			mutation ($expenseTitle: String, $expenseData: [SendExpenseItemInput]!) {
				SendExpenseSlice(ExpenseTitle: $expenseTitle, ExpenseData: $expenseData)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				expenseTitle,
				expenseData
			}
		});

		return data.SendExpenseSlice;
	} catch (e) {
		console.log({ e });
	}
};

export const sendAllExpensesForValidation = async (ExpenseTitle) => {
	try {
		const mutation = gql`
			mutation ($ExpenseTitle: String) {
				SendAllExpenses(ExpenseTitle: $ExpenseTitle)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				ExpenseTitle: ExpenseTitle
			}
		});

		return data.SendAllExpenses;
	} catch (e) {
		console.log({ e });
	}
};

export const getExpenseItems = async (expenseId = -1) => {
	const query = gql`
		query ($expenseId: ID!) {
			GetExpenseItemList(ExpenseId: $expenseId) {
				${columnExpenseItem}
			}
		}
	`;

	const {
		data: { GetExpenseItemList }
	} = await apollo.query({
		query,
		variables: {
			expenseId
		},
		fetchPolicy: 'no-cache'
	});

	return GetExpenseItemList;
};

export const validateExpense = async (expenseId, comment) => {
	const mutation = gql`
		mutation ($expenseId: ID!, $comment: String) {
			ValidateExpense(ExpenseId: $expenseId, Comment: $comment) {
				id
				amountTotal
				comment
				validated
			}
		}
	`;

	const {
		data: { ValidateExpense }
	} = await apollo.mutate({
		mutation,
		variables: {
			expenseId,
			comment
		}
	});

	return ValidateExpense;
};

export const validateExpenseItem = async (expenseItemId) => {
	const mutation = gql`
		mutation ($expenseItemId: ID!) {
			ValidateExpenseItem(ExpenseItemId: $expenseItemId) {
				id
				allItemsTotal
			}
		}
	`;

	const {
		data: { ValidateExpenseItem }
	} = await apollo.mutate({
		mutation,
		variables: {
			expenseItemId
		}
	});

	return ValidateExpenseItem;
};

export const undoRejectExpenseItem = async (expenseItemId) => {
	const mutation = gql`
		mutation ($expenseItemId: ID!) {
			UndoRejectExpenseItem(ExpenseItemId: $expenseItemId) {
				id
				allItemsTotal
			}
		}
	`;

	const {
		data: { UndoRejectExpenseItem }
	} = await apollo.mutate({
		mutation,
		variables: {
			expenseItemId
		}
	});

	return UndoRejectExpenseItem;
};

export const rejectExpense = async (expenseId, comment) => {
	const mutation = gql`
		mutation ($expenseId: ID!, $comment: String) {
			RejectExpense(ExpenseId: $expenseId, Comment: $comment) {
				id
				amountTotal
				comment
				validated
			}
		}
	`;

	const {
		data: { RejectExpense }
	} = await apollo.mutate({
		mutation,
		variables: {
			expenseId,
			comment
		}
	});

	return RejectExpense;
};

export const rejectExpenseItem = async (expenseItemId, comment) => {
	const mutation = gql`
		mutation ($expenseItemId: ID!, $comment: String) {
			RejectExpenseItem(ExpenseItemId: $expenseItemId, Comment: $comment) {
				id
				allItemsTotal
			}
		}
	`;

	const {
		data: { RejectExpenseItem }
	} = await apollo.mutate({
		mutation,
		variables: {
			expenseItemId,
			comment
		}
	});

	return RejectExpenseItem;
};

export const getExpenseToValidate = async () => {
	const query = gql`
		query {
			GetExpenseToValidate {
				id
				type
				description
				date
				amountTotal
				comment
				validated
				kgCoTwo
				departmentName
				functionName
				allExpenseItemIsInContract
				user {
					name
					firstName
					email
				}
				encoderId
				encoder {
					id
					name
					fullName
					firstName
				}
				function
				functionName
			}
		}
	`;

	const {
		data: { GetExpenseToValidate }
	} = await apollo.query({
		query,
		fetchPolicy: 'no-cache'
	});

	return GetExpenseToValidate;
};

export const getExpenseItem = async (expenseItemId = 0) => {
	try {
		const query = gql`
      query ($expenseItemId: ID!) {
        GetExpenseItem(ExpenseItemId: $expenseItemId) {
          ${columnExpenseItem}
        }
      }
    `;

		const {
			data: { GetExpenseItem }
		} = await apollo.query({
			query,
			variables: {
				expenseItemId: +expenseItemId
			},
			fetchPolicy: 'no-cache'
		});

		return GetExpenseItem;
	} catch (e) {
		console.log(e);
	}
};

/**
 * Sends a GraphQL mutation to get expense items from an image or PDF document.
 *
 * @param {Array<String>|String} payload - Document identifier(s), without file extension. If multiple, provide an array.
 * @param {Object} [options={}]
 *
 * @returns {Promise<Array<{amountTotal: number, amountVat: number, description: string, date: string}>|null>}
 * Returns a promise that resolves to an array of expense items, or null if an error occurs.
 * @returns {Promise<Array<{amountTotal: number, amountVat: number, description: string, date: string}>|null>}
 * Returns a promise that resolves to an array of expense items, or null if an error occurs.
 * Each expense item is an object with properties field1, field2, and field3.
 *
 * @throws {ApolloError} If the GraphQL mutation fails, this function will log the error and rethrow it.
 *
 * @async
 */
export const expenseItemsFromImageOrPdf = async (payload, options = {}) => {
	/**
	 * @deftype {?Boolean} [OptionsState=false]
	 *
	 * @type {OptionsState} multipleExpenseItem
	 * @type {OptionsState} splitTVA
	 */
	const multipleExpenseItem = options.multipleExpenseItem || false;
	const splitTVA = options.splitTVA || false;
	const userId = options.userId || null;

	// try {
	const mutation = gql`
		mutation ($DocXid: [String!]!, $MultipleExpenseItem: Boolean!, $SplitTVA: Boolean) {
			ExpenseItemsFromImageOrPdf(DocXid: $DocXid, MultipleExpenseItem: $MultipleExpenseItem, SplitTVA: $SplitTVA) {
				id
				amountTotal
				amountVat
				description
				supplierId
				supplierName
				department
				category
				paiementType
				paiementTypeName
				date
			}
		}
	`;
	const { data } = await apollo.mutate({
		mutation,
		variables: {
			DocXid: payload,
			MultipleExpenseItem: multipleExpenseItem,
			SplitTVA: splitTVA
			// UserId: userId,
		}
	});

	return data.ExpenseItemsFromImageOrPdf;
	// } catch (e) {
	// 	console.error({ e });
	// }
};

/**
 * @param {Object} payload
 */
export const generateExpenseRef = async (payload) => {
	try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation ($AddedExpenseItem: ExpenseItemInput!) {
					NewExpenseItem(AddedExpenseItem: $AddedExpenseItem) {
						id
					}
				}
			`,
			variables: { AddedExpenseItem: payload }
		});
		return data.NewExpenseItem;
	} catch (e) {
		console.error({ e });
	}
};

/**
  * @param {!String|Number} expenseId
  */
export const deleteExpense = async (expenseId) => {
  if (isNil(expenseId)) return;

  try {
    const { data } = await apollo.mutate({
      mutation: gql`
        mutation ($ExpenseItemId: ID!) {
          DelExpenseItem(ExpenseItemId: $ExpenseItemId) {
            id
          }
        }
      `,
      variables: { ExpenseItemId: parseInt(expenseId) }
    })

    return data;
  } catch (e) {
    console.error({ e });
  }
};

const columnExpenseItem = `
	createdAt
	updatedAt
	category
	paiementType
	supplierName
	supplierId
	type
	department
	departmentName
	description
	subCategory
	date
	id
	images
	amount
	amountTotal
	amountVat
	comment
	km
	kgCoTwo
	fromLocation
	coTwoCategory
	toLocation
	validated
	categoryName
	paiementTypeName
	isInContract
	subType
	additionalSubType
	user {
		id
		name
		firstName
	}
	encoder {
		id
		name
		firstName
	}
	projectFlagItems {
		id
		taxeFlags {
			id
			flagId
			value
			name
			short
			factor
			amount
			payCode
			color
			allowanceRate
			bookmark
			category
			digitalSubmission
			disabled
			costCenter
			forScreen
			fieldType
			defaultValue
			jsFormula
		}
		customFlags {
			id
			flagId
			value
			name
			short
			factor
			amount
			payCode
			color
			allowanceRate
			bookmark
			category
			digitalSubmission
			disabled
			costCenter
			forScreen
			fieldType
			defaultValue
			jsFormula
		}
	}
	supplier {
	  name
	}
	carbon {
	  ${PROJECT_CARBON_FIELD}
	}
`;
