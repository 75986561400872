import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export const getProjectFlagItems = async (Types, SortByBookMark) => {
	try {
		const response = await apollo.query({
			query: gql`
				query GetProjectFlagItems($Types: [ID!], $SortByBookMark: Boolean) {
					GetProjectFlagItems(Types: $Types, SortByBookMark: $SortByBookMark) {
						id
						favoriteProjectFlagItems {
							id
							name
							value
							short
							factor
							flagId
							amount
							payCode
							forScreen
							defaultValue
							jsFormula
							color
							bookmark
							category
							digitalSubmission
							disabled
							allowanceRate
							costCenter
							groupingCategory
						}
						projectFlagItems {
							id
							name
							value
							short
							factor
							flagId
							amount
							payCode
							forScreen
							defaultValue
							jsFormula
							color
							bookmark
							category
							digitalSubmission
							disabled
							allowanceRate
							costCenter
							groupingCategory
						}
					}
				}
			`,
			variables: {
				Types: Types,
				SortByBookMark: SortByBookMark
			},
			fetchPolicy: 'no-cache'
		});

		const { GetProjectFlagItems } = response.data;
		return GetProjectFlagItems;
	} catch (e) {
		console.log(e.message);
	}
};

export const getProjectLocationList = async (Date) => {
	const QUERY_GET_PROJECT_LOCATION_LIST = gql`
		query ($Date: String) {
			GetProjectLocationList(Date: $Date) {
				id
				date
				setName
				isHidden
				canNotBeHidden
			}
		}
	`;

	const {
		data: { GetProjectLocationList }
	} = await apollo.query({
		query: QUERY_GET_PROJECT_LOCATION_LIST,
		variables: {
			Date
		},
		fetchPolicy: 'no-cache'
	});

	return GetProjectLocationList;
};

export const getWorkDayProjectList = async (item) => {
	const { offset, limit, keyword } = item;
	try {
		const {
			data: { GetWorkDayProjectList }
		} = await apollo.query({
			query: gql`
				query ($KeyWord: String, $Limit: ID, $Offset: ID) {
					GetWorkDayProjectList(KeyWord: $KeyWord, Limit: $Limit, Offset: $Offset) {
						id
						createdAt
						updatedAt
						name
						projectName
						production
						hierarchyWid
						projectHierarchyName
						exist
					}
				}
			`,
			variables: {
				KeyWord: keyword,
				Limit: limit,
				Offset: offset
			},
			fetchPolicy: 'no-cache'
		});
		return GetWorkDayProjectList;
	} catch (e) {
		throw new Error('Error request');
	}
};

export const getTotalUsersCount = async (ProjectId, ForTsEncoding, OnlyVisibleUser) => {
	try {
		const {
			data: { GetTotalUsersCount }
		} = await apollo.query({
			query: gql`
				query ($ProjectId: ID, $ForTsEncoding: Boolean, $OnlyVisibleUser: Boolean) {
					GetTotalUsersCount(ProjectId: $ProjectId, ForTsEncoding: $ForTsEncoding, OnlyVisibleUser: $OnlyVisibleUser)
				}
			`,
			variables: {
				ProjectId: ProjectId,
				ForTsEncoding: ForTsEncoding,
				OnlyVisibleUser: OnlyVisibleUser
			},
			fetchPolicy: 'no-cache'
		});
		return GetTotalUsersCount;
	} catch (e) {
		throw new Error('Error request');
	}
};

export const refreshWorkDaysProjectsList = async () => {
	try {
		const {
			data: { RefreshWorkDaysProjectsList }
		} = await apollo.mutate({
			mutation: gql`
				mutation {
					RefreshWorkDaysProjectsList
				}
			`,
			fetchPolicy: 'no-cache'
		});
		return RefreshWorkDaysProjectsList;
	} catch (e) {
		throw new Error('Error request');
	}
};

export const hasUnsubmittedTsdayAndNoManager = async (UserIds) => {
	try {
		const {
			data: { HasUnsubmittedTsdayAndNoManager }
		} = await apollo.query({
			query: gql`
				query ($UserIds: [ID!]!) {
					HasUnsubmittedTsdayAndNoManager(UserIds: $UserIds)
				}
			`,
			variables: {
				UserIds
			},
			fetchPolicy: 'no-cache'
		});
		return HasUnsubmittedTsdayAndNoManager;
	} catch (error) {
		throw new Error('Error request');
	}
};

export const createOrUpdateProjectFromWorkDays = async (HierarchyWid) => {
	try {
		const {
			data: { CreateOrUpdateProjectFromWorkDays }
		} = await apollo.mutate({
			mutation: gql`
				mutation ($HierarchyWid: String!) {
					CreateOrUpdateProjectFromWorkDays(HierarchyWid: $HierarchyWid)
				}
			`,
			variables: {
				HierarchyWid
			},
			fetchPolicy: 'no-cache'
		});
		return CreateOrUpdateProjectFromWorkDays;
	} catch (e) {
		throw new Error('Error request');
	}
};

export const addOrDeletePlanningInactiveDate = async (dates, isHide) => {
	try {
		const {
			data: { AddOrDeletePlanningInactiveDate }
		} = await apollo.mutate({
			mutation: gql`
				mutation ($Date: [String!]!, $Hide: Boolean!) {
					AddOrDeletePlanningInactiveDate(Date: $Date, Hide: $Hide)
				}
			`,
			variables: {
				Date: dates,
				Hide: isHide
			},
			fetchPolicy: 'no-cache'
		});
		return AddOrDeletePlanningInactiveDate;
	} catch (e) {
		throw new Error('Error request');
	}
};

export const updateCompletionStatus = async (TsdayIds, Complete) => {
	try {
		const { data: UpdateCompletionStatus } = await apollo.mutate({
			mutation: gql`
				mutation ($TsdayIds: [ID!]!, $Complete: Boolean!) {
					UpdateCompletionStatus(TsdayIds: $TsdayIds, Complete: $Complete)
				}
			`,
			variables: {
				TsdayIds,
				Complete
			},
			fetchPolicy: 'no-cache'
		});
		return UpdateCompletionStatus;
	} catch (_) {
		throw new Error('Error request');
	}
};
