import { isNil } from '~utils';
import { store } from '@/store';
import moment from 'moment';

/**
 * @param {Number or String} value
 * @return {String}
 */

export const rendKgCo2 = (value) => {
	if (isNil(value)) return;
	if (value == '()') {
		// returns currency between (€)
		var str = `0`;
		return str;
	} else {
		if (value > 1000) {
			var tmp = value / 1000;
			var tmp = tmp.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
			return `${tmp} T`;
		} else {
			var tmp = value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
			return `${tmp} Kg`;
		}
	}
};

export const rendWaterQty = (value) => {
	if (isNil(value)) return;
	if (value == '()') {
		// returns currency between (€)
		var str = `0`;
		return str;
	} else {
		if (value > 1000) {
			var tmp = value / 1000;
			var tmp = tmp.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
			return `${tmp} m3`;
		} else {
			var tmp = value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
			return `${tmp} l`;
		}
	}
};

export const rendKwh = (value) => {
	if (isNil(value)) return;
	if (value == '()') {
		var str = `0`;
		return str;
	} else {
		var tmp = value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
		return `${tmp} Kwh`;
	}
};

/**
 * @param {String} filename
 * @return {String}
 */
export const getFileExtension = (filename, defaultExt = 'png') => {
	return filename.substring(filename.lastIndexOf('.') + 1);
};

/**
 * @param {String} iso
 * @return {Number}
 */
export const convertIsoToKey = (iso) => {
	const first = iso[0].charCodeAt(0) * 256;
	const sec = iso[1].charCodeAt(0);
	const res = first + sec;
	return res;
};

export const rendCurrency = (value, fixedValue = 2) => {
	if (isNil(value)) return;
	if (isNil(store) || isNil(store.state) || isNil(store.state.myProfile) || isNil(store.state.myProfile.currencySymbol)) {
		var curSymb = '€';
	} else {
		var curSymb = store.state.myProfile.currencySymbol;
	}
	if (value == '()') {
		// returns currency between (€)
		//    var str = `(${store.state.myProfile.currencySymbol})`
		var str = `(${curSymb})`;
		return str;
	} else {
		if (value > 10000 || value < -10000) {
			// display in K€ with 2 digits
			var tmp = value / 1000;
			tmp = tmp.toFixed(fixedValue).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
			tmp = fixedValue === 2 ? tmp.replace(/(\.0{2})$/g, '') : tmp;
			//  return `${tmp} ${store.state.myProfile.currencySymbol}`;
			let result = `${tmp} K${curSymb}`;
			if (store.appLanguage() === 0) {
				return result;
			} else {
				return rendCurrencyToEuropFormat(result);
			}
		} else {
			if (isNil(value)) return `0 ${curSymb}`;
			var tmp = value.toFixed(fixedValue).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
			tmp = fixedValue === 2 ? tmp.replace(/(\.0{2})$/g, '') : tmp.replace(/(\.0{3})$/g, '');
			//  return `${tmp} ${store.state.myProfile.currencySymbol}`;
			let result = `${tmp} ${curSymb}`;
			if (store.appLanguage() === 0) {
				return result;
			} else {
				return rendCurrencyToEuropFormat(result);
			}
		}
	}
};

const rendCurrencyToEuropFormat = (value) => {
	let mapObj = {
		'.': ',',
		',': '.'
	};
	let result = value.replace(/\.|,/gi, function (matched) {
		return mapObj[matched];
	});

	return result;
};

/**
 * @return {Boolean}
 */
export const isPwa = () => {
	const types = ['fullscreen', 'standalone', 'minimal-ui'];
	return types.some((dm) => window.matchMedia(`(display-mode: ${dm})`).matches);
};

/**
 * @param {String} date
 * @return {String}
 */
export const formatTableDate = (date, format = 'DD/MM/YYYY') => {
	if (isNil(date)) return;
	// const _d = date.split('T')[0]
	// return `${_d.substring(8)}/${_d.substring(5, 7)}`
	return moment(date).utc().format(format);
};

/**
 * @param {Object} date
 * @return {String}
 */
export const fetchDistance = async ({ from, to, arrivalTime, departureTime, travelMode, transitOptions = null, avoidHighways, avoidTolls, unit }) => {
	return new Promise((resolve, reject) => {
		let response;
		const service = new google.maps.DistanceMatrixService();

		// const unit = unitSystem;
		const unitSystem = unit === 'imperial' ? google.maps.UnitSystem.IMPERIAL : google.maps.UnitSystem.METRIC;

		const directionsTravelMode = (key) => {
			const _c = ['WALKING', 'DRIVING', 'TRANSIT'].includes(key);
			const _k = _c ? key : 'DRIVING';
			return google.maps.DirectionsTravelMode[_k];
		};

		const removeStuff = (addr) => {
			if (isNil(addr)) return '...';
			let res = '';
			if (addr.lat === 0 && addr.lng === 0) {
				res = [addr.number, addr.street, addr.number, addr.city, addr.other, addr.state].filter((n) => n).join(', ');
			} else {
				res = { lat: addr.lat, lng: addr.lng };
			}

			return res;
		};

		const drivingOptions = {
			departureTime: isNil(departureTime) ? new Date() : new Date(departureTime),
			trafficModel: 'optimistic'
		};

		const payload = {
			unitSystem,
			avoidHighways: avoidHighways || false,
			avoidTolls: avoidTolls || false,
			origins: [removeStuff(from)],
			destinations: [removeStuff(to)],
			travelMode,
			drivingOptions
		};

		if (!isNil(transitOptions)) {
			payload.transitOptions = {
				departureTime: drivingOptions.departureTime,
				modes: ['BUS'],
				routingPreference: 'FEWER_TRANSFERS'
			};
		}

		service.getDistanceMatrix(payload, function (resp, status) {
			if (status !== google.maps.DistanceMatrixStatus.OK) {
				response = reject(status);
			} else {
				response = resolve(resp);
			}
		});
		return response;
	});
};

export const windowHashChange = async () => {
	/**
	 * source
	 * https://stackoverflow.com/questions/25806608/how-to-detect-browser-back-button-event-cross-browser
	 */

	window.onhashchange = function () {
		if (window.innerDocClick) {
			//Your own in-page mechanism triggered the hash change
		} else {
			// Browser back button was clicked
			// if (window.location.hash != '#undefined') {
			//     goBack();
			// } else {
			//     history.pushState("", document.title, window.location.pathname);
			//     location.reload();
			// }
		}
	};
};

/**
 * @param {Object} doc
 * @param {Object} options - { imageOnly, requestId }
 * @return {String}
 *
 * @doc
 * 	options.imageOnly -> return images/ link
 * 	options.requestId -> change requesterUserId if not null
 * 	options.userId    -> userId if null use store.userID()
 */
export const getFileSrc = (
	doc,
	options = {
		imageOnly: false,
		requestId: null,
		useId: null
	}
) => {
	if (isNil(doc)) return;

	const base = process.env.VUE_APP_GQL;
	const userId = isNil(options.userId) ? store.userID() : options.userId;
	const requesterUserId = isNil(options.requestId) ? userId : options.requestId;
	const contractId = isNil(options.contractId) ? 0 : options.contractId;

	const defaultSrc = `${base}/images/${doc.xid}?requesterUserId=${requesterUserId}&userId=${userId}`;

	if (options.imageOnly) return defaultSrc;

	return doc.type >= 0 ? `${base}/documentForUser/${requesterUserId}/${userId}/${contractId}/${doc.xid}` : defaultSrc; // `${base}/images/${doc.xid}?requestId=${id}&userId=${id}`
};

/**
 * @param {String} ext
 * @return {Boolean}
 */
export const isFileExtImage = (ext) => {
	if (isNil(ext)) return false;
	const regex = /(jpeg|bmp|jpg|gif|png)$/i;
	return regex.test(ext);
};

/**
 * @param {Boolean} bool
 * @param {Array} elements
 * @return {Array}
 */
export const arrInsertIf = (bool = true, ...elements) => (bool ? elements : []);
/**
 * the object you want to merge if bool is true
 *
 * @param {Boolean} bool
 * @param {Object} obj
 * @return {Object}
 */
export const mergeIfObj = (bool = false, obj = {}) => (!bool ? {} : obj);

/**
 * gonna return the frileName from an url
 *
 * @param {!String} url
 * @return {String}
 */
export const getFileNameFromURL = (url) => {
	if (isNil(url)) {
		return console.error("Can't use 'getFileNameFromURL' on 'nul' or 'undefined'");
	}
	/** @type {Url} _u */
	const _u = new URL(url);
	return _u.pathname.slice(1);
};

/**
 * @param {Blob} blob
 * @return {File}
 */
export const getUrlFromBlob = (blob) => {
	/**
	 * @param {Callback} resolve
	 * @param {Callback} reject
	 * @param {Promise}
	 */
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.onerror = reject;
		reader.readAsDataURL(blob);
	});
};

export const getFileName = (filename) => {
	return filename.substring(0, filename.lastIndexOf('.'));
};

/**
 * @param {!String} link
 * @return {String}
 */
export const cleanLinkQuery = (link) => {
	if (isNil(link)) {
		console.error("Can't use 'cleanLinkQuery' with 'nul' or 'undefined'");
		return link;
	}
	/** @type {URL} url */
	const url = new URL(link);
	url.search = '';
	return url.toString();
};
