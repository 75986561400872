import * as _ from 'lodash';
import { REG_SEARCH_FORMAT, INTERNAL_STATUS } from '@/shared/constants';
import { noTilde } from '~utils';

const useWorktype = {
	hourly: 0,
	daily: 1,
	weekly: 2,
	monthly: 3,
	yearly: 4
};
const useValueType = {
	hourly: 24,
	daily: 24,
	weekly: 100,
	monthly: 13,
	yearly: 2
};
const useRangeHour = {};
for (let key in useWorktype) {
	useRangeHour[useWorktype[key]] = useValueType[key];
}
const useRangeMinute = {};
for (let key in useWorktype) {
	useRangeMinute[useWorktype[key]] = 60;
}
const ACCESS_LEVEL = {
	isAdmin: 'isAdmin',
	isProd: 'isProd',
	isVip: 'isVip',
	canAssign: 'canAssign',
	canAccessUsers: 'canAccessUsers',
	canAccessBudget: 'canAccessBudget',
	canAccessMyBudget: 'canAccessMyBudget',
	canAccessGreenReporting: 'canAccessGreenReporting',
	canAccessConfig: 'canAccessConfig',
	canAccessOnboarding: 'canAccessOnboarding',
	canAccessCrewList: 'canAccessCrewList',
	canValidateI9: 'canValidateI9',
	gateKeeper: 'gateKeeper',
	productionAccountant: 'productionAccountant',
	upm: 'upm',
	lineProducer: 'lineProducer',
	studio: 'studio',
	canEditExpense: 'canEditExpense',
	canLockBudget: 'canLockBudget',
	canModifyEstimatedFinalCost: 'canModifyEstimatedFinalCost',
	canEditAndAddCode: 'canEditAndAddCode',
	canEditPo: 'canEditPo',
	screenAccess: 'screenAccess',
	isRole: 'isRole',
	delValidationRole: 'delValidationRole',
	addUpdValidationRole: 'addUpdValidationRole'
};
const DEPARTEMENT_VALIDATION = {
	isAdd: 'isAdd',
	isUpdate: 'isUpdate'
};
const ENTRY_FOR_CREW = {
	ADD_EFC: 'ADD_EFC',
	UPDATE_EFC: 'UPDATE_EFC'
};
const FILTER_STRING_TIME_SHEET = {
	groupByPerson: 'groupByPerson',
	groupByDate: 'groupByDate',
	groupByDateOnly: 'groupByDateOnly',
	groupByStatus: 'groupByStatus',
	planningView: 'planningView'
};
const ACTION_EFC_TIMESHEET = {
	addData: 'addData',
	updateData: 'updateData',
	deleteData: 'deleteData'
};
const NAME_CARDS_TIMESHEET_EFC = {
	timecards_One: 'timecards',
	timesheets_Two: 'timesheets'
};

const setSubType = (payload) => {
	if (payload.id === 1) {
		return 'receipt';
	}
	if (payload.id === 2) {
		return 'invoice';
	}
};
const generateID = () => {
	return Math.random() * 1000000000000000;
};

const getStatus = (status, item, isAleradySendToPaie = false) => {
	const { DEFAULT_INTERNAL, SENT_TO_PAY } = INTERNAL_STATUS;
	if (isAleradySendToPaie) {
		const icon = (status) => {
			switch (status) {
				case 'status-not-submitted':
					return `<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M21.9208 13.265C21.9731 12.8507 22 12.4285 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C12.4354 22 12.8643 21.9722 13.285 21.9182M12 6V12L15.7384 13.8692M19 22V16M16 19H22" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>`;
				case 'status-info':
					return `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#225CBD"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M18.5,11c0.17,0,0.34,0.01,0.5,0.03V6.87C19,5.84,18.16,5,17.13,5H3.87C2.84,5,2,5.84,2,6.87v10.26 C2,18.16,2.84,19,3.87,19h9.73C13.22,18.25,13,17.4,13,16.5C13,13.46,15.46,11,18.5,11z M10.4,13L4,9.19V7h0.23l6.18,3.68L16.74,7 H17v2.16L10.4,13z"/><polygon points="19,13 17.59,14.41 19.17,16 15,16 15,18 19.17,18 17.59,19.59 19,21 23,17"/></g></g></svg>`;
				case 'status-pending':
					return `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#edbe13">
								<path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
								<path d="M21 12v-2h-9l1.34-5.34L9 9v10h9z" opacity="1"/>
								<path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z"/>
							</svg>`;
				case 'status-refused':
					return `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ea4e2c">
							<path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
							<path d="M3 12v2h9l-1.34 5.34L15 15V5H6z" opacity="1"/>
							<path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm0 12-4.34 4.34L12 14H3v-2l3-7h9v10zm4-12h4v12h-4z"/>
						</svg>`;
				case 'status-validated':
					return `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2df52a">
								<path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
								<path d="M21 12v-2h-9l1.34-5.34L9 9v10h9z" opacity="1"/>
								<path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z"/>
							</svg>`;
			}
		};
		return icon(status);
	} else {
		const icon = (status) => {
			switch (status) {
				case 'status-not-submitted':
					return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M21.9208 13.265C21.9731 12.8507 22 12.4285 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C12.4354 22 12.8643 21.9722 13.285 21.9182M12 6V12L15.7384 13.8692M19 22V16M16 19H22" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>`;
				case 'status-info':
					return `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#225CBD"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M18.5,11c0.17,0,0.34,0.01,0.5,0.03V6.87C19,5.84,18.16,5,17.13,5H3.87C2.84,5,2,5.84,2,6.87v10.26 C2,18.16,2.84,19,3.87,19h9.73C13.22,18.25,13,17.4,13,16.5C13,13.46,15.46,11,18.5,11z M10.4,13L4,9.19V7h0.23l6.18,3.68L16.74,7 H17v2.16L10.4,13z"/><polygon points="19,13 17.59,14.41 19.17,16 15,16 15,18 19.17,18 17.59,19.59 19,21 23,17"/></g></g></svg>`;
				case 'status-pending':
					return `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#edbe13">
								<path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
								<path d="M21 12v-2h-9l1.34-5.34L9 9v10h9z" opacity="1"/>
								<path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z"/>
							</svg>`;
				case 'status-refused':
					return `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ea4e2c">
							<path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
							<path d="M3 12v2h9l-1.34 5.34L15 15V5H6z" opacity="1"/>
							<path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm0 12-4.34 4.34L12 14H3v-2l3-7h9v10zm4-12h4v12h-4z"/>
						</svg>`;
				case 'status-validated':
					return `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2df52a">
								<path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
								<path d="M21 12v-2h-9l1.34-5.34L9 9v10h9z" opacity="1"/>
								<path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z"/>
							</svg>`;
			}
		};
		if (item && item.internalStatus === DEFAULT_INTERNAL) {
			return icon(status);
		}
		if (item && item.internalStatus === SENT_TO_PAY) {
			return `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#bf55ec">
			<path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
			<path d="M21 12v-2h-9l1.34-5.34L9 9v10h9z" opacity="1"/>
			<path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z"/>
		</svg>`;
		}
		return icon(status);
	}
};

const formatTextToSql = (search) => {
	if (search && !_.isEmpty(search)) {
		search = search.trim().replaceAll(REG_SEARCH_FORMAT.pattern, '%');
		search = noTilde(search);
		search = search.replaceAll(/[%]+/g, '%');
		search = '%' + search + '%';
	}

	return search;
};

export {
	useWorktype,
	useValueType,
	useRangeHour,
	useRangeMinute,
	ACCESS_LEVEL,
	DEPARTEMENT_VALIDATION,
	ENTRY_FOR_CREW,
	FILTER_STRING_TIME_SHEET,
	NAME_CARDS_TIMESHEET_EFC,
	ACTION_EFC_TIMESHEET,
	setSubType,
	generateID,
	getStatus,
	formatTextToSql
};
