import Vue from 'vue';
import Router from 'vue-router';
import canAccessBudgetMiddleware from '@/middleware/canAccessBudgetMiddleware';
import canAccessUsersMiddleware from '@/middleware/canAccessUsersMiddleware';
import canAccessGreenReportingMiddleware from '@/middleware/canAccessGreenReportingMiddleware';
import canAccessConfigMiddleware from '@/middleware/canAccessConfigMiddleware';
import canAccessMyBudgetMiddleware from '@/middleware/canAccessMyBudgetMiddleware';
import nextFactory from '@/middleware/nextFactory';
import { store } from '@/store';
import manageBudgetMiddleware from '@/middleware/manageBudgetMiddleware';

// Containers for appiteasy
const DefaultContainer = () => import('@/containers/DefaultContainer');

// Views
const OnboardingHome = () => import('@/views/OnboardingHome');
const Dashboard = () => import('@/views/Dashboard');
const Test = () => import('@/views/Test');
const Translations = () => import('@/views/translation/translation');
const BudgetPOList = () => import('@/views/budget/BudgetPOList');

const Colors = () => import('@/views/theme/Colors');
const Typography = () => import('@/views/theme/Typography');

const Widgets = () => import('@/views/Widgets');

const Profile = () => import('@/views/Profile');
const MyContracts = () => import('@/views/mycontracts');
const ChangePassword = () => import('@/views/users/ChangePassword');

// components
const CapturePicture = () => import('@/components/CapturePicture');
const CropPicture = () => import('@/components/CropPicture');

// Views - Pages
const Page404 = () => import('@/views/pages/Page404');
const Page500 = () => import('@/views/pages/Page500');
const Login = () => import('@/views/pages/Login');
const Register = () => import('@/views/pages/Register');
const NewRegister = () => import('@/views/pages/NewRegister');
const PageOodriveSuccess = () => import('@/views/pages/oodrive-success');
const PageOodriveFailure = () => import('@/views/pages/oodrive-failure');

// Users
const Users = () => import('@/views/users/Users');
const User = () => import('@/views/users/User');
const NewUser = () => import('@/views/users/UserForm');
// contacts
const Contacts = () => import('@/views/users/Contacts');

// Project administration
const ProjectAdministration = () => import('@/views/ProjectAdministration');
// Projects
const Projects = () => import('@/views/projects/Projects');
const ProjectForm = () => import('@/views/projects/ProjectForm');
const MyProject = () => import('@/views/projects/MyProject');

const MemoDeals = () => import('@/views/projects/MemoDeals');
// Timesheet
const Work = () => import('@/views/timesheet/Work');
// Stage days
const StageDay = () => import('@/views/stageday/stageday');

const MyTimesheets = () => import('@/views/mytimesheets/MyTimesheets');
const TimesheetDetails = () => import('@/views/mytimesheets/TimesheetDetails');
const GreenReportScreen = () => import('@/views/green/Green');
const Waste = () => import('@/views/waste/Waste');

const ValidateTimesheets = () => import('@/views/validateTS/ValidateTimesheets');
const ValidateTSDetails = () => import('@/views/validateTS/ValidateTSDetails');

// PO
const MyPo = () => import('@/views/mypo/MyPo');

const LocationsIndex = () => import('@/views/locations/index');
const LocationsId = () => import('@/views/locations/_id');

// Expenses
const MyExpenses = () => import('@/views/myexpenses/MyExpenses');
const ExpenseDetails = () => import('@/views/myexpenses/ExpenseDetails');

// Expense Forms
const ExpenseForm = () => import('@/components/MyExpenseMobile/ExpenseForm');

const ValidateExpenses = () => import('@/views/validateExp/ValidateExpenses');
const ValidateExpDetails = () => import('@/views/validateExp/ValidateExpDetails');

const ValidatePOs = () => import('@/views/validatePO/ValidatePOs');
const ValidatePoDetails = () => import('@/views/validatePO/ValidatePoDetails');

// Documents
const Documents = () => import('@/views/documents/documents');
const DocumentDetails = () => import('@/views/documents/documentDetails');
const MyDocumentDetails = () => import('@/views/documents/MyDocumentDetails');
const Deliveries = () => import('@/views/documents/deliveries');
const MyDocuments = () => import('@/views/documents/mydocuments');

// Tip of the day
const TipOfDay = () => import('@/views/tod/tipofday');
const MyTipOfDay = () => import('@/views/tod/mytipofday');

// Green Film
const GreenFilmPrerequisite = () => import('@/views/greenfilm/prerequisite');
const GreenFilmDocs = () => import('@/components/greenfilm/greenfilmdocs');
const GreenFilmFinalReport = () => import('@/views/greenfilm/finalreport');

// Albert
const AlbertProd = () => import('@/views/albert/albertprod');
const AlbertActionPlan = () => import('@/views/albert/albertactionplan');
const AlbertActionPlanSection = () => import('@/views/albert/albertactionplan/Section');
const AlbertActionReport = () => import('@/views/albert/albertactionreport');
const AlbertCarbonFootprint = () => import('@/views/albert/albertcarbonfootprint');

// green suppliers
const LoadGreenSuppliers = () => import('@/views/suppliergreen/loadgreensupplier');

// Accomodations
const AccomodationList = () => import('@/views/accomodation/accomodationList');
const Accomodations = () => import('@/views/accomodation/accomodations');
const Distribution = () => import('@/views/accomodation/distribution');
const MyAccomodations = () => import('@/views/accomodation/myaccomodations');

// Licenses
const LicenseList = () => import('@/views/license/licenseList');
const License = () => import('@/views/license/license');

// Organisation
const Organisation = () => import('@/views/Organisation');

// Validation
const Validations = () => import('@/views/Validations');

const Expenses = () => import('@/views/Expenses');
const Timesheets = () => import('@/views/Timesheets');
const Exports = () => import('@/views/Exports');
const Imports = () => import('@/views/Imports');
const BudgetGlobal = () => import('@/views/budget/BudgetGlobal');
const BudgetForOneDepartment = () => import('@/views/budget/BudgetForOneDepartment');
const PoDetails = () => import('@/views/budget/PoDetails');
const TotalSalariesForOneDepartment = () => import('@/views/budget/TotalSalariesForOneDepartment');
const TimesheetsOfUser = () => import('@/views/budget/TimesheetsOfUser');

const ExpenseItemsDepCat = () => import('@/views/budget/expenseItemsDepCat');
const Category = () => import('@/views/budget/NewCategoryScreen');

const Department = () => import('@/views/department/department');
const Function = () => import('@/views/department/function');
const MyDashboard = () => import('@/views/MyDashboard');

const Testscreen = () => import('@/views/testscreen');

const RedirectSubscription = () => import('@/views/RedirectSubscription');

// Task types
// const TaskTypes = () =>

// Teams

import { transportRoutes } from '@/modules/transports/route';
import { documentPackageRoutes } from '@/modules/document-package/route';
import canAccessToAppMiddleware from '../middleware/canAccessToAppMiddleware';
import { Capacitor } from '@capacitor/core';
import { carbonRemovalRoutes } from '@/modules/carbon-removal/route';
import { onboardingRoutes } from '@/modules/onboarding/route';
import { carbonClapRoutes } from '@/modules/carbonclap/route';
import { fimalacRoutes } from '@/modules/fimalac/route';

Vue.use(Router);

const router = new Router({
	mode: 'hash', // https://router.vuejs.org/api/#mode
	linkActiveClass: 'open active',
	scrollBehavior: () => ({ y: 0 }),
	routes: [
		{
			path: '/',
			redirect: '/pages/login',
			name: 'TheGreenShot',
			component: DefaultContainer,
			children: [
				...transportRoutes,
				...documentPackageRoutes,
				...carbonRemovalRoutes,
				...onboardingRoutes,
				...carbonClapRoutes,
				...fimalacRoutes,
				{
					path: 'dashboard',
					name: 'Dashboard',
					meta: {
						middleware: canAccessToAppMiddleware
					},
					component: Dashboard
				},
				{
					path: 'home',
					name: 'Home',
					meta: {
						middleware: canAccessToAppMiddleware
					},
					component: OnboardingHome
				},
				{
					path: 'my-dashboard',
					name: 'My Dashboard',
					meta: {
						middleware: canAccessToAppMiddleware
					},
					component: OnboardingHome
				},
				{
					path: 'test',
					name: 'Test',
					meta: {
						middleware: canAccessToAppMiddleware
					},
					component: Test
				},
				{
					path: 'myproject',
					name: 'My Project',
					meta: {
						middleware: canAccessToAppMiddleware
					},
					component: MyProject
				},
				{
					path: 'myoffice',
					name: 'My Office',
					meta: {
						middleware: canAccessToAppMiddleware
					},
					component: MyProject
				},
				{
					path: 'profile',
					meta: {
						label: 'Profile',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							name: 'Profile',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: Profile
						},
						{
							path: 'CropPicture',
							name: 'Crop Picture',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: CropPicture
						}
					]
				},
				{
					path: 'mycontracts',
					meta: {
						label: 'My Contracts',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							name: 'My Contracts',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: MyContracts
						}
					]
				},
				{
					path: 'mytasks',
					meta: {
						label: 'Administration',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/tasks/index.vue'),
							meta: { label: 'My Tasks', middleware: canAccessToAppMiddleware },
							name: 'myTasks'
						},
						{
							path: ':id',
							component: () => import('@/views/tasks/_id.vue'),
							meta: { label: 'My Task details', middleware: canAccessToAppMiddleware }
						}
					]
				},
				{
					path: 'myplannings',
					meta: {
						label: 'Administration',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/plannings/index.vue'),
							meta: { label: 'My Plannings', middleware: canAccessToAppMiddleware },
							name: 'myPlannings'
						}
					]
				},
				{
					path: 'CapturePicture',
					name: 'Capture Picture',
					meta: {
						middleware: canAccessToAppMiddleware
					},
					component: CapturePicture
				},
				{
					path: 'password',
					name: 'Change Password',
					meta: {
						middleware: canAccessToAppMiddleware
					},
					component: ChangePassword
				},
				{
					path: 'users',
					meta: {
						label: 'Users',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: Users,
							meta: {
								middleware: canAccessToAppMiddleware
							},
							name: 'All Users'
						},
						{
							path: 'new',
							meta: {
								label: 'New User',
								middleware: canAccessToAppMiddleware
							},
							name: 'New User',
							component: NewUser
						},
						{
							path: ':id',
							meta: {
								label: 'User Details',
								middleware: canAccessToAppMiddleware
							},
							name: 'User details',
							component: User
						}
					]
				},
				{
					path: 'allUsers',
					meta: {
						label: 'Users',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: Users,
							meta: {
								middleware: canAccessToAppMiddleware
							},
							name: 'All App Users'
						}
					]
				},

				{
					path: 'timesheet',
					meta: {
						label: 'Timesheets',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: Work,
							meta: {
								middleware: canAccessToAppMiddleware
							},
							name: 'Work'
						}
					]
				},
				{
					path: 'exports',
					meta: {
						label: 'Exports',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: Exports,
							name: 'Exports'
						}
					]
				},
				{
					path: 'imports',
					meta: {
						label: 'Imports',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: Imports,
							name: 'Imports'
						}
					]
				},
				{
					path: 'stageday',
					meta: {
						label: 'Stage Days',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: StageDay,
							name: 'StageDay'
						}
					]
				},
				{
					path: 'mytimesheets',
					meta: {
						label: 'Timesheets',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: MyTimesheets,
							name: 'My Timesheets'
						},
						{
							path: ':id',
							meta: {
								label: 'Timesheet Details',
								middleware: canAccessToAppMiddleware
							},
							name: 'Timesheet Details',
							component: TimesheetDetails
						}
					]
				},

				{
					path: 'prerequisite',
					meta: {
						label: 'Green Film',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: GreenFilmPrerequisite,
							name: 'Prerequisite',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						}
					]
				},
				{
					path: 'finalreport',
					meta: {
						label: 'Green Film',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: GreenFilmFinalReport,
							name: 'Final report',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						}
					]
				},

				{
					path: 'albertproduction',
					meta: {
						label: 'Albert',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: AlbertProd,
							name: 'Production',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						}
					]
				},
				{
					path: 'albertactionplan',
					meta: {
						label: 'Albert',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: AlbertActionPlan,
							name: 'Action plan',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						},
						{
							path: ':id',
							component: AlbertActionPlanSection,
							name: 'Action plan detail',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						}
					]
				},
				{
					path: 'albertactionreport',
					meta: {
						label: 'Albert',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: AlbertActionReport,
							name: 'Action reports',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						}
					]
				},
				{
					path: 'albertcarbonfootprint',
					meta: {
						label: 'Albert',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: AlbertCarbonFootprint,
							name: 'Carbon footprint',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						}
					]
				},
				{
					path: 'green',
					meta: {
						label: 'Reporting',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: 'reporting',
							component: GreenReportScreen,
							name: 'greenReporting',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						},
						{
							path: 'waste',
							component: Waste,
							name: 'Waste',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						}
					]
				},

				// {
				//   path: "TransportsList",
				//   meta: { label: "Transports" },
				//   component: {
				//     render(c) {
				//       return c("router-view");
				//     },
				//   },
				//   children: [
				//     {
				//       path: "",
				//       component: () => import("@/views/transports/admin/index.vue"),
				//       name: "Transports list",
				//     },
				//     {
				//       path: ":id",
				//       component: () => import("@/views/transports/admin/_id.vue"),
				//       name: "Transport",
				//     },
				//   ],
				// },
				{
					path: 'clientsList',
					meta: {
						label: 'Administration',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/clients/index'),
							name: 'Clients list',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						},
						{
							path: ':id',
							component: () => import('@/views/clients/_id'),
							name: 'Client',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						}
					]
				},
				{
					path: 'licenseList',
					meta: {
						label: 'License list',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: LicenseList,
							name: 'License list',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						},

						{
							path: ':id',
							component: License,
							name: 'License',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						},
						{
							path: ':licenseId/newProject',
							meta: { label: 'New project for license' },
							name: 'AddProjectForLicenseFromDev',
							component: ProjectForm
						},
						{
							path: ':licenseId/updProject/:projectId',
							meta: { label: 'Update project', middleware: canAccessToAppMiddleware },
							name: 'UpdateProjectForLicenseFromDev',
							component: ProjectForm
						}
					]
				},
				{
					path: 'documents',
					meta: {
						label: 'Administration',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: Documents,
							meta: { label: 'Documents', middleware: canAccessToAppMiddleware }
						},
						{
							path: 'documentDetails/:id',
							component: DocumentDetails,
							meta: { label: ' Document details', middleware: canAccessToAppMiddleware }
						},
						{
							path: 'delivery/:id',
							component: Deliveries,
							meta: { label: ' Deliveries for this document', middleware: canAccessToAppMiddleware }
						}
					]
				},
				{
					path: 'assignationMultiple',
					meta: {
						label: 'Administration',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/assignationMultiple/index.vue'),
							meta: { label: 'Assignation Multiple', middleware: canAccessToAppMiddleware },
							name: 'assignationMultiple'
						}
					]
				},
				{
					path: 'tasks',
					meta: {
						label: 'Administration - task',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/tasks/index.vue'),
							meta: { label: 'Tasks', middleware: canAccessToAppMiddleware },
							name: 'taskList'
						},
						{
							path: ':id',
							component: () => import('@/views/tasks/_id.vue'),
							meta: { label: ' Task details', middleware: canAccessToAppMiddleware }
						}
					]
				},
				{
					path: 'offers',
					meta: {
						label: 'Administration - Offers',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/tasks/index.vue'),
							meta: { label: 'Offers', middleware: canAccessToAppMiddleware },
							name: 'offers'
						},
						{
							path: ':id',
							component: () => import('@/views/offers/_id.vue'),
							meta: { label: 'Offers details', middleware: canAccessToAppMiddleware }
						}
					]
				},
				{
					path: 'plannings',
					meta: {
						label: 'Administration',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/plannings/index.vue'),
							meta: { label: 'Plannings', middleware: canAccessToAppMiddleware },
							name: 'plannings'
						}
					]
				},
				{
					path: 'gantt',
					meta: {
						label: 'Administration',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/gantt/index.vue'),
							meta: { label: 'Gantt', middleware: canAccessToAppMiddleware },
							name: 'gantt'
						}
					]
				},
				{
					path: 'perAssignee',
					meta: {
						label: 'Administration',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/perAssignee/index.vue'),
							meta: { label: 'Per Assignee', middleware: canAccessToAppMiddleware },
							name: 'perAssignee'
						}
					]
				},
				{
					path: 'validateTS',
					meta: {
						label: 'Administration',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: ValidateTimesheets,
							name: 'Validate Timesheets'
						},
						{
							path: ':id',
							meta: { label: 'Timesheet Details', middleware: canAccessToAppMiddleware },
							name: 'Validate Timesheet Details',
							component: ValidateTSDetails
						}
					]
				},
				{
					path: 'validateExp',
					meta: {
						label: 'Administration',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: ValidateExpenses,
							name: 'Validate Expenses',
							meta: { middleware: canAccessToAppMiddleware }
						},
						{
							path: ':id',
							meta: { label: 'Expense Details', middleware: canAccessToAppMiddleware },
							name: 'Validate Expense Details',
							component: ValidateExpDetails
						}
					]
				},
				{
					path: 'validatePO',
					meta: {
						label: 'Administration',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: ValidatePOs,
							name: 'Validate Purchase Orders'
						},
						{
							path: ':id',
							meta: { label: 'Purchase Order Details', middleware: canAccessToAppMiddleware },
							name: 'Validate Purchase Order Details',
							component: ValidatePoDetails
						}
					]
				},
				{
					path: 'mypo',
					meta: {
						label: 'Purchase Order',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: MyPo,
							name: 'My PO'
						}
					]
				},
				{
					path: 'timesheetsEntryCrew',
					meta: {
						label: 'Timesheets Entry Crew',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: () => import('@/views/entryForCrew/timesheets/TimesheetsEntryCrew'),
							name: 'Timesheets For Entry Crew'
						},
						{
							path: ':id',
							meta: {
								label: 'Timesheet Details entry for crew',
								middleware: canAccessToAppMiddleware
							},
							name: 'Timesheet Details entry for crew',
							component: TimesheetDetails
						}
					]
				},
				{
					path: 'expensesEntryCrew',
					meta: {
						label: 'Expenses Entry Crew',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: () => import('@/views/entryForCrew/expenses/ExpensesEntryCrew'),
							name: 'expensesEntryCrew'
						},
						{
							path: ':id',
							meta: { label: 'Expense For Entry Crew Details', middleware: canAccessToAppMiddleware },
							name: 'expensesEntryCrewDetails',
							component: () => import('@/views/entryForCrew/expenses/ExpensesEntryCrewDetails')
						}
					]
				},
				{
					path: 'poEntryCrew',
					meta: {
						label: 'PO Entry Crew',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: () => import('@/views/entryForCrew/po/PoEntryCrew'),
							name: 'poEntryCrew'
						}
					]
				},
				{
					path: 'Locations',
					meta: {
						label: 'Locations',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: LocationsIndex,
							name: 'Locations list'
						},
						{
							path: ':id',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: LocationsId,
							name: 'Location'
						}
					]
				},
				{
					path: 'administration/plannings',
					meta: {
						label: 'Plannings',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/plannings/index.vue'),
							meta: { label: 'Plannings', middleware: canAccessToAppMiddleware },
							name: 'AdministrationPlannings'
						}
					]
				},
				{
					path: 'administration/tasks',
					meta: {
						label: 'Tasks',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/tasks/index.vue'),
							meta: { label: 'Tasks', middleware: canAccessToAppMiddleware },
							name: 'administrationTasks'
						}
					]
				},
				{
					path: 'administration/offers',
					meta: {
						label: 'Offers',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/tasks/index.vue'),
							meta: { label: 'Offers' },
							name: 'administrationOffers'
						}
					]
				},
				{
					path: 'Offices',
					meta: {
						label: 'Offices',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: LocationsIndex,
							name: 'Offices list'
						},
						{
							path: ':id',
							component: LocationsId,
							name: 'Office'
						}
					]
				},
				{
					path: 'mybudget',
					meta: {
						label: 'My Budget',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							meta: { label: 'For my department', middleware: canAccessToAppMiddleware },
							name: 'My budget details',
							component: BudgetForOneDepartment
						}
					]
				},
				{
					path: 'myexpenses',
					meta: {
						label: 'Expenses',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: MyExpenses,
							name: 'My Expenses',
							meta: { middleware: canAccessToAppMiddleware }
						},
						{
							path: ':id',
							meta: { label: 'Expense Details', middleware: canAccessToAppMiddleware },
							name: 'Expense Details',
							component: ExpenseDetails
						},
						{
							path: ':form/:action/:type',
							meta: { label: 'Add Expense', middleware: canAccessToAppMiddleware },
							name: 'Add Expense',
							component: ExpenseForm
						},
						{
							path: ':form/:action/:type/:expenseId',
							meta: { label: 'Update Expense', middleware: canAccessToAppMiddleware },
							name: 'Update Expense',
							component: ExpenseForm
						}
					]
				},
				// {
				//   path: "mytransport",
				//   meta: { label: "Transports" },
				//   component: {
				//     render(c) {
				//       return c("router-view");
				//     },
				//   },
				//   children: [
				//     {
				//       path: "",
				//       component: () => import("@/views/transports/my/index"),
				//       name: "My Transport",
				//     },
				//     {
				//       path: ":id",
				//       meta: { label: "My transport details Details" },
				//       name: "My transport details",
				//       component: () => import("@/views/transports/my/_id"),
				//     },
				//   ],
				// },
				{
					path: 'mydocuments',
					meta: {
						label: 'My Documents',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: MyDocuments,
							meta: { middleware: canAccessToAppMiddleware },
							name: 'MyDocuments'
						}
					]
				},
				{
					path: 'accomodationList',
					meta: {
						label: 'Administration',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: AccomodationList,
							name: 'Accomodation list',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						},
						{
							path: ':id',
							component: Accomodations,
							name: 'Accomodation',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						},
						{
							path: 'distribution/:id',
							meta: { label: 'Users going to this accomodation', middleware: canAccessToAppMiddleware },
							component: Distribution
						}
					]
				},
				{
					path: 'myaccomodations',
					meta: {
						label: 'My Accomodations',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: MyAccomodations,
							name: 'MyAccomodations',
							meta: { middleware: canAccessToAppMiddleware }
						}
					]
				},
				{
					path: 'contacts',
					meta: {
						label: 'Contacts',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: Contacts,
							name: 'Contacts',
							meta: { middleware: canAccessToAppMiddleware }
						}
					]
				},
				{
					path: 'projects',
					meta: {
						label: 'Projects',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: Projects,
							meta: { middleware: canAccessToAppMiddleware }
						},
						{
							path: 'new',
							meta: { label: 'New Project', middleware: canAccessToAppMiddleware },
							name: 'NewProject',
							component: ProjectForm
						},

						{
							path: ':id/update',
							meta: { label: 'Update Project', middleware: canAccessToAppMiddleware },
							name: 'UpdateProject',
							component: ProjectForm
						},
						{
							path: ':id/users',
							meta: { label: 'Project Users', middleware: canAccessToAppMiddleware },
							name: 'ProjectUsers',
							component: Users
						},
						{
							path: ':id/adduser',
							meta: { label: 'Add User', middleware: canAccessToAppMiddleware },
							name: 'AddUser',
							component: NewUser
						},
						{
							path: ':projectId',
							meta: { label: 'Project Details', middleware: canAccessToAppMiddleware },
							name: 'UpdateProjectFromProjects',
							component: ProjectForm
						}
					]
				},
				{
					path: 'categories',
					meta: {
						label: 'Categories',
						middleware: canAccessToAppMiddleware
					},
					component: Category,
					name: 'Categories'
				},
				{
					path: 'budget',
					meta: {
						label: 'Budget',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: BudgetGlobal,
							name: 'overallBudget',
							meta: {
								middleware: canAccessToAppMiddleware
							}
						},
						{
							path: 'purchase-orders/:departmentId/:categoryId',
							meta: {
								label: 'Budget Purchase Orders',
								middleware: canAccessToAppMiddleware
							},
							name: 'BudgetPOList',
							component: BudgetPOList
						},
						{
							path: 'purchase-order/:id',
							meta: {
								label: 'PO Details',
								middleware: canAccessToAppMiddleware
							},
							name: 'PODetails',
							component: PoDetails
						},
						{
							path: 'department/:department',
							meta: { label: 'For one department', middleware: canAccessToAppMiddleware },
							name: 'Department Details',
							component: BudgetForOneDepartment
						},
						{
							path: 'department/:department/category/:category',
							meta: { label: 'For one department / one category', middleware: manageBudgetMiddleware },
							name: 'Department Category Details',
							component: ExpenseItemsDepCat
						},
						{
							path: 'department/:department/salariesForCategory/:category',
							meta: { label: 'Salaries for one department', middleware: manageBudgetMiddleware },
							name: 'Department Salaries Details',
							component: TotalSalariesForOneDepartment
						},
						{
							path: 'department/:department/salaries/:userId',
							meta: { label: 'Timesheet details for one user', middleware: manageBudgetMiddleware },
							name: 'User Timesheet Details',
							component: TimesheetsOfUser
						},
						{
							//path: 'department/:department/salaries/:userId/timesheet/:id',
							path: '/timesheet details/:id',
							meta: { label: 'Daily work', middleware: manageBudgetMiddleware },
							name: 'Budget Timesheet Details',
							component: TimesheetDetails
						},
						{
							path: '/expense-sheet-detail/:id',
							meta: { label: 'Expense Details', middleware: manageBudgetMiddleware },
							name: 'budget-expense-sheet-details',
							component: ExpenseDetails
						}
					]
				},
				{
					path: 'departments',
					meta: {
						label: 'Departments',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: Department,
							name: 'Departments',
							meta: { middleware: canAccessToAppMiddleware }
						},
						{
							path: ':department/functions',
							meta: { label: 'Functions', middleware: canAccessToAppMiddleware },
							name: 'Functions for one department',
							component: Function
						}
					]
				},
				{
					path: 'testscreen',
					meta: {
						label: 'testscreen',
						middleware: canAccessToAppMiddleware
					},
					component: GreenFilmDocs,
					name: 'testscreen'
				},
				{
					path: 'translation',
					meta: {
						label: 'Translations',
						middleware: canAccessToAppMiddleware
					},
					component: Translations,
					name: 'Translation'
				},
				{
					path: 'LoadGreenSuppliers',
					meta: {
						label: 'Load Green Suppliers',
						middleware: canAccessToAppMiddleware
					},
					component: LoadGreenSuppliers,
					name: 'LoadGreenSupplier'
				},
				{
					path: 'expenses',
					meta: {
						label: 'Expenses',
						middleware: canAccessToAppMiddleware
					},
					component: Expenses,
					name: 'Expenses'
				},
				{
					path: 'timesheets',
					meta: {
						label: 'Timesheets',
						middleware: canAccessToAppMiddleware
					},
					component: Timesheets,
					name: 'Timesheets'
				},
				{
					path: 'settings',
					meta: {
						label: 'Settings',
						middleware: canAccessToAppMiddleware
					},
					component: ProjectAdministration
				},
				{
					path: 'tipOfTheDay',
					meta: { label: 'Tip of the day', middleware: canAccessToAppMiddleware },
					component: TipOfDay
				},
				{
					path: 'myTipOfTheDay',
					meta: { label: 'My tip of the day', middleware: canAccessToAppMiddleware },
					component: MyTipOfDay
				},
				{
					path: 'task-types',
					meta: { label: 'Task types', middleware: canAccessToAppMiddleware },
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/tasktypes/index.vue'),
							meta: { label: 'List' }
						},
						{
							path: ':id',
							component: () => import('@/views/tasktypes/_id.vue'),
							meta: { label: ' Edit' }
						}
					]
				},
				{
					path: 'teams',
					meta: { label: 'Team', middleware: canAccessToAppMiddleware },
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/teams/index.vue'),
							meta: { label: 'List' }
						},
						{
							path: ':id',
							component: () => import('@/views/teams/_id.vue'),
							meta: { label: 'Form' }
						}
					]
				},
				{
					path: 'project',
					redirect: '/projects/0', // redirect to current project details if click on project link instead of blank page
					meta: { label: 'Project', middleware: canAccessToAppMiddleware },
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: 'users',
							meta: {
								label: 'Users',
								middleware: canAccessToAppMiddleware
							},
							component: Users,
							name: 'Users'
						},
						{
							path: 'MemoDeals',
							meta: { label: 'Memo deals', middleware: canAccessToAppMiddleware },
							component: MemoDeals,
							name: 'MemoDeals'
						},
						{
							path: 'users/:id',
							meta: { label: 'User', middleware: canAccessToAppMiddleware },
							component: User,
							name: 'User'
						},
						{
							path: 'organisation',
							name: 'Organisation',
							component: Organisation,
							meta: { middleware: canAccessToAppMiddleware }
						},
						{
							path: 'validations',
							name: 'Validations',
							meta: {
								middleware: canAccessToAppMiddleware
							},
							component: Validations
						}
					]
				},
				{
					path: 'film-commission',
					meta: { label: 'Film Commission', middleware: canAccessToAppMiddleware },
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/film-commission/index.vue'),
							meta: { label: 'List' }
						},
						{
							path: ':action/:id',
							component: () => import('@/views/film-commission/_id.vue'),
							meta: { label: 'Form' }
						}
					]
				},
				{
					path: 'supplier-source',
					meta: { label: 'Supplier Source', middleware: canAccessToAppMiddleware },
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/supplier-source/index.vue'),
							meta: { label: 'List' }
						},
						{
							path: ':action/:id',
							component: () => import('@/views/supplier-source/_id.vue'),
							meta: { label: 'Form' }
						}
					]
				},
				{
					path: 'supplier-label',
					meta: { label: 'Supplier Label', middleware: canAccessToAppMiddleware },
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/supplier-label/index.vue'),
							meta: { label: 'List' }
						},
						{
							path: ':action/:id',
							component: () => import('@/views/supplier-label/_id.vue'),
							meta: { label: 'Form' }
						}
					]
				},
				{
					path: 'mydocuments',
					meta: {
						label: 'My Documents',
						middleware: canAccessToAppMiddleware
					},
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: 'document-details/:id/:contractId',
							component: MyDocumentDetails,
							meta: { label: ' Document details' }
						}
					]
				},
				{
					path: 'scripts',
					meta: { label: 'Scripts', middleware: canAccessToAppMiddleware },
					component: {
						render(c) {
							return c('router-view');
						}
					},
					children: [
						{
							path: '',
							component: () => import('@/views/script/index.vue'),
							meta: { label: 'List' }
						},
						{
							path: ':action/:id',
							component: () => import('@/views/script/_id.vue'),
							meta: { label: 'Form' }
						}
					]
				}
			]
		},
		{
			path: '/loading',
			name: 'loading',
			component: () => import('@/views/pages/loading.vue')
		},
		{
			path: '/loading-new',
			name: 'loading-new',
			component: () => import('@/views/pages/loading-new.vue')
		},
		{
			path: '/pages/redirect-subscription',
			name: 'redirect-subscription',
			component: RedirectSubscription
		},
		{
			path: '/pages',
			redirect: '/pages/404',
			name: 'Pages',
			component: {
				render(c) {
					return c('router-view');
				}
			},

			children: [
				{
					path: '404',
					name: 'Page404',
					component: Page404
				},
				{
					path: '500',
					name: 'Page500',
					component: Page500
				},
				{
					path: 'login',
					name: 'Login',
					component: Login
				},
				{
					path: 'register',
					name: 'Register',
					// component: Register
					component: NewRegister
				}
			]
		},
		{ path: '*', redirect: '/pages/404' },
		{
			path: '/oodrive-success',
			name: 'Page Success For Signature',
			component: PageOodriveSuccess
		},
		{
			path: '/oodrive-failure',
			name: 'Page Failure For Signature',
			component: PageOodriveFailure
		}
	]
});

const DEFAULT_TITLE = 'TheGreenShot';

router.beforeResolve((to, from, next) => {
	// If this isn't an initial page load.
	if (to.name) {
		// Start the route progress bar.
		NProgress.start();
	}
	next();
});

router.afterEach((to, from) => {
	if (to.name) {
		document.title = DEFAULT_TITLE + ' - ' + to.name;
	} else {
		document.title = DEFAULT_TITLE;
	}
	NProgress.done();
});

router.beforeEach((to, from, next) => {
	if (Capacitor.getPlatform() !== 'web') {
		next();
	}
	if (to.meta.middleware) {
		const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

		const context = {
			from,
			next,
			router,
			to,
			localStore: store
		};

		const nextMiddleware = nextFactory(context, middleware, 1);

		return middleware[0]({ ...context, next: nextMiddleware });
	}

	next();
});

export default router;
