import { addUpdAdvancedGlobalFilter, getAdvancedGlobalFilter, deleteAdvancedGlobalFilter } from '@/cruds/global-filter.crud';
import _ from 'lodash';

/*
 * Filter type value possible
 * 0: Filter for budget per category
 * 1: Filter for budget salaries
 * 2: Filter for budget expenses
 * 3: Filter for budget purchase orders
 * 4: Filter for budget expense sheet
 * 5: Filter for green table
 */

const state = () => ({
	myLocationToday: true,
	globalFilter: {
		forBudgetPerCategory: null,
		forBudgetSalaries: null,
		forBudgetExpenses: null,
		forBudgetPO: null,
		forBudgetExpenseSheet: null,
		forGreenTable: null
	},
	paginationFilter: {
		forBudgetSalaries: { recordLimit: 100, recordOffSet: 0 },
		forBudgetExpenses: { recordLimit: 100, recordOffSet: 0 },
		forBudgetPO: { recordLimit: 100, recordOffSet: 0 },
		forBudgetExpenseSheet: { recordLimit: 100, recordOffSet: 0 },
		forGreenTable: { recordLimit: 100, recordOffSet: 0 }
	},
	perPageFilter: {
		forBudgetSalaries: 100,
		forBudgetExpenses: 100,
		forBudgetPO: 100,
		forBudgetExpenseSheet: 100,
		forGreenTable: 100
	},
	listFilters: {
		forBudgetPerCategory: [],
		forBudgetSalaries: [],
		forBudgetExpenses: [],
		forBudgetPO: [],
		forBudgetExpenseSheet: [],
		forGreenTable: []
	}
});

const getters = {
	filterBudget: (state) => state.globalFilter,

	paginationFilter: (state) => state.paginationFilter,

	perPageFilter: (state) => state.perPageFilter,

	listFilters: (state) => state.listFilters,

	myLocationToday: (state) => state.myLocationToday
};

const mutations = {
	SET_MY_LOCATION_TODAY(state, value) {
		state.myLocationToday = value;
	},

	SET_FILTER_BUDGET_PER_CATEGORY(state, globalFilter) {
		state.globalFilter.forBudgetPerCategory = globalFilter;
	},
	SET_FILTER_BUDGET_SALARIES(state, globalFilter) {
		state.globalFilter.forBudgetSalaries = globalFilter;
	},
	SET_FILTER_BUDGET_EXPENSES(state, globalFilter) {
		state.globalFilter.forBudgetExpenses = globalFilter;
	},
	SET_FILTER_BUDGET_PO(state, globalFilter) {
		state.globalFilter.forBudgetPO = globalFilter;
	},
	SET_FILTER_BUDGET_EXPENSE_SHEET(state, globalFilter) {
		state.globalFilter.forBudgetExpenseSheet = globalFilter;
	},
	SET_FILTER_GREEN_TABLE(state, globalFilter) {
		state.globalFilter.forGreenTable = globalFilter;
	},

	SET_PAGINATION_BUDGET_SALARIES_RECORD_LIMIT(state, recordLimit) {
		state.paginationFilter.forBudgetSalaries.recordLimit = recordLimit;
	},
	SET_PAGINATION_BUDGET_SALARIES_RECORD_OFFSET(state, recordOffSet) {
		state.paginationFilter.forBudgetSalaries.recordOffSet = recordOffSet;
	},
	SET_PAGINATION_BUDGET_EXPENSES_RECORD_LIMIT(state, recordLimit) {
		state.paginationFilter.forBudgetExpenses.recordLimit = recordLimit;
	},
	SET_PAGINATION_BUDGET_EXPENSES_RECORD_OFFSET(state, recordOffSet) {
		state.paginationFilter.forBudgetExpenses.recordOffSet = recordOffSet;
	},
	SET_PAGINATION_BUDGET_PO_RECORD_LIMIT(state, recordLimit) {
		state.paginationFilter.forBudgetPO.recordLimit = recordLimit;
	},
	SET_PAGINATION_BUDGET_PO_RECORD_OFFSET(state, recordOffSet) {
		state.paginationFilter.forBudgetPO.recordOffSet = recordOffSet;
	},
	SET_PAGINATION_BUDGET_EXPENSE_SHEET_OFFSET(state, recordOffset) {
		state.paginationFilter.forBudgetExpenseSheet.recordOffSet = recordOffset;
	},
	SET_PAGINATION_BUDGET_EXPENSE_SHEET_LIMIT(state, recordLimit) {
		state.paginationFilter.forBudgetExpenseSheet.recordLimit = recordLimit;
	},
	SET_PAGINATION_GREEN_TABLE_OFFSET(state, recordOffset) {
		state.paginationFilter.forGreenTable.recordOffSet = recordOffset;
	},
	SET_PAGINATION_GREEN_TABLE_LIMIT(state, recordLimit) {
		state.paginationFilter.forGreenTable.recordLimit = recordLimit;
	},

	SET_PERPAGE_BUDGET_SALARIES(state, value) {
		state.perPageFilter.forBudgetSalaries = value;
	},
	SET_PERPAGE_BUDGET_EXPENSES(state, value) {
		state.perPageFilter.forBudgetExpenses = value;
	},
	SET_PERPAGE_BUDGET_PO(state, value) {
		state.perPageFilter.forBudgetPO = value;
	},
	SET_PERPAGE_BUDGET_EXPENSE_SHEET(state, value) {
		state.perPageFilter.forBudgetExpenseSheet = value;
	},
	SET_PERPAGE_GREEN_TABLE(state, value) {
		state.perPageFilter.forGreenTable = value;
	},

	SET_LIST_FILTERS_BUDGET_PER_CATEGORY(state, listFilters) {
		state.listFilters.forBudgetPerCategory = listFilters;
	},
	SET_LIST_FILTERS_BUDGET_SALARIES(state, listFilters) {
		state.listFilters.forBudgetSalaries = listFilters;
	},
	SET_LIST_FILTERS_BUDGET_EXPENSES(state, listFilters) {
		state.listFilters.forBudgetExpenses = listFilters;
	},
	SET_LIST_FILTERS_BUDGET_PO(state, listFilters) {
		state.listFilters.forBudgetPO = listFilters;
	},
	SET_LIST_FILTERS_BUDGET_EXPENSE_SHEET(state, listFilters) {
		state.listFilters.forBudgetExpenseSheet = listFilters;
	},
	SET_LIST_FILTERS_GREEN_TABLE(state, listFilters) {
		state.listFilters.forGreenTable = listFilters;
	}
};

const actions = {
	setMyLocationToday({ commit }, value) {
		commit('SET_MY_LOCATION_TODAY', value);
	},

	async addFilter({ commit, dispatch }, inputFilter) {
		try {
			if (inputFilter.startDate) {
				inputFilter.startDate = inputFilter.startDate.split('T')[0] + 'T00:00:00Z';
			}
			if (inputFilter.endDate) {
				inputFilter.endDate = inputFilter.endDate.split('T')[0] + 'T23:59:59Z';
			}
			const dataToSend = returnDataToSend(inputFilter);
			const result = await addUpdAdvancedGlobalFilter(dataToSend);

			if (result.filterType === 0) {
				const res = returnData(result);
				commit('SET_FILTER_BUDGET_PER_CATEGORY', res);
			} else if (result.filterType === 1) {
				const res = returnData(result);
				commit('SET_FILTER_BUDGET_SALARIES', res);
				commit('SET_PAGINATION_BUDGET_SALARIES_RECORD_LIMIT', result.recordLimit);
				commit('SET_PAGINATION_BUDGET_SALARIES_RECORD_OFFSET', result.recordOffSet);
			} else if (result.filterType === 2) {
				const res = returnData(result);
				commit('SET_FILTER_BUDGET_EXPENSES', res);
				commit('SET_PAGINATION_BUDGET_EXPENSES_RECORD_LIMIT', result.recordLimit);
				commit('SET_PAGINATION_BUDGET_EXPENSES_RECORD_OFFSET', result.recordOffSet);
			} else if (result.filterType === 3) {
				const res = returnData(result);
				commit('SET_FILTER_BUDGET_PO', res);
				commit('SET_PAGINATION_BUDGET_PO_RECORD_LIMIT', result.recordLimit);
				commit('SET_PAGINATION_BUDGET_PO_RECORD_OFFSET', result.recordOffSet);
			} else if (result.filterType === 4) {
				const res = returnData(result);
				commit('SET_FILTER_BUDGET_EXPENSE_SHEET', res);
				commit('SET_PAGINATION_BUDGET_EXPENSE_SHEET_LIMIT', result.recordLimit);
				commit('SET_PAGINATION_BUDGET_EXPENSE_SHEET_OFFSET', result.recordOffSet);
			} else if (result.filterType === 5) {
				const res = returnData(result);
				commit('SET_FILTER_GREEN_TABLE', res);
				commit('SET_PAGINATION_GREEN_TABLE_LIMIT', result.recordLimit);
				commit('SET_PAGINATION_GREEN_TABLE_OFFSET', result.recordOffSet);
			}

			dispatch('getListFilters', result.filterType);
		} catch (error) {
			console.log(error);
		}
	},
	async updateFilter({ commit, dispatch }, { inputFilter, filterId }) {
		try {
			const result = await addUpdAdvancedGlobalFilter(inputFilter, filterId);

			if (result.filterType === 0) {
				const res = returnData(result);
				commit('SET_FILTER_BUDGET_PER_CATEGORY', res);
			} else if (result.filterType === 1) {
				const res = returnData(result);
				commit('SET_FILTER_BUDGET_SALARIES', res);
				commit('SET_PAGINATION_BUDGET_SALARIES_RECORD_LIMIT', result.recordLimit);
				commit('SET_PAGINATION_BUDGET_SALARIES_RECORD_OFFSET', result.recordOffSet);
				commit('SET_PERPAGE_BUDGET_SALARIES', result.perPage);
			} else if (result.filterType === 2) {
				const res = returnData(result);
				commit('SET_FILTER_BUDGET_EXPENSES', res);
				commit('SET_PAGINATION_BUDGET_EXPENSES_RECORD_LIMIT', result.recordLimit);
				commit('SET_PAGINATION_BUDGET_EXPENSES_RECORD_OFFSET', result.recordOffSet);
				commit('SET_PERPAGE_BUDGET_EXPENSES', result.perPage);
			} else if (result.filterType === 3) {
				const res = returnData(result);
				commit('SET_FILTER_BUDGET_PO', res);
				commit('SET_PAGINATION_BUDGET_PO_RECORD_LIMIT', result.recordLimit);
				commit('SET_PAGINATION_BUDGET_PO_RECORD_OFFSET', result.recordOffSet);
				commit('SET_PERPAGE_BUDGET_PO', result.perPage);
			} else if (result.filterType === 4) {
				const res = returnData(result);
				commit('SET_FILTER_BUDGET_EXPENSE_SHEET', res);
				commit('SET_PAGINATION_BUDGET_EXPENSE_SHEET_LIMIT', result.recordLimit);
				commit('SET_PAGINATION_BUDGET_EXPENSE_SHEET_OFFSET', result.recordOffSet);
				commit('SET_PERPAGE_BUDGET_EXPENSE_SHEET', result.perPage);
			} else if (result.filterType === 5) {
				const res = returnData(result);
				commit('SET_FILTER_GREEN_TABLE', res);
				commit('SET_PAGINATION_GREEN_TABLE_LIMIT', result.recordLimit);
				commit('SET_PAGINATION_GREEN_TABLE_OFFSET', result.recordOffSet);
				commit('SET_PERPAGE_GREEN_TABLE', result.perPage);
			}

			if (inputFilter.isActive) {
				dispatch('getListFilters', result.filterType);
			}
		} catch (error) {
			console.log(error);
		}
	},
	async deleteFilter({ commit, dispatch }, { filterId, filterType }) {
		try {
			await deleteAdvancedGlobalFilter(filterId);

			if (filterType === 0) {
				commit('SET_FILTER_BUDGET_PER_CATEGORY', null);
			} else if (filterType === 1) {
				commit('SET_FILTER_BUDGET_SALARIES', null);
				commit('SET_PAGINATION_BUDGET_SALARIES_RECORD_LIMIT', 100);
				commit('SET_PAGINATION_BUDGET_SALARIES_RECORD_OFFSET', 0);
				commit('SET_PERPAGE_BUDGET_SALARIES', 100);
			} else if (filterType === 2) {
				commit('SET_FILTER_BUDGET_EXPENSES', null);
				commit('SET_PAGINATION_BUDGET_EXPENSES_RECORD_LIMIT', 100);
				commit('SET_PAGINATION_BUDGET_EXPENSES_RECORD_OFFSET', 0);
				commit('SET_PERPAGE_BUDGET_EXPENSES', 100);
			} else if (filterType === 3) {
				commit('SET_FILTER_BUDGET_PO', null);
				commit('SET_PAGINATION_BUDGET_PO_RECORD_LIMIT', 100);
				commit('SET_PAGINATION_BUDGET_PO_RECORD_OFFSET', 0);
				commit('SET_PERPAGE_BUDGET_PO', 100);
			} else if (filterType === 4) {
				commit('SET_FILTER_BUDGET_EXPENSE_SHEET', null);
				commit('SET_PAGINATION_BUDGET_EXPENSE_SHEET_LIMIT', 100);
				commit('SET_PAGINATION_BUDGET_EXPENSE_SHEET_OFFSET', 0);
				commit('SET_PERPAGE_BUDGET_EXPENSE_SHEET', 100);
			} else if (filterType === 5) {
				commit('SET_FILTER_GREEN_TABLE', null);
				commit('SET_PAGINATION_GREEN_TABLE_LIMIT', 100);
				commit('SET_PAGINATION_GREEN_TABLE_OFFSET', 0);
				commit('SET_PERPAGE_GREEN_TABLE', 100);
			}

			dispatch('getListFilters', filterType);
		} catch (error) {
			console.log(error);
		}
	},
	async getListFilters({ commit }, filterType = 0) {
		let result = await getAdvancedGlobalFilter(false, filterType);

		if (filterType === 0) {
			result = !_.isNil(result) ? result : [];
			commit('SET_LIST_FILTERS_BUDGET_PER_CATEGORY', result);
		} else if (filterType === 1) {
			result = !_.isNil(result) ? result : [];
			commit('SET_LIST_FILTERS_BUDGET_SALARIES', result);
		} else if (filterType === 2) {
			result = !_.isNil(result) ? result : [];
			commit('SET_LIST_FILTERS_BUDGET_EXPENSES', result);
		} else if (filterType === 3) {
			result = !_.isNil(result) ? result : [];
			commit('SET_LIST_FILTERS_BUDGET_PO', result);
		} else if (filterType === 4) {
			result = !_.isNil(result) ? result : [];
			commit('SET_LIST_FILTERS_BUDGET_EXPENSE_SHEET', result);
		} else if (filterType === 5) {
			result = !_.isNil(result) ? result : [];
			commit('SET_LIST_FILTERS_GREEN_TABLE', result);
		}
	},
	async getMyFilterActive({ commit }, filterType = 0) {
		let result = await getAdvancedGlobalFilter(true, filterType);

		if (result.filterType === 0) {
			const res = returnData(result);
			commit('SET_FILTER_BUDGET_PER_CATEGORY', res);
		} else if (result.filterType === 1) {
			const res = returnData(result);
			commit('SET_FILTER_BUDGET_SALARIES', res);
		} else if (result.filterType === 2) {
			const res = returnData(result);
			commit('SET_FILTER_BUDGET_EXPENSES', res);
		} else if (result.filterType === 3) {
			const res = returnData(result);
			commit('SET_FILTER_BUDGET_PO', res);
		} else if (result.filterType === 4) {
			const res = returnData(result);
			commit('SET_LIST_FILTERS_BUDGET_EXPENSE_SHEET', res);
		} else if (result.filterType === 5) {
			const res = returnData(result);
			commit('SET_LIST_FILTERS_GREEN_TABLE', res);
		}
	},
	setMyFilterActive({ commit }, { newValue, filterType }) {
		if (filterType === 0) {
			commit('SET_FILTER_BUDGET_PER_CATEGORY', newValue);
		} else if (filterType === 1) {
			commit('SET_FILTER_BUDGET_SALARIES', newValue);
		} else if (filterType === 2) {
			// console.log("EXPENSES MODULE", newValue)
			// alert("EXPENSES MODULES")
			commit('SET_FILTER_BUDGET_EXPENSES', newValue);
		} else if (filterType === 3) {
			commit('SET_FILTER_BUDGET_PO', newValue);
		} else if (filterType === 4) {
			commit('SET_FILTER_BUDGET_EXPENSE_SHEET', newValue);
		} else if (filterType === 5) {
			commit('SET_FILTER_GREEN_TABLE', newValue);
		}
	},
	setPagination({ commit }, { limit, offset, filterType }) {
		if (filterType === 1) {
			commit('SET_PAGINATION_BUDGET_SALARIES_RECORD_LIMIT', limit);
			commit('SET_PAGINATION_BUDGET_SALARIES_RECORD_OFFSET', offset);
		} else if (filterType === 2) {
			commit('SET_PAGINATION_BUDGET_EXPENSES_RECORD_LIMIT', limit);
			commit('SET_PAGINATION_BUDGET_EXPENSES_RECORD_OFFSET', offset);
		} else if (filterType === 3) {
			commit('SET_PAGINATION_BUDGET_PO_RECORD_LIMIT', limit);
			commit('SET_PAGINATION_BUDGET_PO_RECORD_OFFSET', offset);
		} else if (filterType === 4) {
			commit('SET_PAGINATION_BUDGET_EXPENSE_SHEET_LIMIT', limit);
			commit('SET_PAGINATION_BUDGET_EXPENSE_SHEET_OFFSET', offset);
		} else if (filterType === 5) {
			commit('SET_PAGINATION_GREEN_TABLE_LIMIT', limit);
			commit('SET_PAGINATION_GREEN_TABLE_OFFSET', offset);
		}
	},
	setPerPageFilter({ commit }, { perPage, filterType }) {
		if (filterType === 1) {
			commit('SET_PERPAGE_BUDGET_SALARIES', perPage);
		} else if (filterType === 2) {
			commit('SET_PERPAGE_BUDGET_EXPENSES', perPage);
		} else if (filterType === 3) {
			commit('SET_PERPAGE_BUDGET_PO', perPage);
		} else if (filterType === 4) {
			commit('SET_PERPAGE_BUDGET_EXPENSE_SHEET', perPage);
		} else if (filterType === 4) {
			commit('SET_PERPAGE_GREEN_TABLE', perPage);
		}
	},
	initFilterActive({ commit }) {
		commit('SET_FILTER_BUDGET_PER_CATEGORY', null);

		commit('SET_FILTER_BUDGET_SALARIES', null);
		commit('SET_PAGINATION_BUDGET_SALARIES_RECORD_LIMIT', 100);
		commit('SET_PAGINATION_BUDGET_SALARIES_RECORD_OFFSET', 0);
		commit('SET_PERPAGE_BUDGET_SALARIES', 100);

		commit('SET_FILTER_BUDGET_EXPENSES', null);
		commit('SET_PAGINATION_BUDGET_EXPENSES_RECORD_LIMIT', 100);
		commit('SET_PAGINATION_BUDGET_EXPENSES_RECORD_OFFSET', 0);
		commit('SET_PERPAGE_BUDGET_EXPENSES', 100);

		commit('SET_FILTER_BUDGET_PO', null);
		commit('SET_PAGINATION_BUDGET_PO_RECORD_LIMIT', 100);
		commit('SET_PAGINATION_BUDGET_PO_RECORD_OFFSET', 0);
		commit('SET_PERPAGE_BUDGET_PO', 100);

		commit('SET_FILTER_BUDGET_EXPENSE_SHEET', null);
		commit('SET_PAGINATION_BUDGET_EXPENSE_SHEET_LIMIT', 100);
		commit('SET_PAGINATION_BUDGET_EXPENSE_SHEET_OFFSET', 0);
		commit('SET_PERPAGE_BUDGET_EXPENSE_SHEET', 100);

		commit('SET_FILTER_GREEN_TABLE', null);
		commit('SET_PAGINATION_GREEN_TABLE_LIMIT', 100);
		commit('SET_PAGINATION_GREEN_TABLE_OFFSET', 0);
		commit('SET_PERPAGE_GREEN_TABLE', 100);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};

const returnData = (val) => {
	let filterResult = null;

	if (_.isNil(val)) {
		filterResult = {
			city: '',
			state: '',
			country: '',
			department: null,
			userDepartment: null,
			userFunction: null,
			startDate: '',
			endDate: '',
			personId: null,
			supplierId: null,
			status: null,
			type: null,
			category: null,
			id: 0,
			filterName: '',
			source: null,
			co2Type: null,
			reviewStatus: [],
			processed: null,
			forForecastReportOnly: null,
			invoicedToProduction: null,
			templateId: null
		};
	} else {
		filterResult = {
			city: val.city ? val.city : '',
			state: val.state,
			country: val.country,
			department: val.department !== -2 ? val.department : null,
			userDepartment: val.userDepartment !== -2 ? val.userDepartment : null,
			userFunction: val.userFunction !== -2 ? val.userFunction : null,
			startDate: val.startDate !== '' ? val.startDate.split('T')[0] + 'T00:00:00Z' : '',
			endDate: val.endDate !== '' ? val.endDate.split('T')[0] + 'T23:59:59Z' : '',
			personId: val.personId !== -2 ? val.personId : null,
			supplierId: val.supplierId !== -2 ? val.supplierId : null,
			status: val.status !== -2 ? val.status : null,
			type: val.type !== -2 ? val.type : null,
			category: val.category !== -2 ? val.category : null,
			id: val.id,
			filterName: val.filterName,
			source: val.source !== -2 ? val.source : null,
			co2Type: val.co2Type !== -2 ? val.co2Type : null,
			templateId: val.templateId !== -2 ? val.templateId : null,
			reviewStatus: val.reviewStatus.length > 0 ? val.reviewStatus : [],
			processed: val.processed,
			forForecastReportOnly: val.forForecastReportOnly,
			invoicedToProduction: val.invoicedToProduction
		};
	}

	return filterResult;
};

const returnDataToSend = (val) => {
	let filterResult = {
		city: val.city ? val.city : '',
		state: val.state ? val.state : '',
		country: val.country ? val.country : '',
		department: val.department ? val.department : -2,
		userDepartment: val.userDepartment ? val.userDepartment : -2,
		userFunction: val.userFunction ? val.userFunction : -2,
		startDate: val.startDate ? val.startDate.split('T')[0] + 'T00:00:00Z' : '',
		endDate: val.endDate ? val.endDate.split('T')[0] + 'T23:59:59Z' : '',
		personId: val.personId ? val.personId : -2,
		supplierId: val.supplierId ? val.supplierId : -2,
		source: val.source ? val.source : -2,
		co2Type: val.co2Type ? val.co2Type : -2,
		templateId: val.templateId ? val.templateId : -2,
		reviewStatus: val.reviewStatus.length > 0 ? val.reviewStatus : [],
		status: val.status ? val.status : -2,
		type: val.type ? val.type : -2,
		category: val.category ? val.category : -2,
		filterName: val.filterName,
		filterType: val.filterType,
		isActive: val.isActive,
		recordLimit: val.recordLimit ? val.recordLimit : 100,
		recordOffSet: val.recordOffSet ? val.recordOffSet : 0,
		processed: val.processed,
		forForecastReportOnly: val.forForecastReportOnly,
		invoicedToProduction: val.invoicedToProduction
	};

	return filterResult;
};
