<template>
	<div class="list-item">
		<div class="header-item" v-if="computedShownFields.length > 0 && !horizontalMode">
			<div class="table-title" v-if="keyForTitle.replace(/[ \s]+/g, '') !== '' && title.replace(/[ \s]+/g, '') !== ''">{{ title }}</div>
			<div class="table-resume">
				<div v-for="(resume, index) in getListKeyValueItem(item, computedShownFields)" :key="index">
					<div class="resume-item" v-if="resume.value && resume.value !== ''">
						<div class="label">{{ resume.value }}</div>
						<div class="title">{{ resume.label }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="header-item p-3" v-else-if="computedShownFields.length > 0 && horizontalMode">
			<h6 class="m-0" v-if="keyForTitle.replace(/[ \s]+/g, '') !== '' && title.replace(/[ \s]+/g, '') !== ''">{{ title }} (X{{ quantity }})</h6>
			<div>
				<div v-for="(resume, index) in getListKeyValueItem(item, computedShownFields)" :key="index">
					<div v-if="resume.value && resume.value !== ''">
						<h4 class="m-0 mt-2">{{ resume.label }} - {{ resume.value }}</h4>
					</div>
				</div>
			</div>
		</div>
		<div class="content-item" v-show="toggleDetail">
			<div class="data-item even" :class="`${computedShownFields.length === 0 ? 'border-radius-top' : ''}`" v-if="hasBadge && !hideStatus">
				<div class="key"></div>
				<div class="value">
					<div class="status" :class="`${isBadgeUseProps ? item[badgeClass] : badgeClass}`">
						{{ isBadgeUseProps ? item[badgeValue] : badgeValue }}
					</div>
				</div>
			</div>
			<div v-for="(cell, i) in getListKeyValueItem(item)" :key="i">
				<div
					class="data-item"
					:class="`${hasBadge ? (i % 2 === 0 ? 'odd' : 'even') : i % 2 === 0 ? 'even' : 'odd'} ${i === 0 && !hasBadge ? 'border-radius-top' : ''} ${
						i === getListKeyValueItem(item).length - 1 && !$hasSlot('children-actions') ? 'border-radius-bottom border-bottom' : ''
					}`"
				>
					<div class="key">{{ cell.label }}</div>
					<div class="value" v-if="!cell.isSlot">{{ cell.value }}</div>
					<div class="value" v-else><slot :name="cell.key" /></div>
				</div>
			</div>
			<div v-if="$hasSlot('children-actions')" class="actions-item">
				<!-- TODO don't forget to add class="action" to each element
                    && thanx to use lucide icon 🙂 (https://lucide.dev/)
                    && use directly the icon to handle an action (don't wrap in a button)
                    e.g:: <photo class="action" size="20" @click="handleMe" />
                    -->
				<slot name="children-actions" :item="item" />
			</div>
		</div>
		<card-footer @card-list-builder:toggle="toggleCardDetail" v-if="computedShownFields.length > 0" />
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import CardFooter from '@/components/CardListBuilder/CardFooter';
import { isNil, convertStringToPropGetter, noTilde, generateSecureId, isObj } from '~utils';

export default {
	name: 'CardContent',
	mixins: [languageMessages],
	components: {
		CardFooter
	},
	computed: {
		title() {
			this.computedShownFields = this.shownFields;
			this.computedFields = this.fields;

			return this.getTitleValue(this.keyForTitle, this.item);
		},
		quantity() {
			if (this.keyForQuantity != '' && this.showQuantityOnTitle) {
				return this.getTitleValue(this.keyForQuantity, this.item);
			}
		}
	},
	props: {
		showQuantityOnTitle: {
			type: Boolean,
			required: false,
			default: false
		},
		keyForQuantity: {
			type: String,
			required: false,
			default: ''
		},
		horizontalMode: {
			type: Boolean,
			required: false,
			default: false
		},
		fields: {
			type: Array,
			required: false,
			default: () => []
		},
		item: {
			type: Object,
			required: true,
			default: null
		},
		omittedFields: {
			type: Array,
			required: false,
			default: () => []
		},
		shownFields: {
			type: Array,
			required: false,
			default: () => []
		},
		hasBadge: {
			type: Boolean,
			required: false,
			default: false
		},
		hideStatus: {
			type: Boolean,
			required: false,
			default: false
		},
		/**
		 * This field is mandatory and take true value
		 * when the value of badgeClass or badgeValue
		 * is a key of object
		 */
		isBadgeUseProps: {
			type: Boolean,
			required: false,
			default: false
		},
		badgeClass: {
			type: String,
			required: false,
			default: 'info'
		},
		badgeValue: {
			type: String,
			required: false,
			default: ''
		},
		keyForTitle: {
			type: String,
			required: false,
			default: ''
		},
		toggleMode: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			toggleDetail: false,
			computedFields: [],
			computedShownFields: []
		};
	},
	methods: {
		getListKeyValueItem(item, fields = null) {
			const dataFields = isNil(fields) ? this.computedFields : fields;
			let res = [];

			dataFields.forEach((f) => {
				if (isNil(f)) return;
				if (this.omittedFields.includes(f.key)) return;

				res.push(this.getKeyValue(item, f));
			});

			return res;
		},
		getKeyValue(item, field) {
			if (isNil(item) || isNil(field)) return '';
			const key = convertStringToPropGetter(item, field.key);
			// create 'formatter' prototypeKey for items options access
			// ex -> formatter: (value, key, item) => {}
			const value = isNil(field.formatter) ? key : field.formatter(key, field.key, item);

			return {
				key: field.key,
				label: field.label,
				value,
				isSlot: isNil(field.isSlot) ? false : true
			};
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		toggleCardDetail(payload) {
			this.toggleDetail = !payload;
		},
		getTitleValue(keyForTitle, item, fields = null) {
			if (keyForTitle === '') return;
			let data = null;

			const dataFields = isNil(fields) ? this.shownFields : fields;

			dataFields.forEach((f) => {
				if (isNil(f)) return;

				if (f.key === keyForTitle) {
					data = this.getKeyValue(item, f);
					return;
				}
			});

			const keys = [keyForTitle];
			this.computedShownFields = this.computedShownFields.filter((t) => !keys.includes(t.key));
			this.computedFields = this.computedFields.filter((t) => !keys.includes(t.key));
			// console.log(data);
			return isNil(data) ? '' : data.value;
		},
		isObject(value) {
			return isObj(value);
		}
	},
	watch: {
		shownFields: {
			handler(fields) {
				if (!isNil(fields)) {
					this.computedShownFields = fields;
				}
			},
			deep: true,
			immediate: true
		},
		fields: {
			handler(fields) {
				if (!isNil(fields)) {
					this.computedFields = fields;
				}
			},
			deep: true,
			immediate: true
		},
		toggleMode: {
			handler(toggle) {
				if (!isNil(toggle)) {
					this.toggleDetail = toggle;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
