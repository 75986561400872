import { store } from '@/store';
import { isNil } from '@/shared/utils';

import { getAllFormsData, addForm, addLabelToFormContentInDB, getAllMenus, getMenu, setGlobalMenuMap } from '@/cruds/language.crud';

export default {
	name: 'languageMessages',
	data() {
		return {
			messageMap: new Map(),
			menuMap: new Map()
		};
	},
	beforeCreate() {
		this.$nextTick(async () => {
			let formObj;
			let formsMap = store.getScreenFormsMap();
			//console.log("in created for form:", this.$options.name, formsMap);
			if (formsMap == undefined) {
				//  return;
				//console.log("in created for form: FORMSMAP UNDEFINED", this.$options.name);
				//return;
				//console.log("in created for form: GETTING DATA", this.$options.name);
				// forms content never loaded, load all form content and put in the store (form Array
				// loading forms from db
				//console.log("in created, load all data by calling getAllformcontent");
				const data = await getAllFormsData();
				formsMap = this.parseFormScreensContent(data);

				//console.log("getFormScreenContent data:", data);
				store.setScreenFormsMap(formsMap);
				//console.log("in created, formsMap loaded:",formsMap);
			}
			// check if form content for current screen exists otherwise create it.
			formObj = formsMap.get(this.$options.name); // getting formContent
			if (formObj == undefined) {
				//console.log("in created form not found in formsMap, form name:",this.$options.name);
				// adding protection against calling serveral times addform for the same form...
				let tmpFormObj = {};
				tmpFormObj.messageMap = new Map();
				tmpFormObj.formNumber = 0;
				formsMap.set(this.$options.name, tmpFormObj);
				// console.log("temporaryformcreated:", tmpFormObj);

				// add form to database
				formObj = await addForm(this.$options.name);
				// add created form to formsMap
				formObj.messageMap = new Map();
				formsMap.set(this.$options.name, formObj);
			}
			if (formObj != undefined) {
				this.messageMap = formObj.messageMap;
				//console.log("in created messageMap:",  this.messageMap);
			}

			// managing menus
			let menuMap = store.getAllMenuMap();

			if (menuMap == undefined) {
				// menu content never loaded, load all menu content and put in the store (menu Array
				// loading menus from db
				//console.log("in created, load all menu by calling getAllMenus");
				const data = await getAllMenus();
				//console.log("getAllMenus data:", data);
				menuMap = this.parseAllMenuContent(data);

				//console.log("getAllMenus menuMap:", this.$options.name, menuMap);
				store.setAllMenuMap(menuMap);
				//console.log("in created, menuMap loaded:",menuMap);
				// set global menu to direct js access in language.cruds.js
				//console.log("call setGlobalMenuMap");
				setGlobalMenuMap(menuMap);
			}

			if (menuMap != undefined) {
				this.menuMap = menuMap;
				//console.log("in created messageMap:",  this.messageMap);
			}
		});
	},
	methods: {
		parseFormScreensContent(data) {
			/* formMap object has the following structure:
			formMap {
				formName: string (key), objForm { formNumber: int, messageMap: map { labelID (int key), labelText} }
				}
			*/
			var formMap = new Map();
			var curFormObj = {};
			for (var i = 0; i < data.length; i++) {
				curFormObj = formMap.get(data[i].formName);
				if (curFormObj == undefined) {
					// create the object for the form
					curFormObj = {};
					curFormObj.formNumber = parseInt(data[i].formNumber);
					curFormObj.messageMap = new Map();
					formMap.set(data[i].formName, curFormObj);
				}
				if (parseInt(data[i].value) != -1) {
					// if value == -1 message undefined, no message yet for this form
					curFormObj.messageMap.set(parseInt(data[i].value), data[i].message);
				}
			}
			//console.log("Loaded form data from db, formMap:", formMap);
			return formMap;
		},
		parseAllMenuContent(data) {
			/* parse database menuitems and fills a  map with menunumber and  an array menuitems :
				we create a map of menuNumber =>
				[{
					value: 0,
					text: 'Not defined'
				}, {};;;
				]
			*/
			var menuMap = new Map();
			var curMenuObj = {};
			for (var i = 0; i < data.length; i++) {
				curMenuObj = menuMap.get(parseInt(data[i].number));
				if (curMenuObj == undefined) {
					// create the object for the menu
					//console.log("in parseMenu, create curMenuObj for number:",parseInt(data[i].number));
					curMenuObj = {};
					curMenuObj.number = parseInt(data[i].number);
					curMenuObj.menuArray = [];
					menuMap.set(curMenuObj.number, curMenuObj.menuArray);
				}
				// adding item in curMenuObj
				var curMenuItem = {};
				curMenuItem.value = parseInt(data[i].value);
				curMenuItem.text = data[i].message;
				curMenuItem.factor = data[i].paramFloat;
				//console.log("in parseMenu, create curMenuItem for value:",curMenuItem.value);
				var ar = menuMap.get(parseInt(data[i].number));
				ar.push(curMenuItem);
			}
			//console.log("Loaded menu data from db, menuMap:", menuMap);
			return menuMap;
		},
		FormMenuSorted(menuId) {
			var foundMenuArray = this.FormMenu(menuId);
			foundMenuArray.sort((a, b) => (a.text < b.text ? -1 : Number(a.text > b.text)));
			return foundMenuArray;
		},
		FormMenu(menuId) {
			//console.log("FormMenu, menuMap:", this.menuMap);
			//console.log("FormMenu, looking for menuId:", menuId);
			let foundMenuArray = this.menuMap.get(menuId);
			// console.log('1');
			// new Promise((resolve, reject) => {
			// 	setTimeout(() => {
			// 		resolve(this.menuMap.get(menuId));
			// 	}, 250);
			// 	reject(false);
			// }).then((data) => {
			// 	foundMenuArray = data;
			// 	console.log('2');
			// });
			// console.log('3');

			//console.log("FormMenu, foundMenuArray:", foundMenuArray);
			if (foundMenuArray === undefined) {
				//console.log("in FormMenu menu not found:", menuId);
				return [{ value: 0, text: 'not found' }];
			}

			const addTranslatorInfo = store.isTranslator() && this.$cookies.get('showTradInfo') == 'true';

			if (addTranslatorInfo) {
				// add information for translator form number and label number
				let newMenuArray = [];
				for (let i = 0; i < foundMenuArray.length; i++) {
					// adding item in curMenuObj
					let curMenuItem = {};
					curMenuItem.value = parseInt(foundMenuArray[i].value);
					curMenuItem.text = foundMenuArray[i].text + '(' + menuId + ',' + foundMenuArray[i].value + ')';
					curMenuItem.factor = parseInt(foundMenuArray[i].factor);
					newMenuArray.push(curMenuItem);
				}

				return newMenuArray;
			}

			return foundMenuArray;
		},
		GetTextFromMenuNumberAndMenuValue(mnuNumber, mnuValue) {
			//console.log("in GetTextFromMenuNumberAndMenuValue, mnuNumber mnuValue",mnuNumber, mnuValue);
			const mnu = this.FormMenu(parseInt(mnuNumber));
			const mnuVal = parseInt(mnuValue);
			for (let i = 0; i < mnu.length; i++) {
				if (mnu[i].value === mnuVal) {
					return mnu[i].text;
				}
			}

			return 'Not found';
		},
		FormMSG(id, label) {
			//console.log("in formMsg label = ",label);
			var foundLabel = this.messageMap.get(id);
			if (foundLabel != undefined) {
				//console.log("in FormMSG foundLabel in messageMap:", foundLabel);
				var addTranslatorInfo = store.isTranslator() && this.$cookies.get('showTradInfo') == 'true';
				if (addTranslatorInfo) {
					// add information for translator form number and label number
					var formMap = store.getScreenFormsMap();
					var formSc = formMap.get(this.$options.name);
					foundLabel = foundLabel + '(' + formSc.formNumber + ',' + id + ')';
				}
				return foundLabel; // + "_T";
			}
			// label not found check if needs to add it to database
			var formsMap = store.getScreenFormsMap();
			if (formsMap == undefined) {
				//console.log("in FormMSG formsMap not loaded id:",id);
				//console.log("in FormMSG formsMap not loaded label:",label);
				return label + '_'; //"_fmnl";  // formmap not loaded
			} else {
				var formObj = formsMap.get(this.$options.name); // getting formContent
				// console.log("in FormMSG formsMap name:",this.$options.name);
				// console.log("in FormMSG formsMap formObj:",formObj);
				// console.log("in FormMSG formsMap id:",id);
				if (formObj == undefined) {
					return label + '_FNE'; // Form does Not Exist in map (should never happen as it is created in "create" above)
				} else if (formObj.formNumber > 0) {
					// avoid ading label to forms not yet created in database (formNumber==0)
					//console.log("in FormMSG formObj:", formObj);
					foundLabel = formObj.messageMap.get(id);
					if (foundLabel == undefined) {
						// adding label to local map to avoid calling serveral times the db to add it as vue updates multiple times the labels
						formObj.messageMap.set(parseInt(id), label); //+ "_AToDB");
						// add label to db , will be loaded next time
						console.log('in FormMSG adding label for form:', this.$options.name, formObj.messageMap);
						console.log('in FormMSG adding label :', formObj.formNumber, id, label);
						new Promise((resolve, reject) => {
							setTimeout(async () => {
								resolve(await addLabelToFormContentInDB(formObj.formNumber, id, label, 0, true));
								reject(false);
							}, 500);
						});

						return label; // + "_laddedToDb";  // label does not exist, create it
					}
					if (foundLabel != undefined) {
						// should never come here as messageMap would find the label above
						//console.log("in FormMSG foundLabel from formContent:", foundLabel);
						return foundLabel;
					}
				}
			}
			return label + '_NF';
		}
	}
};
