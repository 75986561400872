<template>
	<router-view />
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

export default {
	name: 'App',
	data() {
		return {
			isKeyboardVisible: false
		};
	},
	create() {
		this.initStatusBarPwa();
		this.initKeyboardListener();
	},
	methods: {
		initStatusBarPwa() {
			if (!this.$isPwa()) return;
			StatusBar.setStyle({ style: Style.Light });
			// StatusBar.setBarStyle('light-content', true);
			// Check if the platform is iOS before calling setBarStyle
			if (Capacitor.getPlatform() === 'ios') {
				StatusBar.setBarStyle('light-content', true);
			}

			StatusBar.setBackgroundColor('#000000');
		},
		// initKeyboardListener() {
		// 	window.addEventListener('keyboardWillShow', () => {
		// 		this.isKeyboardVisible = true;
		// 	});
		//
		// 	window.addEventListener('keyboardWillHide', () => {
		// 		this.isKeyboardVisible = false;
		// 	});
		//
		// 	document.addEventListener('focusin', (event) => {
		// 		if (this.isKeyboardVisible) {
		// 			event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
		// 		}
		// 	});
		// }
		initKeyboardListener() {
			if (!this.$isPwa()) return;
			if (this.$isIOSDevice()) return;

			window.addEventListener('resize', () => {
				const vh = window.innerHeight * 0.01;
				const previousVh = document.documentElement.style.getPropertyValue('--vh') || 0;

				if (Math.abs(vh - previousVh) > 5) {
					this.isKeyboardVisible = vh < previousVh;
					document.documentElement.style.setProperty('--vh', `${vh}px`);
				}
			});

			document.addEventListener('focusin', (event) => {
				if (!this.isKeyboardVisible) return;
				event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
			});
		}
	}
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.min.css';
/* Import Font Awesome Icons Set */
$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/scss/font-awesome.scss';
/* Import Simple Line Icons Set */
$simple-line-font-path: '~simple-line-icons/fonts/';
@import '~simple-line-icons/scss/simple-line-icons.scss';
//  @import '~@coreui/icons/css/coreui-icons.css';
/* Import Flag Icons Set */
@import '~flag-icon-css/css/flag-icon.min.css';
/* Import Bootstrap Vue Styles */
// @import '~bootstrap-vue/dist/bootstrap-vue.css';
// Import Main styles for this application
@import 'assets/scss/style';

pwa-camera-modal-instance,
pwa-action-sheet {
	z-index: 999999999999999999 !important;
}
</style>
