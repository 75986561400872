import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';
import _ from 'lodash';

/**
 * get all projects
 * @param {String, Number} licenseId
 */
export const getProjects = async (licenseId = 0) => {
	const {
		data: { Projects }
	} = await apollo.query({
		query: gql`
			query ($licenseId: ID) {
				Projects(licenseID: $licenseId) {
					id
					licenseID
					titre
					production
					realisateur
				}
			}
		`,
		variables: {
			licenseId: parseInt(licenseId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return Projects;
};

/**
 * get one project
 * @param {String, Number} supplierID
 */
export const getProject = async (projectId) => {
	//console.log("getProject projectId:",projectId);
	if (isNil(projectId)) return;
	try {
		const { data } = await apollo.query({
			query: gql`
				query ($projectID: ID!) {
					Project(projectID: $projectID) {
						${fields}
					}
				}
			`,
			variables: {
				projectID: parseInt(projectId)
			},
			fetchPolicy: 'no-cache'
		});
		//console.log("end of Project:",data.Project);
		return data.Project;
	} catch (e) {
		console.log({ e });
	}
};

export const myProject = async () => {
	try {
		const { data } = await apollo.query({
			query: gql`
				query {
					myProject {
						${fields}
					}
				}
			`,
			variables: {},
			fetchPolicy: 'no-cache'
		});

		return data.myProject;
	} catch (e) {
		console.log({ e });
	}
};

export const updMyProject = async (id, project, supplierIds = null) => {
	try {
		const mutation = gql`
			mutation ($projectId: ID!, $newProject: ProjectInput!, $supplierIds: [ID]) {
				updMyProject(projectId: $projectId, newProject: $newProject, SupplierID: $supplierIds)
			}
		`;

		let variables = {
			projectId: +id,
			newProject: project,
			supplierIds
		};

		const { data } = await apollo.mutate({
			mutation,
			variables
		});

		return data.updMyProject;
	} catch (e) {
		console.log({ e });
	}
};
const mutation_newPme = gql`
	mutation ($ProjectId: ID!, $PmeName: String!, $Filter: CopyProjectFilterInput) {
		NewPmeProject(ProjectId: $ProjectId, PmeName: $PmeName, Filter: $Filter) {
			id
		}
	}
`;
export const newSmeProject = async (id, smeName, filter) => {
	try {
		const mutation = gql`
			mutation ($ProjectId: ID!, $PmeName: String!, $Filter: CopyProjectFilterInput) {
				NewPmeProject(ProjectId: $ProjectId, PmeName: $PmeName, Filter: $Filter) {
					id
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				ProjectId: parseInt(id),
				PmeName: smeName,
				Filter: filter
			}
		});

		return data.NewPmeProject;
	} catch (e) {
		console.log({ e });
	}
};

export const addProject = async (project, originProjectId = 0) => {
	console.log('in addProject:', project);
	try {
		const mutation = gql`
			mutation ($newProject: ProjectInput!, $originProjectId: ID) {
				addProject(newProject: $newProject, OriginProjectId: $originProjectId) {
					${fields}
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				newProject: project,
				originProjectId
			}
		});

		return data.addProject;
	} catch (e) {
		console.log({ e });
	}
};

export const setSlaveProjectStatus = async (stCustomerId, active) => {
	const mutation = gql`
		mutation SET_SLAVE_PROJECT_STATUS($stCustomerId: String!, $active: Boolean!) {
			SetSlaveProjectStatus(StCustomerId: $stCustomerId, Active: $active)
		}
	`;

	try {
		const { data: SetSlaveProjectStatus } = await apollo.mutate({
			mutation,
			variables: {
				stCustomerId,
				active
			}
		});

		return SetSlaveProjectStatus;
	} catch (e) {
		console.log(e);
	}
};

export const getUserLastProject = async (userId) => {
	try {
		const query = gql`
			query GET_USER_LAST_PROJECT($userId: ID!) {
				GetUserLastProject(UserId: $userId) {
					${fields}
				}
			}
		`;
		const {
			data: { GetUserLastProject }
		} = await apollo.query({
			query,
			variables: {
				userId
			},
			fetchPolicy: 'no-cache'
		});

		return GetUserLastProject;
	} catch (error) {
		console.log(error.message);
	}
};

export const updProjectForAndroidSubscription = async (id, project) => {
	try {
		const mutation = gql`
			mutation ($projectId: ID!, $newProject: ProjectInput!) {
				UpdProjectForAndroidSubscription(projectId: $projectId, newProject: $newProject)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				projectId: parseInt(id),
				newProject: project
			}
		});

		return data.UpdProjectForAndroidSubscription;
	} catch (e) {
		console.log({ e });
	}
};

const fields = `
	id
	titre
	production
	projectType
	realisateur
	timeZone
	currency
	distanceUnit
	language
	license {
		id
      createdAt
      updatedAt
      userId
      createdBy
      companyId
      companyName
      license
      description
      userLanguage
      creator {
        id
        name
        firstName
        fullName
        title
      }
      user {
        id
        name
        fullName
        firstName
        function
      }
      company {
        id
        name
      }
	}
	licenseID
	picture
	songMusic
	coProducers
	invoiceDetails
	addressId
	carbonModel
	greenReporting
	useOvertime
	useNightHours
	useTooEarlyHours
	useSixthDay
	useLunchTime
	useStageDay
	numEpisode
	filmLength
	shootingFormat
	productionType
	useOvt1
	useOvt2
	useHomeToWorkTime
	useTransport
	useLunchPerDiem
	useHotelPerDiem
	useDinnerPerDiem
	useAbroadPerDiem
	useTimesheet
	useExpense
	useEntryForCrewExpenses
	useEntryForCrewPo
	useEntryForCrewTimesheet
	useEntryForCrew
	usePurchaseOrder
	useDocument
	useCallSheet
	useAccommodation
	useTipOfDay
	useGreenSupplier
	useTask
	usePlanning
	useClient
	useBudget
	startDate
	endDate
	customerId
	numberOfUser
	supportLevel
	masterProjectId
	budgetLocked
	useForCrewInvoice
	reference
	endTrialTime
	largeAccount
	largeAccountPaid
	numberOfActiveUsers
	lastInvoiceNumber
	address {
		id
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	legalEntities {
		id
		info
		contactName
		contactFunction
		nationalRegistryNumber
		vat
		phone
		email
		name
		type
		addressId
		companyType
		address {
			zip
			state
			street
			number
			box
			lat
			lng
			city
			country
			countryCode
			countryKey
		}
		supplierBankAccounts {
			supplierId
			bankName
			currency
			exchangeRate
			iban
			bic
		}
	}
	lastOrderNumber
	lastOrderFormat
	lastOfferNumber
	lastOfferFormat
	lastInvoiceNumber
	lastInvoiceFormat
	userName
	hodName
	managerName
	shootingStartDate
	wrapStartDate
	useBeforeCallTime
	useWeeklyOvt
	useWeeklyOvt1
	useWeeklyOvt2
	useSeventhDay
	useSundayRate
	useExpenseTravel
	useExpenseTicket
	useExpenseInvoice
	reference
	payrollInterface
	allowTSEntryOutsideContract
	allowExpenseEntryOutsideContract
	allowPOEntryOutsideContract
	analyticPeriodType
	ccLogin
	ccPassword
	ccShareReporting
	useKlippaOcr
	lastPoFormat
	lastPoNumber
	maxExpenseItemsPerSheet
	regionalSetting
	useProduction
	useContacts
	useCrewSetup
	useLocations
	useExport
	useImport
	useFinance
	useTimesheetPlanning
	useGreen
	useCarbonTemplate
	useGreenTable
	useReporting
	useCarbonRemoval
	useWaste
	hideSalaryInfo
`;
