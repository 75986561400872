import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export const getDepartment = async (departmentId) => {
	try {
		const QUERY_GET_DEPARTMENT = gql`
			query ($departmentId: Int!) {
				GetDepartment(Number: $departmentId) {
					id
					createdAt
					updatedAt
					projectId
					importId
					value
					message
					category
					radioChannel
					forSalary
					forExpense
					costCenter
				}
			}
		`;

		const {
			data: { GetDepartment }
		} = await apollo.query({
			query: QUERY_GET_DEPARTMENT,
			variables: {
				departmentId: parseInt(departmentId)
			}
		});

		return GetDepartment;
	} catch (error) {
		console.error(error);
	}
};

export const getDepartments = async (ForExpense = null, AppChoice = null) => {
	try {
		const QUERY_GET_DEPARTMENTS = gql`
			query ($ForExpense: Boolean, $AppChoice: Boolean) {
				GetDepartments(ForExpense: $ForExpense, AppChoice: $AppChoice) {
					value
					id
					message
				}
			}
		`;

		const {
			data: { GetDepartments }
		} = await apollo.query({
			query: QUERY_GET_DEPARTMENTS,
			variables: {
				ForExpense,
				AppChoice
			},
			fetchPolicy: 'no-cache'
		});

		return GetDepartments;
	} catch (error) {
		console.error(error);
	}
};

export const getFunctions = async (departmentId = null) => {
	try {
		const query = gql`
			query ($departmentId: Int) {
				GetFunctions(DepartmentNumber: $departmentId) {
					${funcitonFields}
				}
			}
		`;

		const {
			data: { GetFunctions }
		} = await apollo.query({
			query,
			variables: {
				departmentId
			},
			fetchPolicy: 'no-cache'
		});

		return GetFunctions;
	} catch (error) {
		console.error(error);
	}
};

export const updateFunction = async (functionId, functionName, newCostCenter, shortCode = '', prefix = '', CategoryId, ForLicense = false) => {
	try {
		const mutation = gql`
			mutation ($functionId: ID!, $functionName: String!, $newCostCenter: String, $shortCode: String, $CategoryId: ID, $prefix: String, $ForLicense: Boolean) {
				UpdFunction(FunctionId: $functionId, FunctionName: $functionName, NewCostCenter: $newCostCenter, ShortCode: $shortCode, CategoryId: $CategoryId, Prefix: $prefix, ForLicense: $ForLicense) {
					${funcitonFields}
				}
			}
		`;

		let variables = {
			functionId,
			functionName,
			newCostCenter,
			shortCode,
			CategoryId,
			prefix,
			ForLicense
		};

		const {
			data: { UpdFunction }
		} = await apollo.mutate({
			mutation,
			variables,
			fetchPolicy: 'no-cache'
		});

		return UpdFunction;
	} catch (error) {
		console.error(error);
	}
};

export const deleteFunction = async (functionId, deleteCategory) => {
	try {
		const mutation = gql`
			mutation ($functionId: ID!, $deleteCategory: Boolean!) {
				DelFunction(FunctionId: $functionId, DeleteCategory: $deleteCategory) {
					${funcitonFields}
				}
			}
		`;

		let variables = {
			functionId,
			deleteCategory
		};

		const {
			data: { DelFunction }
		} = await apollo.mutate({
			mutation,
			variables,
			fetchPolicy: 'no-cache'
		});

		return DelFunction;
	} catch (error) {
		console.error(error);
	}
};

export const addFunction = async (departmentId) => {
	try {
		const mutation = gql`
			mutation ($departmentId: Int!) {
				NewFunction(DepartmentNumber: $departmentId) {
					${funcitonFields}
				}
			}
		`;

		let variables = {
			departmentId
		};

		const {
			data: { NewFunction }
		} = await apollo.mutate({
			mutation,
			variables,
			fetchPolicy: 'no-cache'
		});

		return NewFunction;
	} catch (error) {
		console.error(error);
	}
};

const funcitonFields = `
	id
	value
	departmentNumber
	prefix
	message
	costCenter
	category
	shortCode
	categoryLinkedToFun
`;
