import gql from 'graphql-tag';
import { isNil } from '@/shared/utils';
import { apolloClient as apollo } from '@/vue-apollo';

const RES_FIELD = `
  id
  createdAt
  updatedAt
  projectId
  type
  type1
  kind
  status
  selected
  surface
  setName
  setName1
  setName2
  setName3
  scriptName
  description
  note
  foundOn
  mapPicture
  googleMapLink
  toilets
  trash
  fireExtinguisher
  catering
  addressId1
  addressId2
  addressId3
  addressId4
  addressId5
  addressId6
  addressId7
  addressId8
  addressId9
  addressId10
  addressId11
  addressId12
  addressId13
  addressId14
  ownerSupplierId
  dayRateFee
  dayRateSettingUpFee
  downPaiement
  total
  paiementType
  parkingPermit
  getInDate
  getOutDate
  shootingStartDate
  shootingEndDate
  elecSupplier
  elecMeterStart
  elecMeterEnd
  elecMeterStartPict
  elecMeterEndPict
  gasMeterStart
  gasMeterEnd
  gasMeterStartPict
  gasMeterEndPict
  waterMeterStart
  waterMeterEnd
  waterMeterStartPict
  waterMeterEndPict
  clientId
  numberOfPicture
  filmingArea
  mainCharacteristic
  totNbOfDaysOccupation
  totNbOfManDays
  energySavingPlan
  nbOfAirConditioners
  greenEnergySupplier
  recycledPaper
  sortedWaste
  generatorFuelType
  generatorVolumeUsed
  generatorPict
  waterSupplier
  gasSupplier
  address1 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address2 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address3 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address4 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address5 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address6 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address7 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address8 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address9 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address10 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address11 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address12 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address13 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address14 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  owner {
    id
    createdAt
    updatedAt
    licenseId
    name
    webSite
    externalId
    companyType
    email
    vat
    phone
    info
    addressId
    contactName
    contactFunction
    nationalRegistryNumber
    type
    vatRate
    companyTypeName
    typeName
  }
  images
  imageName
  documents
  computerEquipments {
    id
    createdAt
    updatedAt
    locationId
    type
    description
    quantity
  }
  isHidden
  canNotBeHidden
`;

const state = () => ({
	currLocation: null,
	projectLocationsList: []
});

const getters = {
	currLocation: (state) => state.currLocation,
	projectLocationsList: (state) => state.projectLocationsList
};

const mutations = {
	SET_CURRENT_LOCATION(state, location) {
		state.currLocation = location;
	},
	SET_PROJECT_LOCATIONS_LIST(state, locations) {
		state.projectLocationsList = locations;
	}
};

const actions = {
	async setNewLocation({ commit, dispatch }, clientId = null) {
		try {
			const mutation = gql`
				mutation ($clientId: ID) {
					NewLocation(ClientId: $clientId) {
						${RES_FIELD}
					}
				}
			`;

			let record = null;
			if (!isNil(clientId) && clientId > 0) {
				record = await apollo.mutate({
					mutation,
					variables: {
						clientId: parseInt(clientId, 10)
					}
				});
			} else {
				record = await apollo.mutate({
					mutation
				});
			}

			commit('SET_CURRENT_LOCATION', record.data.NewLocation);
			dispatch('getProjectLocationList');
		} catch (e) {
			console.log({ e });
		}
	},

	setCurrentLocation({ commit }, location) {
		commit('SET_CURRENT_LOCATION', location);
	},
	destroyCurrentLocation({ commit, state }, id = null) {
		// if id != null : condition on id before destroy
		if (!isNil(id)) if (state.currLocation.id !== id) return;
		commit('SET_CURRENT_LOCATION', null);
	},

	async getProjectLocationList({ commit }, variables = {}) {
		try {
			const query = gql`
				query ($clientId: ID, $startDate: String, $endDate: String, $Status: ID, $RemovePremise: Boolean,
          $RemoveLocationPME: Boolean,
          $RemoveLocationDecorFilm: Boolean
          ) {
					GetProjectLocationList(ClientId: $clientId, StrDate: $startDate, EndDate: $endDate, Status:$Status, RemovePremise: $RemovePremise, RemoveLocationPME: $RemoveLocationPME, RemoveLocationDecorFilm: $RemoveLocationDecorFilm) {
						${RES_FIELD}
					}
				}
			`;
			const {
				data: { GetProjectLocationList }
			} = await apollo.query({
				query,
				variables,
				fetchPolicy: 'no-cache'
			});
			commit('SET_PROJECT_LOCATIONS_LIST', GetProjectLocationList);
		} catch (e) {
			console.log({ e });
		}
	},

	async getLocation({ commit }, id) {
		if (isNil(id)) return;
		try {
			const query = gql`
        query ($LocationId: ID!) {
          GetLocation(LocationId: $LocationId) {
            ${RES_FIELD}
          }
        }
      `;
			const {
				data: { GetLocation }
			} = await apollo.query({
				query,
				variables: {
					LocationId: parseInt(id, 10)
				},
				fetchPolicy: 'no-cache'
			});
			commit('SET_CURRENT_LOCATION', {
				...GetLocation,
				id
			});
		} catch (e) {
			console.log({ e });
		}
	},

	async updateLocation({ commit, dispatch }, { id }) {
		if (isNil(id)) return;
		try {
			const mutation = gql`
				mutation ($LocationId: ID!, $UpdatedLocation: LocationInput!) {
					UpdLocation(LocationId: $LocationId, UpdatedLocation: $UpdatedLocation) {
						${RES_FIELD}
					}
				}
			`;
			const { data } = await apollo.mutate({
				mutation,
				variables: {
					LocationId: parseInt(id, 10),
					UpdatedLocation: location
				}
			});
			commit('SET_PROJECT_LOCATIONS_LIST', {
				id: parseInt(id),
				...data.Location
			});
			dispatch('getProjectLocationList');
		} catch (e) {
			console.log({ e });
		}
	},

	async deleteLocation({ dispatch }, id) {
		if (isNil(id)) return;
		try {
			const mutation = gql`
				mutation ($LocationId: ID!) {
					DelLocation(LocationId: $LocationId)
				}
			`;
			await apollo.mutate({
				mutation,
				variables: {
					LocationId: parseInt(id)
				}
			});
			dispatch('getProjectLocationList');
		} catch (e) {
			console.log({ e });
		}
	},

	async deleteLocations({ dispatch }, ids) {
		if (ids.length === 0) return;
		try {
			const mutation = gql`
				mutation ($LocationsId: [ID!]!) {
					DelLocations(LocationsId: $LocationsId)
				}
			`;
			await apollo.mutate({
				mutation,
				variables: {
					LocationsId: ids
				}
			});
			dispatch('getProjectLocationList');
		} catch (e) {
			console.log({ e });
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
