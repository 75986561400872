export const carbonClapRoutes = [
	{
		path: 'green',
		meta: { label: 'Green' },
		component: {
			render(c) {
				return c('router-view');
			}
		},
		children: [
			{
				path: 'list',
				meta: { label: 'Table' },
				component: () => import('@/modules/carbonclap/views/green/index.vue'),
				name: 'carbonClapGreen'
			},
			{
				path: 'carbon-clap',
				meta: { label: 'Reporting' },
				component: () => import('@/modules/carbonclap/views/reporting/index.vue'),
				name: 'carbonClapReporting'
			},
			{
				path: 'carbon-template',
				meta: { label: 'Carbon template' },
				component: () => import('@/modules/carbonclap/views/carbon-template/index.vue'),
				name: 'carbonTemplate'
			}
		]
	}
];
